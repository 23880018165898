@charset "UTF-8";
/* =================================================================
 License : e-TRUST Inc.
 File name : assets.css
 Style : assets.css
================================================================= */
@import url("https://fonts.googleapis.com/css?family=Muli:200,200i,300,300i,400,400i,600,600i,700,700i,800,800i,900,900i&subset=latin-ext,vietnamese");
@import url("https://fonts.googleapis.com/css?family=Raleway:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i&subset=latin-ext");
.pcnone {
  display: none;
}

@media only screen and (max-width: 950px) {
  .pcnone {
    display: block;
  }
}

.spnone {
  display: block;
}

@media only screen and (max-width: 700px) {
  .spnone {
    display: none;
  }
}

@media only screen and (max-width: 700px) {
  .spleft {
    text-align: left !important;
  }
}

@media only screen and (max-width: 700px) {
  .spleft div {
    text-align: left !important;
  }
}

.jcss {
  justify-content: start;
}

.jcsc {
  justify-content: center;
}

.jcsb {
  justify-content: space-between;
}

.aic {
  align-items: center;
}

.ais {
  align-items: start;
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
}

.disnone {
  display: none !important;
}

@media only screen and (max-width: 700px) {
  .disnone {
    display: block !important;
  }
}

@media only screen and (max-width: 700px) {
  .spdisnone {
    display: none !important;
  }
}

.tdpd10 td {
  padding: 10px !important;
}

li {
  list-style: none;
}

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  font-weight: 500;
}

body {
  counter-reset: number 0;
  line-height: 1.8em;
  font-weight: 500;
  color: #181818;
  font-family: "メイリオ", "Meiryo", "游ゴシック", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
  word-wrap: break-word;
  word-break: break-all;
  line-break: strict;
  overflow: hidden;
  letter-spacing: 0;
  font-size: 16px;
}

.viga {
  font-family: 'Viga', sans-serif;
  line-height: 1;
  letter-spacing: 0;
  font-weight: bold;
}

.inner {
  margin: 0 auto;
  max-width: 1000px;
  width: 100%;
}

.inner2 {
  margin: 0 auto;
  max-width: 520px;
}

.inner3 {
  margin: 0 auto;
  max-width: 700px;
}

#contents_wrap {
  width: 100%;
  min-width: 100%;
}

#contents {
  width: 100%;
  max-width: 700px;
}

@media only screen and (max-width: 1000px) {
  #contents {
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media screen and (min-width: 701px) and (max-width: 950px) {
  #contents {
    max-width: 100%;
    padding: 0 40px 0px 40px;
  }
}

@media only screen and (max-width: 700px) {
  #contents {
    padding: 0 30px 0px 30px;
  }
}

#main {
  width: 100%;
}

#main #col_main {
  width: 100%;
}

#side {
  width: 18.75000%;
}

.map {
  margin: 56px 20px 0;
}

.map li {
  border-left: 5px solid #CCCCCC;
  list-style: none;
  margin: 0 0 15px;
  padding: 0 0 0 10px;
  text-align: left;
}

table {
  table-layout: auto;
}

@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
  table {
    table-layout: fixed;
  }
}

@supports (-ms-ime-align: auto) {
  table {
    table-layout: fixed;
  }
}

.tlfix table {
  table-layout: fixed;
}

table td {
  padding: 20px !important;
}

table td img {
  height: auto !important;
}

.pdtd10 td {
  padding: 10px !important;
}

pre {
  margin: 0;
  padding: 0;
  white-space: pre-line;
}

b, strong {
  font-weight: bold;
}

u {
  text-decoration: line-through;
}

img {
  max-width: 100%;
  height: auto !important;
}

small {
  font-size: 80%;
}

hr {
  border: none;
  height: 0;
  border-bottom: 1px dashed #B3B3B3;
}

span {
  font-weight: inherit;
}

u {
  text-decoration: underline;
}

b {
  font-weight: 500;
}

input[type="button"] {
  cursor: pointer;
  padding: 5px 10px;
}

a {
  transition: 1s;
  text-decoration: none;
}

a:hover {
  opacity: 0.6;
  transition: 1s;
}

@media screen and (min-width: 701px) and (max-width: 950px) {
  .imgR, .imgL {
    max-width: 30% !important;
  }
}

@media only screen and (max-width: 700px) {
  .imgR, .imgL {
    display: block;
    float: none;
    margin: 0 auto 10px;
    max-width: 100% !important;
    text-align: center;
  }
}

.mincho {
  font-family: "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
}

.gothic {
  font-family: "メイリオ", "Meiryo", "游ゴシック", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
}

@media only screen and (max-width: 700px) {
  .fltL {
    float: none;
    width: 100% !important;
  }
  .fltR {
    float: none;
    width: 100% !important;
  }
  .sptal {
    text-align: left !important;
  }
  .sptar {
    text-align: right !important;
  }
  .sptac {
    text-align: center !important;
  }
  .spcenter {
    text-align: center;
    display: block;
    margin-top: 10px;
  }
}

@-webkit-keyframes zoom {
  50% {
    -webkit-transform: scale(1.1);
  }
}

@keyframes zoom {
  50% {
    transform: scale(1.1);
  }
}

header#global_header {
  max-width: 100%;
  width: 100%;
  min-width: 100%;
  height: auto;
}

body.fixed02 {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
}

.fixed03 {
  position: fixed !important;
  z-index: 11 !important;
  top: 0px !important;
  left: 0 !important;
  width: 100% !important;
  background: white !important;
  max-width: 100% !important;
  padding: 15px 0 !important;
}

@media only screen and (max-width: 1000px) {
  .fixed03 {
    padding: 15px 10px !important;
  }
}

@media only screen and (max-width: 950px) {
  .fixed03 {
    padding: 15px 16px !important;
  }
}

.fixed03 .clone-nav {
  margin-right: 30px;
}

@media only screen and (max-width: 700px) {
  .fixed03 .clone-nav {
    display: none;
  }
}

.fixed03 .clone-nav li a {
  font-size: 16px;
}

.fixed03 #hednav {
  display: block !important;
}

.fixed {
  position: fixed !important;
  z-index: 11 !important;
  top: 0px !important;
  left: 0 !important;
  width: 100% !important;
  background: white !important;
  max-width: 100% !important;
  padding: 15px 0 !important;
}

@media only screen and (max-width: 1000px) {
  .fixed {
    padding: 15px 10px !important;
  }
}

@media only screen and (max-width: 950px) {
  .fixed {
    padding: 15px 16px !important;
  }
}

.fixed .clone-nav {
  margin-right: 30px;
}

@media only screen and (max-width: 700px) {
  .fixed .clone-nav {
    display: none;
  }
}

.fixed .clone-nav li a {
  font-size: 16px;
}

.fixed #hednav {
  display: block !important;
}

#header {
  padding: 30px 0;
  z-index: 10;
}

@media only screen and (max-width: 1000px) {
  #header {
    padding: 30px 10px;
  }
}

@media only screen and (max-width: 950px) {
  #header {
    padding: 30px 16px;
  }
}

#header .inner {
  display: flex;
  justify-content: space-between;
}

#header .flxL {
  width: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

#header .flxL #siteID {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

#header .flxL #siteID img {
  width: 100% !important;
  max-width: calc( 400px / 2) !important;
}

#header .flxR {
  margin-left: 7%;
  width: 73%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media only screen and (max-width: 700px) {
  #header .flxR {
    justify-content: flex-end;
  }
}

#header .flxR #hednav {
  display: none;
}

.clone-nav {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media only screen and (max-width: 700px) {
  .clone-nav {
    justify-content: flex-end;
  }
}

.clone-nav li {
  line-height: 1;
}

@media only screen and (max-width: 950px) {
  .clone-nav li {
    margin-left: 10px;
  }
  .clone-nav li:nth-child(1) {
    display: none;
  }
  .clone-nav li:nth-child(5) {
    display: none;
  }
  .clone-nav li:nth-child(6) {
    display: none;
  }
  .clone-nav li:nth-child(8) {
    display: none;
  }
}

@media only screen and (max-width: 700px) {
  .clone-nav li:nth-child(4) {
    display: none;
  }
  .clone-nav li:nth-child(6) {
    display: none;
  }
  .clone-nav li:nth-child(7) {
    display: none;
  }
}

.clone-nav li a {
  display: block;
  font-size: 20px;
  color: #181818;
  position: relative;
}

@media only screen and (max-width: 1000px) {
  .clone-nav li a {
    font-size: 18px;
  }
}

@media only screen and (max-width: 950px) {
  .clone-nav li a {
    padding: 0;
    font-size: 16px;
  }
}

.clone-nav li a:after {
  position: absolute;
  bottom: -4px;
  left: 0;
  content: '';
  width: 100%;
  height: 2px;
  background: #333;
  transform: scale(0, 1);
  transform-origin: center top;
  transition: transform .3s;
}

.clone-nav li a:hover {
  opacity: 1 !important;
}

.clone-nav li a:hover:after {
  transform: scale(1, 1);
}

.clone-nav li a span {
  display: none;
}

.clone-nav .fixnone {
  display: none;
}

.clone-nav.sns {
  display: none;
}

#hednav .wrap {
  position: relative;
  height: 30px;
  width: 30px;
}

@media only screen and (max-width: 700px) {
  #hednav .wrap {
    height: 20px;
    width: 24px;
  }
}

#hednav .wrap .global-menu-btn {
  cursor: pointer;
  cursor: hand;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 11;
  line-height: 1;
  transition: 1s;
  color: #181818;
  opacity: 1;
  transition: opacity 0.2s ease-out;
}

@media only screen and (max-width: 700px) {
  #hednav .wrap .global-menu-btn {
    height: 20px;
    width: 24px;
  }
}

#hednav .wrap .global-menu-btn:hover {
  opacity: 1;
}

#hednav .wrap .global-menu-btn::before {
  content: "";
  margin: auto;
  height: 30px;
  width: 30px;
  background: linear-gradient(to bottom, #181818, #181818 20%, transparent 20%, transparent 40%, #181818 40%, #181818 60%, transparent 60%, transparent 80%, #181818 80%, #181818 100%);
  transition: opacity 0.2s ease-out, width 0.2s 0.2s ease-out;
}

@media only screen and (max-width: 700px) {
  #hednav .wrap .global-menu-btn::before {
    height: 20px;
    width: 24px;
  }
}

#hednav .wrap .global-menu-btn:active {
  -webkit-transform: translateY(2px);
  transform: translateY(2px);
}

#hednav .wrap .global-menu-btn i {
  display: none;
}

#hednav .wrap .global-menu-close-btn {
  cursor: pointer;
  cursor: hand;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 11;
  line-height: 1;
  transition: 1s;
  color: #181818;
  opacity: 1;
  transition: opacity 0.2s ease-out;
}

@media only screen and (max-width: 700px) {
  #hednav .wrap .global-menu-close-btn {
    height: 21px;
    width: 21px;
  }
}

#hednav .wrap .global-menu-close-btn:hover {
  opacity: 1;
}

#hednav .wrap .global-menu-close-btn:before {
  opacity: 0;
  content: '×';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  font-family: Arial, sans-serif;
  font-size: 76px;
  line-height: 0;
  transition: opacity 0.4s ease-out;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media only screen and (max-width: 700px) {
  #hednav .wrap .global-menu-close-btn:before {
    font-size: 47px;
  }
}

#hednav .wrap .global-menu-close-btn:active {
  -webkit-transform: translateY(2px);
  transform: translateY(2px);
}

#hednav .wrap .global-menu-close-btn i {
  display: none;
}

.MenuOpened {
  display: block !important;
}

.MenuOpened .navwrap {
  pointer-events: auto;
}

.MenuOpened .wrap .global-menu-btn {
  opacity: 1 !important;
}

.MenuOpened .wrap .global-menu-btn:before {
  opacity: 0 !important;
  width: 0 !important;
}

.MenuOpened .wrap .global-menu-close-btn:before {
  opacity: 1 !important;
  -webkit-transform: translate3d(0, 0, 0) rotate(360deg) !important;
  transform: translate3d(0, 0, 0) rotate(360deg) !important;
  transition: opacity 0.4s 1s ease-out, -webkit-transform 0.4s 1s ease-out !important;
  transition: transform 0.4s 1s ease-out, opacity 0.4s 1s ease-out !important;
  transition: transform 0.4s 1s ease-out, opacity 0.4s 1s ease-out, -webkit-transform 0.4s 1s ease-out !important;
}

.navwrap {
  pointer-events: none;
  position: fixed;
  top: -100%;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  overflow: hidden;
}

.navwrap:before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  background: rgba(255, 255, 255, 0.95);
  width: 100%;
  height: 0;
  padding-bottom: 100%;
  border-radius: 100%;
  -webkit-transform: scale(0.04), translateY(9999px);
  transform: scale(0.04), translateY(9999px);
  overflow: hidden;
}

.MenuOpened .navwrap {
  top: 0;
}

.MenuOpened .navwrap:before {
  -webkit-animation: menu-animation 0.8s ease-out forwards;
  animation: menu-animation 0.8s ease-out forwards;
}

.navwrap nav {
  transition: 10s;
  position: fixed;
  max-width: 500px;
  width: 100%;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-perspective: 1000;
  perspective: 1000;
  color: white;
}

@media screen and (min-width: 701px) and (max-width: 950px) {
  .navwrap nav {
    transform: inherit;
    margin: 50px auto 30px;
    max-width: 500px;
    position: static;
  }
}

@media only screen and (max-width: 700px) {
  .navwrap nav {
    transform: inherit;
    margin: 50px auto 20px;
    max-width: 330px;
    position: static;
  }
}

.navwrap nav ul {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.navwrap nav ul li {
  text-align: left;
  width: 48%;
  opacity: 0;
  -webkit-transform: translate(0, 36px);
  transform: translate(0, 36px);
  margin-top: 50px;
}

@media only screen and (max-width: 700px) {
  .navwrap nav ul li {
    margin-top: 26px;
  }
}

.navwrap nav ul li.pcnone {
  display: block;
}

.navwrap nav ul li a {
  font-size: 15px;
  color: #181818;
}

.navwrap nav ul li a span {
  font-family: 'Viga', sans-serif;
  line-height: 1em;
  letter-spacing: 0.05em;
  font-weight: bold;
  font-size: 44px;
  display: block;
}

@media only screen and (max-width: 700px) {
  .navwrap nav ul li a span {
    font-size: 28px;
  }
}

.navwrap nav .sns {
  margin-top: 60px;
  align-items: center;
  justify-content: center;
}

@media only screen and (max-width: 700px) {
  .navwrap nav .sns {
    margin-top: 20px;
  }
}

.navwrap nav .sns li {
  margin-top: 0 !important;
  width: auto;
}

.navwrap nav .sns li:nth-child(2) {
  margin: 0 15px;
}

.navwrap nav .sns li a:hover {
  opacity: 1 !important;
}

.navwrap nav .sns li a img {
  opacity: 1 !important;
  width: 100% !important;
  max-width: calc( 100px / 2) !important;
}

.navwrap #hed_info {
  margin: 60px auto 0;
  max-width: 320px;
  position: relative;
}

@media screen and (min-width: 701px) and (max-width: 950px) {
  .navwrap #hed_info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 100%;
    padding: 0 50px;
  }
}

@media only screen and (max-width: 700px) {
  .navwrap #hed_info {
    margin: 0 auto;
  }
}

.navwrap #hed_info .tel2 {
  margin-bottom: 10px;
}

@media screen and (min-width: 701px) and (max-width: 950px) {
  .navwrap #hed_info .tel2 {
    margin-bottom: 0;
  }
}

@media screen and (min-width: 701px) and (max-width: 950px) {
  .navwrap #hed_info .hoverbtn {
    width: 48%;
  }
}

.navwrap #hed_info .hoverbtn > div {
  opacity: 0;
  -webkit-transform: translate(0, 36px);
  transform: translate(0, 36px);
  margin-top: 0 !important;
  width: auto;
}

.navwrap #hed_info .hoverbtn > div a {
  min-height: 80px;
  border-radius: 10px;
  background: #8fcb0e;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: left;
  padding: 15px 30px;
  color: #181818;
  font-size: 12px;
  font-weight: 700;
}

@media screen and (min-width: 701px) and (max-width: 950px) {
  .navwrap #hed_info .hoverbtn > div a {
    padding: 15px 20px;
  }
}

.navwrap #hed_info .hoverbtn > div a:hover {
  opacity: 1 !important;
}

.navwrap #hed_info .hoverbtn > div a span {
  font-weight: 700;
  display: block;
  font-size: 18px;
  margin-bottom: 3px;
}

.navwrap #hed_info .hoverbtn > div a .txt {
  font-weight: 700;
  width: 100%;
  line-height: 1;
}

.navwrap #hed_info .hoverbtn > div a img {
  opacity: 1 !important;
  width: 100% !important;
  max-width: calc( 100px / 2) !important;
  margin-right: 15px;
}

.MenuOpened #hed_info .hoverbtn > div {
  opacity: 1;
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
  transition: opacity 0.2s ease-out, -webkit-transform 0.2s ease-out;
  transition: transform 0.2s ease-out, opacity 0.2s ease-out;
  transition: transform 0.2s ease-out, opacity 0.2s ease-out, -webkit-transform 0.2s ease-out;
}

.MenuOpened #hed_info .hoverbtn > div:nth-child(1) {
  transition-delay: 0.75s;
  margin-top: 0;
}

.MenuOpened #hed_info .hoverbtn > div:nth-child(2) {
  transition-delay: 0.85s;
  margin-top: 0;
}

.MenuOpened .navwrap ul li {
  opacity: 1;
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
  transition: opacity 0.2s ease-out, -webkit-transform 0.2s ease-out;
  transition: transform 0.2s ease-out, opacity 0.2s ease-out;
  transition: transform 0.2s ease-out, opacity 0.2s ease-out, -webkit-transform 0.2s ease-out;
}

.MenuOpened .navwrap ul li:nth-child(1) {
  transition-delay: 0.75s;
  margin-top: 0;
}

.MenuOpened .navwrap ul li:nth-child(2) {
  transition-delay: 0.85s;
  margin-top: 0;
}

.MenuOpened .navwrap ul li:nth-child(3) {
  transition-delay: 0.95s;
}

.MenuOpened .navwrap ul li:nth-child(4) {
  transition-delay: 1.05s;
}

.MenuOpened .navwrap ul li:nth-child(5) {
  transition-delay: 1.15s;
}

.MenuOpened .navwrap ul li:nth-child(6) {
  transition-delay: 1.25s;
}

.MenuOpened .navwrap ul li:nth-child(7) {
  transition-delay: 1.35s;
}

.MenuOpened .navwrap ul li:nth-child(8) {
  transition-delay: 1.45s;
}

.MenuOpened .navwrap nav ul li {
  opacity: 1;
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
  transition: opacity 0.2s ease-out, -webkit-transform 0.2s ease-out;
  transition: transform 0.2s ease-out, opacity 0.2s ease-out;
  transition: transform 0.2s ease-out, opacity 0.2s ease-out, -webkit-transform 0.2s ease-out;
}

.MenuOpened .navwrap nav ul li:nth-child(1) {
  transition-delay: 0.75s;
  margin-top: 0;
}

.MenuOpened .navwrap nav ul li:nth-child(2) {
  transition-delay: 0.85s;
  margin-top: 0;
}

.MenuOpened .navwrap nav ul li:nth-child(3) {
  transition-delay: 0.95s;
}

.MenuOpened .navwrap nav ul li:nth-child(4) {
  transition-delay: 1.05s;
}

.MenuOpened .navwrap nav ul li:nth-child(5) {
  transition-delay: 1.15s;
}

.MenuOpened .navwrap nav ul li:nth-child(6) {
  transition-delay: 1.25s;
}

.MenuOpened .navwrap nav ul li:nth-child(7) {
  transition-delay: 1.35s;
}

.MenuOpened .navwrap nav ul li:nth-child(8) {
  transition-delay: 1.45s;
}

.MenuOpened .navwrap nav .sns li a {
  transition: all .3s;
  display: block;
}

.MenuOpened .navwrap nav .sns li a:hover {
  transform: translateY(-5px);
}

@-webkit-keyframes menu-animation {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.04) translateY(300%);
    transform: scale(0.04) translateY(300%);
  }
  40% {
    -webkit-transform: scale(0.04) translateY(0);
    transform: scale(0.04) translateY(0);
    transition: ease-out;
  }
  40% {
    -webkit-transform: scale(0.04) translateY(0);
    transform: scale(0.04) translateY(0);
  }
  60% {
    opacity: 1;
    -webkit-transform: scale(0.02) translateY(0px);
    transform: scale(0.02) translateY(0px);
  }
  61% {
    -webkit-transform: scale(0.04);
    transform: scale(0.04);
  }
  99.9% {
    height: 0;
    padding-bottom: 100%;
    border-radius: 100%;
  }
  100% {
    -webkit-transform: scale(2);
    transform: scale(2);
    height: 100%;
    padding-bottom: 0;
    border-radius: 0;
  }
}

@keyframes menu-animation {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.04) translateY(300%);
    transform: scale(0.04) translateY(300%);
  }
  40% {
    -webkit-transform: scale(0.04) translateY(0);
    transform: scale(0.04) translateY(0);
    transition: ease-out;
  }
  40% {
    -webkit-transform: scale(0.04) translateY(0);
    transform: scale(0.04) translateY(0);
  }
  60% {
    opacity: 1;
    -webkit-transform: scale(0.02) translateY(0px);
    transform: scale(0.02) translateY(0px);
  }
  61% {
    -webkit-transform: scale(0.04);
    transform: scale(0.04);
  }
  99.9% {
    height: 0;
    padding-bottom: 100%;
    border-radius: 100%;
  }
  100% {
    -webkit-transform: scale(2);
    transform: scale(2);
    height: 100%;
    padding-bottom: 0;
    border-radius: 0;
  }
}

#mainArea {
  position: relative;
  z-index: 0;
}

#mainArea:before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: block;
  z-index: 1;
}

#mainArea ul li {
  margin-right: 2px;
  margin-left: 2px;
}

#mainArea2 {
  max-width: 1000px;
  margin: 0 auto 100px;
}

@media only screen and (max-width: 700px) {
  #mainArea2 {
    margin: 0 auto 60px;
  }
}

#mainArea2 #local-keyvisual {
  position: relative;
}

#mainArea2 #local-keyvisual .newsttl h1 {
  background: rgba(243, 225, 0, 0.85) !important;
}

#mainArea2 #local-keyvisual #local-keyvisual-title {
  text-align: left;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 70px;
  width: 70%;
  z-index: 1;
}

@media screen and (min-width: 701px) and (max-width: 950px) {
  #mainArea2 #local-keyvisual #local-keyvisual-title {
    bottom: 50px;
  }
}

@media only screen and (max-width: 700px) {
  #mainArea2 #local-keyvisual #local-keyvisual-title {
    bottom: 20px;
    width: 85%;
  }
}

#mainArea2 #local-keyvisual #local-keyvisual-title h1 {
  background: rgba(0, 175, 236, 0.85);
  padding: 0 50px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  height: 220px;
  color: #181818;
  font-weight: 700;
  line-height: 1;
  font-size: 44px;
}

@media screen and (min-width: 701px) and (max-width: 950px) {
  #mainArea2 #local-keyvisual #local-keyvisual-title h1 {
    padding: 60px 20px 52px;
    font-size: 30px;
    height: 180px;
  }
}

@media only screen and (max-width: 700px) {
  #mainArea2 #local-keyvisual #local-keyvisual-title h1 {
    padding: 25px 20px 20px;
    font-size: 24px;
    height: 110px;
  }
}

#mainArea2 #local-keyvisual #local-keyvisual-title h1:empty {
  display: none;
}

#mainArea2 #local-keyvisual #local-keyvisual-title h1 span {
  margin-top: 30px;
  display: block;
  font-size: 34px;
}

@media screen and (min-width: 701px) and (max-width: 950px) {
  #mainArea2 #local-keyvisual #local-keyvisual-title h1 span {
    font-size: 20px;
    margin-top: 20px;
  }
}

@media only screen and (max-width: 700px) {
  #mainArea2 #local-keyvisual #local-keyvisual-title h1 span {
    margin-top: 12px;
    font-size: 18px;
  }
}

#mainArea2 #local-keyvisual img {
  width: 100%;
  max-width: 780px;
  z-index: 0;
  margin: 0 0 0 auto;
}

@media screen and (min-width: 701px) and (max-width: 950px) {
  #mainArea2 #local-keyvisual img {
    padding: 0 0 0 100px;
  }
}

@media only screen and (max-width: 700px) {
  #mainArea2 #local-keyvisual img {
    padding: 0 0 0 50px;
  }
}

.pan {
  margin: 100px 0 2px 0;
  width: 100vw;
  height: 100%;
  margin-left: calc(((100vw - 100%) / 2) * -1);
  margin-right: calc(((100vw - 100%) / 2) * -1);
  padding: 15px 0;
  background: #efefef;
}

@media only screen and (max-width: 700px) {
  .pan {
    margin-top: 60px;
  }
}

.pan ul {
  max-width: 1000px;
  margin: 0 auto;
}

@media screen and (min-width: 701px) and (max-width: 950px) {
  .pan ul {
    padding: 0 40px;
  }
}

@media only screen and (max-width: 700px) {
  .pan ul {
    padding: 0 30px;
  }
}

.pan ul li {
  float: left;
  display: inline;
  font-size: 13px;
}

@media only screen and (max-width: 700px) {
  .pan ul li {
    font-size: 12px;
  }
}

.pan ul li:last-child:after {
  display: none;
}

.pan ul li:after {
  font-family: "Font Awesome 5 Pro";
  content: "\f061";
  font-size: 11px;
  vertical-align: middle;
  text-align: right;
  display: block;
  padding: 0 10px;
  font-weight: 600;
  float: right;
}

.pan a {
  font-size: 13px;
  color: #221815;
}

@media only screen and (max-width: 700px) {
  .pan a {
    font-size: 12px;
  }
}

footer#global_footer {
  width: 100%;
  min-width: 100% !important;
  height: auto;
  color: #181818;
}

footer#global_footer a {
  color: #181818;
  text-decoration: none;
}

footer#global_footer a:hover {
  text-decoration: none;
}

#footer {
  background: #efefef;
  padding: 100px 0;
}

@media only screen and (max-width: 700px) {
  #footer {
    padding: 55px 0;
  }
}

#footer .inner {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

@media only screen and (max-width: 1000px) {
  #footer .inner {
    padding: 0 10px;
  }
}

#footer .inner .flxL {
  width: 40%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

@media only screen and (max-width: 950px) {
  #footer .inner .flxL {
    display: none;
  }
}

#footer .inner .flxL .nav_footer dl {
  text-align: left;
}

#footer .inner .flxL .nav_footer dl dt {
  line-height: 1;
  margin-bottom: 18px;
  font-size: 16px;
  font-weight: 700;
}

#footer .inner .flxL .nav_footer dl dt a {
  opacity: 1;
  font-size: 16px;
  font-weight: 700;
}

#footer .inner .flxL .nav_footer dl dt a:before {
  position: absolute;
  bottom: -4px;
  left: 0;
  content: '';
  width: 100%;
  height: 2px;
  background: #333;
  transform: scale(0, 1);
  transform-origin: center top;
  transition: transform .3s;
}

#footer .inner .flxL .nav_footer dl dt:hover {
  opacity: 1 !important;
}

#footer .inner .flxL .nav_footer dl dt:hover a:before {
  transform: scale(1, 1);
}

#footer .inner .flxL .nav_footer dl dd {
  line-height: 1;
  position: relative;
  display: block;
  margin-bottom: 17px;
}

#footer .inner .flxL .nav_footer dl dd:before {
  content: "-";
  margin-right: 7px;
}

#footer .inner .flxL .nav_footer dl dd a {
  opacity: 1;
}

#footer .inner .flxL .nav_footer dl dd a:before {
  position: absolute;
  bottom: -4px;
  left: 0;
  content: '';
  width: 100%;
  height: 2px;
  background: #333;
  transform: scale(0, 1);
  transform-origin: center top;
  transition: transform .3s;
}

#footer .inner .flxL .nav_footer dl dd:hover {
  opacity: 1 !important;
}

#footer .inner .flxL .nav_footer dl dd:hover a:before {
  transform: scale(1, 1);
}

#footer .inner .flxL .nav_footer dl .blank {
  line-height: 1;
}

#footer .inner .flxL .nav_footer dl .blank a {
  line-height: 1;
  position: relative;
}

#footer .inner .flxL .nav_footer dl .blank a:after {
  font-family: "Font Awesome 5 Pro";
  content: "\f2d2";
  margin-left: 4px;
  font-size: 13px;
}

#footer .inner .flxL .nav1 dl:first-child {
  margin-bottom: 40px;
}

#footer .inner .flxL .nav1 dl dt a {
  position: relative;
  line-height: 1;
}

#footer .inner .flxL .nav1 dl dd a {
  position: relative;
  line-height: 1;
}

#footer .inner .flxL .nav2 ul {
  text-align: left;
  margin-top: 40px;
}

#footer .inner .flxL .nav2 ul li a {
  position: relative;
  margin-bottom: 10px;
  line-height: 1;
}

#footer .inner .flxL .nav2 ul li a:after {
  position: absolute;
  bottom: -4px;
  left: 0;
  content: '';
  width: 100%;
  height: 2px;
  background: #333;
  transform: scale(0, 1);
  transform-origin: center top;
  transition: transform .3s;
}

#footer .inner .flxL .nav2 ul li a:hover {
  opacity: 1 !important;
}

#footer .inner .flxL .nav2 ul li a:hover:after {
  transform: scale(1, 1);
}

#footer .inner .flxR {
  width: 55%;
}

@media only screen and (max-width: 950px) {
  #footer .inner .flxR {
    width: 100%;
  }
}

#footer .inner .flxR h2 {
  font-size: 34px;
  line-height: 1.6em;
  margin-bottom: 25px;
}

@media screen and (min-width: 701px) and (max-width: 950px) {
  #footer .inner .flxR h2 {
    font-size: 34px;
  }
}

@media only screen and (max-width: 700px) {
  #footer .inner .flxR h2 {
    font-size: 22px;
  }
}

#footer .inner .flxR .tel2 {
  font-family: 'Viga', sans-serif;
  line-height: 1em;
  letter-spacing: 0.05em;
  font-weight: bold;
  font-size: 35px;
  margin-top: 30px;
}

#footer .inner .flxR .tel2 img {
  width: 100%;
  max-width: calc( 120px / 3);
  margin-right: 8px;
}

@media only screen and (max-width: 700px) {
  #footer .inner .flxR .btn {
    max-width: 315px;
  }
}

#footer .inner .flxR p {
  margin-top: 20px;
  line-height: 1.8em;
}

@media only screen and (max-width: 700px) {
  #footer2 .inner2 {
    padding: 0 40px;
  }
}

#footer2 .inner2 .sns {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 100px 0 50px;
}

@media only screen and (max-width: 700px) {
  #footer2 .inner2 .sns {
    margin: 60px 0 30px;
  }
}

#footer2 .inner2 .sns li {
  margin: 0 10px;
}

#footer2 .inner2 .sns li a {
  display: block;
  transition: all .3s;
}

#footer2 .inner2 .sns li a:hover {
  opacity: 1 !important;
  transform: translateY(-5px);
}

#footer2 .inner2 .sns li a img {
  opacity: 1 !important;
  width: 100%;
  max-width: calc( 100px / 2);
}

#footer2 .inner2 address {
  margin: 30px 0;
}

#footer2 .inner2 p {
  margin-bottom: 50px;
  width: 100%;
  font-size: 20px;
}

@media only screen and (max-width: 1000px) {
  #footer2 .inner2 p {
    font-size: 18px;
  }
}

@media only screen and (max-width: 950px) {
  #footer2 .inner2 p {
    font-size: 16px;
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 700px) {
  #footer2 .inner2 p {
    font-size: 15px;
  }
}

#copy {
  margin-bottom: 100px;
  padding: 10px 0;
  text-align: center;
  font-size: 13px;
  display: block;
  line-height: 1.7em;
}

@media only screen and (max-width: 700px) {
  #copy {
    margin-bottom: 60px;
    text-align: center;
    padding: 0;
  }
}

#copy a {
  font-size: 13px;
  text-decoration: none;
}

#copy a:hover {
  opacity: 0.6;
}

@-webkit-keyframes border-transform {
  0%, 100% {
    border-radius: 63% 37% 54% 46% / 55% 48% 52% 45%;
  }
  14% {
    border-radius: 40% 60% 54% 46% / 49% 60% 40% 51%;
  }
  28% {
    border-radius: 54% 46% 38% 62% / 49% 70% 30% 51%;
  }
  42% {
    border-radius: 61% 39% 55% 45% / 61% 38% 62% 39%;
  }
  56% {
    border-radius: 61% 39% 67% 33% / 70% 50% 50% 30%;
  }
  70% {
    border-radius: 50% 50% 34% 66% / 56% 68% 32% 44%;
  }
  84% {
    border-radius: 46% 54% 50% 50% / 35% 61% 39% 65%;
  }
}

@keyframes border-transform {
  0%, 100% {
    border-radius: 63% 37% 54% 46% / 55% 48% 52% 45%;
  }
  14% {
    border-radius: 40% 60% 54% 46% / 49% 60% 40% 51%;
  }
  28% {
    border-radius: 54% 46% 38% 62% / 49% 70% 30% 51%;
  }
  42% {
    border-radius: 61% 39% 55% 45% / 61% 38% 62% 39%;
  }
  56% {
    border-radius: 61% 39% 67% 33% / 70% 50% 50% 30%;
  }
  70% {
    border-radius: 50% 50% 34% 66% / 56% 68% 32% 44%;
  }
  84% {
    border-radius: 46% 54% 50% 50% / 35% 61% 39% 65%;
  }
}

.progress-wrap {
  position: fixed;
  right: 50px;
  bottom: 50px;
  height: 50px;
  width: 50px;
  cursor: pointer;
  display: block;
  border-radius: 50px;
  z-index: 10;
  opacity: 0;
  visibility: hidden;
  transform: translateY(15px);
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
}

.progress-wrap:hover {
  transform: translateY(-3px);
}

@media only screen and (max-width: 700px) {
  .progress-wrap {
    right: 10px;
    bottom: 50px;
  }
}

.progress-wrap.active-progress {
  opacity: 1;
  visibility: visible;
}

.progress-wrap::after {
  font-family: "Font Awesome 5 Pro";
  content: "\f062";
  font-size: 20px;
  position: absolute;
  text-align: center;
  line-height: 50px;
  left: 0;
  top: 0;
  height: 50px;
  width: 50px;
  cursor: pointer;
  display: block;
  z-index: 0;
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
}

.progress-wrap::before {
  font-family: "Font Awesome 5 Pro";
  content: "\f062";
  font-size: 20px;
  position: absolute;
  text-align: center;
  line-height: 50px;
  opacity: 0;
  background-image: linear-gradient(298deg, #da2c4d, #f8ab37);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  left: 0;
  top: 0;
  height: 50px;
  width: 50px;
  cursor: pointer;
  display: block;
  z-index: 0;
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
}

.progress-wrap svg {
  z-index: 1;
  position: relative;
  border-radius: 50%;
}

.progress-wrap svg path {
  fill: none;
}

.progress-wrap svg.progress-circle path {
  stroke: #181818;
  stroke-width: 5;
  box-sizing: border-box;
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
}

body.light .progress-wrap::after {
  color: #181818;
}

body.light .progress-wrap svg.progress-circle path {
  stroke: #181818;
}

.switch,
.circle {
  -webkit-transition: all 300ms linear;
  transition: all 300ms linear;
}

.switch {
  width: 80px;
  height: 4px;
  border-radius: 27px;
  background-image: linear-gradient(298deg, #da2c4d, #f8ab37);
  position: fixed;
  right: 50px;
  top: 75px;
  display: block;
  margin: 0 auto;
  text-align: center;
  opacity: 1;
  z-index: 33333;
  transition: all 300ms linear;
}

.circle {
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: -5px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #4b4d64;
  box-shadow: 0 4px 4px rgba(26, 53, 71, 0.25), 0 0 0 1px rgba(26, 53, 71, 0.07);
  animation: border-transform 10s linear infinite alternate forwards;
}

.circle:before {
  position: absolute;
  font-family: 'unicons';
  content: '\eac1';
  top: 0;
  left: 0;
  z-index: 2;
  font-size: 20px;
  line-height: 40px;
  text-align: center;
  width: 100%;
  height: 40px;
  opacity: 1;
  color: #ff8695;
  -webkit-transition: all 300ms linear;
  transition: all 300ms linear;
}

.circle:after {
  position: absolute;
  font-family: 'unicons';
  content: '\eb8f';
  top: 0;
  left: 0;
  z-index: 2;
  font-size: 20px;
  line-height: 40px;
  text-align: center;
  width: 100%;
  height: 40px;
  color: #f8ab37;
  opacity: 0;
  -webkit-transition: all 300ms linear;
  transition: all 300ms linear;
}

.switched .circle {
  left: 45px;
  box-shadow: 0 4px 4px rgba(26, 53, 71, 0.25), 0 0 0 1px rgba(26, 53, 71, 0.07);
  background: #1f2029;
}

.switched .circle:before {
  opacity: 0;
}

.switched .circle:after {
  opacity: 1;
}

.link a {
  line-height: 1;
  position: relative;
  color: #66ad01;
}

.link a:after {
  position: absolute;
  bottom: -4px;
  left: 0;
  content: '';
  width: 100%;
  height: 1px;
  background: #66ad01;
  transform: scale(0, 1);
  transform-origin: center top;
  transition: transform .3s;
}

@media only screen and (max-width: 700px) {
  .link a:after {
    bottom: 0;
  }
}

.link a:hover {
  opacity: 1 !important;
}

.link a:hover:after {
  transform: scale(1, 1);
}

.btn {
  overflow: hidden;
  position: relative;
  display: block;
  border-radius: 5px;
  max-width: 400px;
  margin: 0 auto;
}

@media only screen and (max-width: 700px) {
  .btn {
    max-width: 315px;
  }
}

.btn .su_button_circle {
  background-color: #f3e100;
  border-radius: 1000px;
  position: absolute;
  left: 0;
  top: 0;
  width: 0px;
  height: 0px;
  margin-left: 0px;
  margin-top: 0px;
  pointer-events: none;
}

.btn .explode-circle {
  animation: explode 0.5s forwards;
}

.btn .desplode-circle {
  animation: desplode 0.5s forwards;
}

.btn button {
  opacity: 1 !important;
  text-align: center;
  transition: 400ms;
  text-decoration: none;
  z-index: 2;
  background: #8fcb0e;
  font-size: 22px;
  border-radius: 5px;
  display: block;
  padding: 25px 10px;
  line-height: 1;
}

.btn button:after {
  font-family: "Font Awesome 5 Pro";
  content: "\f061";
  position: absolute;
  right: 20px;
  bottom: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.btn button:hover {
  opacity: 1 !important;
}

.btn button span {
  position: relative;
  z-index: 1;
}

.btn a {
  color: #181818;
  opacity: 1 !important;
  text-align: center;
  transition: 400ms;
  text-decoration: none;
  z-index: 2;
  background: #8fcb0e;
  font-size: 22px;
  border-radius: 5px;
  display: block;
  padding: 25px 10px;
  line-height: 1;
}

.btn a:after {
  font-family: "Font Awesome 5 Pro";
  content: "\f061";
  position: absolute;
  right: 20px;
  bottom: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.btn a:hover {
  opacity: 1 !important;
}

.btn a span {
  position: relative;
  z-index: 1;
}

@keyframes explode {
  0% {
    width: 0px;
    height: 0px;
    margin-left: 0px;
    margin-top: 0px;
    background-color: rgba(15, 126, 3, 0.2);
  }
  100% {
    width: 900px;
    height: 900px;
    margin-left: -450px;
    margin-top: -450px;
    background-color: rgba(243, 225, 0, 0.8);
  }
}

@keyframes desplode {
  0% {
    width: 900px;
    height: 900px;
    margin-left: -450px;
    margin-top: -450px;
    background-color: rgba(243, 225, 0, 0.8);
  }
  100% {
    width: 0px;
    height: 0px;
    margin-left: 0px;
    margin-top: 0px;
    background-color: rgba(16, 165, 1, 0.6);
  }
}

.hoverimg a:hover {
  opacity: 1 !important;
}

.hoverimg .box {
  position: relative;
}

.hoverimg .box img {
  opacity: 1 !important;
}

.hoverimg .box a:hover {
  opacity: 1 !important;
}

.hoverimg .box::before, .hoverimg .box::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: scale3d(0, 0, 1);
  transition: transform .3s ease-out 0s;
  background: rgba(255, 255, 255, 0.1);
  content: '';
  pointer-events: none;
}

.hoverimg .box::before {
  transform-origin: left top;
}

.hoverimg .box::after {
  transform-origin: right bottom;
}

.hoverimg .box:hover::before, .hoverimg .box:hover::after, .hoverimg .box:focus::before, .hoverimg .box:focus::after {
  transform: scale3d(1, 1, 1);
}

.hoverimg2 .sns_list a:hover {
  opacity: 1 !important;
}

.hoverimg2 .sns_list > div {
  position: relative;
}

.hoverimg2 .sns_list > div::before, .hoverimg2 .sns_list > div::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: scale3d(0, 0, 1);
  transition: transform .3s ease-out 0s;
  background: rgba(255, 255, 255, 0.1);
  content: '';
  pointer-events: none;
  z-index: 1;
}

.hoverimg2 .sns_list > div::before {
  transform-origin: left top;
}

.hoverimg2 .sns_list > div::after {
  transform-origin: right bottom;
}

.hoverimg2 .sns_list > div:hover::before, .hoverimg2 .sns_list > div:hover::after, .hoverimg2 .sns_list > div:focus::before, .hoverimg2 .sns_list > div:focus::after {
  transform: scale3d(1, 1, 1);
}

.hoverimg3 {
  position: relative;
}

.hoverimg3 a:hover {
  opacity: 1 !important;
}

.hoverimg3 img {
  opacity: 1 !important;
}

.hoverimg3 a:hover {
  opacity: 1 !important;
}

.hoverimg3::before, .hoverimg3::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: scale3d(0, 0, 1);
  transition: transform .3s ease-out 0s;
  background: rgba(255, 255, 255, 0.1);
  content: '';
  pointer-events: none;
}

.hoverimg3::before {
  transform-origin: left top;
}

.hoverimg3::after {
  transform-origin: right bottom;
}

.hoverimg3:hover::before, .hoverimg3:hover::after, .hoverimg3:focus::before, .hoverimg3:focus::after {
  transform: scale3d(1, 1, 1);
}

.jstarget .box {
  opacity: 0.1;
  transform: translate(0px, 100px);
  transition: all 500ms;
}

.jstarget .box:nth-of-type(2) {
  transition-delay: 200ms;
}

.jstarget .box:nth-of-type(3) {
  transition-delay: 400ms;
}

.jstarget .box:nth-of-type(4) {
  transition-delay: 600ms;
}

.jstarget .box:nth-of-type(5) {
  transition-delay: 800ms;
}

.jstarget .box:nth-of-type(6) {
  transition-delay: 1000ms;
}

.jstarget .box:nth-of-type(7) {
  transition-delay: 1200ms;
}

.jstarget .box:nth-of-type(8) {
  transition-delay: 1400ms;
}

.jstarget .box:nth-of-type(9) {
  transition-delay: 1600ms;
}

.jstarget.effect .box {
  opacity: 1;
  transform: translate(0, 0);
}

.jstarget .sns_list > div {
  opacity: 0.1;
  transform: translate(0px, 100px);
  transition: all 500ms;
}

.jstarget .sns_list > div:nth-of-type(2) {
  transition-delay: 200ms;
}

.jstarget .sns_list > div:nth-of-type(3) {
  transition-delay: 400ms;
}

.jstarget .sns_list > div:nth-of-type(4) {
  transition-delay: 600ms;
}

.jstarget .sns_list > div:nth-of-type(5) {
  transition-delay: 800ms;
}

.jstarget .sns_list > div:nth-of-type(6) {
  transition-delay: 1000ms;
}

.jstarget .sns_list > div:nth-of-type(7) {
  transition-delay: 1200ms;
}

.jstarget .sns_list > div:nth-of-type(8) {
  transition-delay: 1400ms;
}

.jstarget .sns_list > div:nth-of-type(9) {
  transition-delay: 1600ms;
}

.jstarget.effect .sns_list > div {
  opacity: 1;
  transform: translate(0, 0);
}

.cont_1 {
  padding: 100px 0;
  background: #00afec;
}

@media only screen and (max-width: 700px) {
  .cont_1 {
    padding: 60px 0;
    margin: 0 0 60px 0 !important;
  }
}

.cont_1 .inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

@media only screen and (max-width: 1000px) {
  .cont_1 .inner {
    padding: 0 10px;
  }
}

@media screen and (min-width: 701px) and (max-width: 950px) {
  .cont_1 .inner {
    padding: 0 20px 0 40px;
  }
}

@media only screen and (max-width: 950px) {
  .cont_1 .inner {
    flex-wrap: wrap;
  }
}

@media only screen and (max-width: 700px) {
  .cont_1 .inner {
    padding: 0 30px;
  }
}

.cont_1 .inner .midashi {
  width: 100%;
}

@media screen and (min-width: 701px) and (max-width: 950px) {
  .cont_1 .inner .midashi2 {
    margin-left: -13px !important;
  }
}

.cont_1 .inner .midashi2 h3 {
  line-height: 1.6em;
}

.cont_1 .inner .flxL {
  width: 57%;
}

@media screen and (min-width: 701px) and (max-width: 950px) {
  .cont_1 .inner .flxL {
    width: 57%;
  }
}

@media only screen and (max-width: 700px) {
  .cont_1 .inner .flxL {
    width: 100%;
    margin-bottom: 20px;
  }
}

.cont_1 .inner .flxL .txt {
  line-height: 1.8em;
  font-size: 20px;
}

@media screen and (min-width: 701px) and (max-width: 950px) {
  .cont_1 .inner .flxL .txt {
    font-size: 18px;
    max-width: 354px;
  }
}

@media only screen and (max-width: 700px) {
  .cont_1 .inner .flxL .txt {
    font-size: 16px;
    max-width: 100%;
  }
}

.cont_1 .inner .flxR {
  width: 42%;
}

@media screen and (min-width: 701px) and (max-width: 950px) {
  .cont_1 .inner .flxR {
    width: 45%;
    margin-left: -3%;
  }
}

@media only screen and (max-width: 700px) {
  .cont_1 .inner .flxR {
    width: 100%;
  }
}

.cont_2wrap {
  max-width: 1000px;
  margin: 0 auto 100px;
}

@media only screen and (max-width: 1000px) {
  .cont_2wrap {
    padding: 0 10px;
  }
}

@media screen and (min-width: 701px) and (max-width: 950px) {
  .cont_2wrap {
    max-width: 100%;
    padding: 0 40px;
    margin: 0 auto 60px;
  }
}

@media only screen and (max-width: 700px) {
  .cont_2wrap {
    padding: 0 30px;
    margin: 0 auto 60px;
  }
}

.cont_2wrap .cont_2 {
  margin-left: -3.38164%;
}

@media only screen and (max-width: 950px) {
  .cont_2wrap .cont_2 {
    margin-left: 0;
    justify-content: space-between;
  }
}

.cont_2wrap .cont_2 .box {
  width: 29.95169%;
  margin-left: 3.38164%;
  margin-bottom: 3.38164%;
  border: 1px solid #dddddd;
}

@media only screen and (max-width: 700px) {
  .cont_2wrap .cont_2 .box:last-child {
    margin-bottom: 0 !important;
  }
}

@media screen and (min-width: 701px) and (max-width: 950px) {
  .cont_2wrap .cont_2 .box {
    width: 47%;
    margin-left: 0;
    margin-bottom: 40px;
  }
}

@media only screen and (max-width: 700px) {
  .cont_2wrap .cont_2 .box {
    width: 100%;
    margin-left: 0;
    margin-bottom: 30px;
  }
}

.cont_2wrap .cont_2 .box a {
  color: #181818;
  font-size: 24px;
  text-align: center;
}

@media only screen and (max-width: 700px) {
  .cont_2wrap .cont_2 .box a {
    font-size: 22px;
  }
}

.cont_2wrap .cont_2 .box a:hover {
  opacity: 1 !important;
}

.cont_2wrap .cont_2 .box a div {
  line-height: 1;
  padding: 20px;
}

.cont_2wrap .cont_2 .box a img {
  opacity: 1 !important;
}

.cont_3 {
  background: #f3e100;
  padding: 100px 0;
}

@media only screen and (max-width: 700px) {
  .cont_3 {
    padding: 60px 0;
    margin: 0 0 60px 0 !important;
  }
}

.cont_3 .inner {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
}

@media only screen and (max-width: 1000px) {
  .cont_3 .inner {
    padding: 0 10px;
  }
}

@media screen and (min-width: 701px) and (max-width: 950px) {
  .cont_3 .inner {
    max-width: 100%;
    padding: 0 40px;
  }
}

@media only screen and (max-width: 700px) {
  .cont_3 .inner {
    padding: 0 30px;
  }
}

.cont_3 .inner .midashi {
  width: 100%;
}

.cont_3 .inner .flxL {
  width: 60%;
}

@media only screen and (max-width: 950px) {
  .cont_3 .inner .flxL {
    width: 100%;
  }
}

.cont_3 .inner .flxL .newswrap {
  background: rgba(255, 255, 255, 0.4);
  padding: 40px 0 40px 40px;
  height: 400px;
  overflow-y: auto;
}

.cont_3 .inner .flxL .newswrap .news {
  z-index: 1;
  position: relative;
  height: 100%;
  padding-right: 40px;
}

.cont_3 .inner .flxL .newswrap .news .blog_list {
  border: 0;
}

.cont_3 .inner .flxL .newswrap .news .blog_list .blog_photo {
  display: none;
}

.cont_3 .inner .flxL .newswrap .news .blog_list > div {
  border-bottom: 0;
  margin-bottom: 30px;
}

.cont_3 .inner .flxL .newswrap .news .blog_list .blog_text {
  width: 100%;
  padding: 0;
  font-size: 16px;
}

.cont_3 .inner .flxL .newswrap .news .blog_list .blog_text .detawrap {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 10px;
}

.cont_3 .inner .flxL .newswrap .news .blog_list .blog_text .detawrap .wrapdeta .blog_date {
  line-height: 1;
  font-size: 16px;
  font-weight: 700;
  color: #181818;
}

.cont_3 .inner .flxL .newswrap .news .blog_list .blog_text .detawrap .wrapdeta ul {
  display: block;
}

.cont_3 .inner .flxL .newswrap .news .blog_list .blog_text .detawrap .wrapdeta ul li {
  display: none;
}

.cont_3 .inner .flxL .newswrap .news .blog_list .blog_text .detawrap .wrapdeta ul li:first-child {
  display: block;
}

.cont_3 .inner .flxL .newswrap .news .blog_list .blog_text .detawrap .wrapdeta ul li a {
  line-height: 1;
  margin-left: 10px;
  display: block;
  font-size: 12px;
  border: 1px solid #181818;
  color: #181818;
  border-radius: 20px;
  padding: 3px 10px;
}

.cont_3 .inner .flxL .newswrap .news .blog_list .blog_text h3 {
  margin-bottom: 0;
}

.cont_3 .inner .flxL .newswrap .news .blog_list .blog_text h3 a {
  font-size: 16px;
  color: #181818;
}

.cont_3 .inner .flxL .newswrap .news .blog_list .blog_text .detail {
  display: none;
}

.cont_3 .inner .flxL .newswrap .news dl {
  padding: 15px 0;
  border-bottom: 1px dashed #333;
  display: block !important;
}

.cont_3 .inner .flxL .newswrap .news dl dt, .cont_3 .inner .flxL .newswrap .news dl dd {
  padding: 0 !important;
  width: 100% !important;
  border: 0 !important;
  background-color: inherit !important;
}

.cont_3 .inner .flxR {
  width: 35%;
}

@media only screen and (max-width: 950px) {
  .cont_3 .inner .flxR {
    width: 100%;
    margin-top: 60px;
  }
  .cont_3 .inner .flxR iframe {
    width: 100% !important;
  }
}

@media only screen and (max-width: 700px) {
  .cont_3 .inner .flxR {
    margin-top: 30px;
  }
}

.cont_3 .inner .flxR iframe {
  height: 400px !important;
}

.cont_4wrap {
  max-width: 1000px;
  margin: 0 auto 100px;
  overflow: hidden;
}

@media only screen and (max-width: 1000px) {
  .cont_4wrap {
    padding: 0 10px;
  }
}

@media screen and (min-width: 701px) and (max-width: 950px) {
  .cont_4wrap {
    padding: 0;
  }
}

@media only screen and (max-width: 700px) {
  .cont_4wrap {
    margin: 0 auto 60px !important;
  }
}

.cont_4wrap .cont_4 .owl-stage {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
}

.cont_4wrap .cont_4 .owl-stage .owl-item.center {
  text-align: left;
}

.cont_4wrap .cont_4 .owl-stage .owl-item li article {
  color: #181818;
}

.cont_4wrap .cont_4 .owl-stage .owl-item li article > a {
  display: block;
  margin-bottom: 20px;
}

.cont_4wrap .cont_4 .owl-stage .owl-item li article > a img {
  width: 100% !important;
}

.cont_4wrap .cont_4 .owl-stage .owl-item li article h3 {
  line-height: 1em;
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 15px;
}

.cont_4wrap .cont_4 .owl-stage .owl-item li article > div {
  line-height: 1.5em;
}

.cont_4wrap .cont_4 .owl-stage .owl-item li article > div a {
  color: #181818;
}

.cont_4wrap .cont_4 .owl-stage .owl-item li article > div a:after {
  font-family: "Font Awesome 5 Pro";
  content: "\f2d2";
  margin-left: 4px;
  font-size: 13px;
}

.cont_4wrap .cont_4 .owl-nav {
  display: none !important;
}

.cont_5 {
  background: #e9546b;
  padding: 100px 0;
}

@media screen and (min-width: 701px) and (max-width: 950px) {
  .cont_5 {
    padding: 100px 0 60px;
  }
}

@media only screen and (max-width: 700px) {
  .cont_5 {
    padding: 60px 0 45px;
    margin: 0 0 60px 0 !important;
  }
}

@media only screen and (max-width: 1000px) {
  .cont_5 .inner {
    padding: 0 10px;
  }
}

@media screen and (min-width: 701px) and (max-width: 950px) {
  .cont_5 .inner {
    max-width: 100%;
    padding: 0 40px;
  }
}

@media only screen and (max-width: 700px) {
  .cont_5 .inner {
    padding: 0 30px;
  }
}

.cont_5 .media .sns_list {
  border: 0;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

@media only screen and (max-width: 700px) {
  .cont_5 .media .sns_list {
    justify-content: space-between;
    margin-left: 0;
  }
}

.cont_5 .media .sns_list > div {
  width: 23%;
  justify-content: space-between;
  padding: 0;
  border-bottom: 0;
}

@media only screen and (max-width: 950px) {
  .cont_5 .media .sns_list > div {
    flex-direction: row !important;
    width: 47%;
    margin-bottom: 40px;
  }
}

@media only screen and (max-width: 700px) {
  .cont_5 .media .sns_list > div {
    width: 48%;
    margin-bottom: 15px;
    display: block !important;
  }
}

.cont_5 .media .sns_list > div .sns_text {
  display: none;
}

.cont_5 .media .sns_list > div .sns_photo {
  width: 100%;
  padding: 0;
}

@media only screen and (max-width: 700px) {
  .cont_5 .media .sns_list > div .sns_photo {
    width: 100%;
  }
}

.cont_5 .media .sns_list > div .sns_photo > a {
  display: block;
  position: relative;
}

.cont_5 .media .sns_list > div .sns_photo > a:hover {
  opacity: 1 !important;
}

.cont_5 .media .sns_list > div .sns_photo > a:before {
  content: "";
  width: 100%;
  padding-top: 100%;
  display: block;
}

@media only screen and (max-width: 700px) {
  .cont_5 .media .sns_list > div .sns_photo > a:before {
    padding-top: 100%;
  }
}

.cont_5 .media .sns_list > div .sns_photo img {
  opacity: 1 !important;
  height: 100% !important;
  width: 100% !important;
  object-fit: cover;
  font-family: 'object-fit: cover;';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.cont_6 {
  max-width: 1000px;
  align-items: center;
  justify-content: space-between;
}

@media only screen and (max-width: 1000px) {
  .cont_6 {
    padding: 0 10px;
  }
}

@media screen and (min-width: 701px) and (max-width: 950px) {
  .cont_6 {
    padding: 0 0;
    display: block !important;
    max-width: 482px;
  }
}

@media only screen and (max-width: 700px) {
  .cont_6 {
    padding: 0 50px;
    margin: 0 auto 60px auto !important;
  }
}

.cont_6 .box {
  width: 48%;
}

@media only screen and (max-width: 950px) {
  .cont_6 .box {
    width: 100%;
  }
  .cont_6 .box:first-child {
    margin-bottom: 40px;
  }
}

@media only screen and (max-width: 950px) and (max-width: 700px) {
  .cont_6 .box:first-child {
    margin-bottom: 30px;
  }
}

.brsp {
  display: none;
}

@media only screen and (max-width: 700px) {
  .brsp {
    display: block;
  }
}

.lh1 {
  line-height: 1 !important;
}

.lh16 {
  line-height: 1.6em !important;
}

.lh16 h2 {
  line-height: 1.6em !important;
}

.lh18 {
  line-height: 1.8em !important;
}

.lh18 h2 {
  line-height: 1.8em !important;
}

/* title */
h2.mail.title {
  display: none !important;
}

@media only screen and (max-width: 700px) {
  .midashi {
    margin: 0 0 40px 0 !important;
  }
}

.midashi h2 {
  font-weight: 400;
  line-height: 1;
  color: #181818;
  font-size: 62px;
}

@media only screen and (max-width: 1000px) {
  .midashi h2 {
    font-size: 62px;
  }
}

@media only screen and (max-width: 700px) {
  .midashi h2 {
    font-size: 40px;
  }
}

.midashi2 h3 {
  font-weight: 400;
  line-height: 1;
  color: #181818;
  font-size: 34px;
}

@media only screen and (max-width: 1000px) {
  .midashi2 h3 {
    font-size: 32px;
  }
}

@media screen and (min-width: 701px) and (max-width: 950px) {
  .midashi2 h3 {
    line-height: 1.8em;
    font-size: 32px;
  }
}

@media only screen and (max-width: 700px) {
  .midashi2 h3 {
    line-height: 1.8em;
    font-size: 22px;
    font-weight: 700;
  }
}

@media only screen and (max-width: 700px) {
  .midashi3 div {
    text-align: left !important;
  }
}

.midashi3 h2 {
  letter-spacing: 0;
  color: #181818;
  line-height: 1;
  font-weight: 700;
  font-size: 34px;
}

@media only screen and (max-width: 1000px) {
  .midashi3 h2 {
    font-size: 32px;
  }
}

@media screen and (min-width: 701px) and (max-width: 950px) {
  .midashi3 h2 {
    line-height: 1.6em;
    font-size: 25px;
  }
}

@media only screen and (max-width: 700px) {
  .midashi3 h2 {
    line-height: 1.6em;
    font-size: 22px;
  }
}

.midashi4 h2 {
  text-align: center;
  font-family: 'Viga', sans-serif;
  letter-spacing: 0.05em;
  color: #181818;
  line-height: 1;
  font-weight: 500;
  position: relative;
  display: block;
  padding: 0 55px;
  font-size: 16px;
}

@media only screen and (max-width: 1000px) {
  .midashi4 h2 {
    font-size: 16px;
  }
}

@media screen and (min-width: 701px) and (max-width: 950px) {
  .midashi4 h2 {
    line-height: 1.6em;
    font-size: 16px;
  }
}

@media only screen and (max-width: 700px) {
  .midashi4 h2 {
    line-height: 1.6em;
    font-size: 16px;
  }
}

.midashi4 h2 span {
  display: inline-block;
  background: #fff;
  padding: 0 22px;
  z-index: 1;
  position: relative;
}

.midashi4 h2:before, .midashi4 h2:after {
  z-index: 0;
  content: '';
  position: absolute;
  top: 50%;
  display: block;
  width: 100%;
  height: 1px;
  background-color: black;
}

.midashi4 h2:before {
  left: 0;
}

.midashi4 h2:after {
  right: 0;
}

.midashi5 h3 {
  letter-spacing: 0.05em;
  line-height: 1;
  font-weight: 700;
  color: #00afec;
  font-size: 26px;
}

@media only screen and (max-width: 1000px) {
  .midashi5 h3 {
    font-size: 24px;
  }
}

@media screen and (min-width: 701px) and (max-width: 950px) {
  .midashi5 h3 {
    line-height: 1.6em;
    font-size: 20px;
  }
}

@media only screen and (max-width: 700px) {
  .midashi5 h3 {
    line-height: 1.6em;
    font-size: 18px;
  }
}

.midashi6 h2 a {
  letter-spacing: 0.05em;
  line-height: 1;
  font-weight: 700;
  color: #181818;
  border-bottom: 2px solid #181818;
  padding-bottom: 10px;
  font-size: 26px;
}

@media only screen and (max-width: 1000px) {
  .midashi6 h2 a {
    font-size: 24px;
  }
}

@media screen and (min-width: 701px) and (max-width: 950px) {
  .midashi6 h2 a {
    line-height: 1.6em;
    font-size: 20px;
  }
}

@media only screen and (max-width: 700px) {
  .midashi6 h2 a {
    line-height: 1.6em;
    font-size: 18px;
  }
}

.midashi7 h3 {
  letter-spacing: 0.05em;
  line-height: 1;
  font-weight: 700;
  color: #181818;
  font-size: 18px;
}

@media only screen and (max-width: 1000px) {
  .midashi7 h3 {
    font-size: 18px;
  }
}

@media screen and (min-width: 701px) and (max-width: 950px) {
  .midashi7 h3 {
    line-height: 1.6em;
    font-size: 16px;
  }
}

@media only screen and (max-width: 700px) {
  .midashi7 h3 {
    line-height: 1.6em;
    font-size: 16px;
  }
}

.midashi7 h4 {
  letter-spacing: 0.05em;
  line-height: 1;
  font-weight: 700;
  color: #181818;
  font-size: 18px;
}

@media only screen and (max-width: 1000px) {
  .midashi7 h4 {
    font-size: 18px;
  }
}

@media screen and (min-width: 701px) and (max-width: 950px) {
  .midashi7 h4 {
    line-height: 1.6em;
    font-size: 16px;
  }
}

@media only screen and (max-width: 700px) {
  .midashi7 h4 {
    line-height: 1.6em;
    font-size: 16px;
  }
}

.midashi8 h3 {
  padding-bottom: 5px;
  letter-spacing: 0.05em;
  line-height: 1.4em;
  font-weight: 700;
  color: #181818;
  border-bottom: 2px solid #181818;
  padding-bottom: 10px;
  font-size: 26px;
}

@media only screen and (max-width: 1000px) {
  .midashi8 h3 {
    font-size: 24px;
  }
}

@media screen and (min-width: 701px) and (max-width: 950px) {
  .midashi8 h3 {
    line-height: 1.6em;
    font-size: 20px;
  }
}

@media only screen and (max-width: 700px) {
  .midashi8 h3 {
    line-height: 1.6em;
    font-size: 18px;
    padding-bottom: 0;
  }
}

.midashi8 h2 {
  padding-bottom: 5px;
  letter-spacing: 0.05em;
  line-height: 1.4em;
  font-weight: 700;
  color: #181818;
  border-bottom: 2px solid #181818;
  font-size: 26px;
}

@media only screen and (max-width: 1000px) {
  .midashi8 h2 {
    font-size: 24px;
  }
}

@media screen and (min-width: 701px) and (max-width: 950px) {
  .midashi8 h2 {
    line-height: 1.6em;
    font-size: 20px;
  }
}

@media only screen and (max-width: 700px) {
  .midashi8 h2 {
    line-height: 1.6em;
    font-size: 18px;
  }
}

.midashi9 h2 {
  padding-bottom: 10px;
  letter-spacing: 0;
  line-height: 1;
  font-weight: 700;
  color: #181818;
  border-bottom: 2px solid #181818;
  font-size: 26px;
}

@media only screen and (max-width: 1000px) {
  .midashi9 h2 {
    font-size: 24px;
  }
}

@media screen and (min-width: 701px) and (max-width: 950px) {
  .midashi9 h2 {
    line-height: 1.6em;
    font-size: 20px;
    padding-bottom: 5px;
  }
}

@media only screen and (max-width: 700px) {
  .midashi9 h2 {
    line-height: 1.6em;
    font-size: 18px;
    padding-bottom: 5px;
  }
}

.midashi9 h3 {
  padding-bottom: 10px;
  letter-spacing: 0;
  line-height: 1;
  font-weight: 700;
  color: #181818;
  border-bottom: 2px solid #181818;
  font-size: 26px;
}

@media only screen and (max-width: 1000px) {
  .midashi9 h3 {
    font-size: 24px;
  }
}

@media screen and (min-width: 701px) and (max-width: 950px) {
  .midashi9 h3 {
    line-height: 1.6em;
    font-size: 20px;
    padding-bottom: 5px;
  }
}

@media only screen and (max-width: 700px) {
  .midashi9 h3 {
    line-height: 1.6em;
    font-size: 18px;
    padding-bottom: 5px;
  }
}

.midashi10 h4 {
  line-height: 1;
  font-weight: 700;
  font-size: 18px;
  color: #181818 !important;
}

@media only screen and (max-width: 700px) {
  .midashi10 h4 {
    line-height: 1.6em;
    font-size: 16px;
  }
}

@media only screen and (max-width: 700px) {
  .midashi11 div {
    text-align: left !important;
  }
}

.midashi11 h2 {
  letter-spacing: 0;
  color: #181818;
  line-height: 1;
  font-weight: 700;
  font-size: 34px;
}

@media only screen and (max-width: 1000px) {
  .midashi11 h2 {
    font-size: 32px;
  }
}

@media screen and (min-width: 701px) and (max-width: 950px) {
  .midashi11 h2 {
    font-size: 30px;
  }
}

@media only screen and (max-width: 700px) {
  .midashi11 h2 {
    font-size: 22px;
  }
}

.lineup01 {
  font-size: 20px;
  line-height: 1;
}

@media only screen and (max-width: 700px) {
  .lineup01 {
    line-height: 1.7em;
    font-size: 16px;
  }
}

.lineup01 > div > span {
  font-size: 14px;
}

@media only screen and (max-width: 700px) {
  .lineup01 div {
    text-align: left !important;
  }
}

.lineup01 br {
  display: none;
}

.lineup01 .brtxt {
  display: block !important;
}

.lineup01 .brnone {
  display: block;
}

@media only screen and (max-width: 700px) {
  .lineup01 .brnone {
    display: none;
  }
}

.lineup02 {
  width: 100vw;
  height: 100%;
  margin-left: calc(((100vw - 100%) / 2) * -1);
  margin-right: calc(((100vw - 100%) / 2) * -1);
  padding: 100px 0 100px;
  background: #00afec;
}

@media only screen and (max-width: 700px) {
  .lineup02 {
    padding: 60px 0 60px;
  }
}

@media only screen and (max-width: 1000px) {
  .lineup02 .inner {
    padding: 0 10px;
  }
}

@media screen and (min-width: 701px) and (max-width: 950px) {
  .lineup02 .inner {
    padding: 0 40px;
  }
}

@media only screen and (max-width: 700px) {
  .lineup02 .inner {
    padding: 0 30px;
  }
}

.lineup02 .boxwrap {
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 700px) {
  .lineup02 .boxwrap {
    display: block;
  }
}

.lineup02 .boxwrap .box {
  height: 100%;
  width: 48%;
  margin-bottom: 4%;
  background: #fff;
}

.lineup02 .boxwrap .box:nth-last-child(1) {
  margin-bottom: 0;
}

.lineup02 .boxwrap .box:nth-last-child(2) {
  margin-bottom: 0;
}

@media only screen and (max-width: 700px) {
  .lineup02 .boxwrap .box:nth-last-child(2) {
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 700px) {
  .lineup02 .boxwrap .box {
    margin-bottom: 30px;
    width: 100%;
  }
}

.lineup02 .boxwrap .box a {
  opacity: 1 !important;
  display: block;
  color: #181818;
}

.lineup02 .boxwrap .box a:hover {
  opacity: 1 !important;
}

.lineup02 .boxwrap .box a article:after {
  font-family: "Font Awesome 5 Pro";
  content: "\f061";
  font-size: 35px;
  vertical-align: middle;
  text-align: right;
  display: block;
  padding: 0 30px 30px 0;
}

@media only screen and (max-width: 950px) {
  .lineup02 .boxwrap .box a article:after {
    padding: 0 20px 20px 20px;
  }
}

.lineup02 .boxwrap .box .img img {
  opacity: 1 !important;
  width: 100% !important;
}

.lineup02 .boxwrap .box .img img:hover {
  opacity: 1 !important;
}

.lineup02 .boxwrap .box .txtwrap {
  letter-spacing: 0;
  padding: 30px;
}

@media only screen and (max-width: 950px) {
  .lineup02 .boxwrap .box .txtwrap {
    padding: 20px 20px 10px 20px;
  }
}

.lineup02 .boxwrap .box .txtwrap h3 {
  line-height: 1.4em;
  display: block;
  margin-bottom: 15px;
  font-weight: 700;
  font-size: 26px;
}

@media only screen and (max-width: 1000px) {
  .lineup02 .boxwrap .box .txtwrap h3 {
    font-size: 24px;
  }
}

@media screen and (min-width: 701px) and (max-width: 950px) {
  .lineup02 .boxwrap .box .txtwrap h3 {
    font-size: 20px;
  }
}

@media only screen and (max-width: 700px) {
  .lineup02 .boxwrap .box .txtwrap h3 {
    font-size: 18px;
    margin-bottom: 10px;
  }
}

.lineup02 .boxwrap .box .txtwrap h3 span {
  display: block;
}

@media screen and (min-width: 701px) and (max-width: 950px) {
  .lineup02 .boxwrap .box .txtwrap h3 span {
    display: inline-block;
  }
}

.lineup02 .boxwrap .box .txtwrap .txt {
  line-height: 1.8em;
}

@media only screen and (max-width: 700px) {
  .lineup02 .boxwrap .box .txtwrap .txt {
    font-size: 15px;
  }
}

.blogshare, .loadshare, .bg100 {
  width: 100vw;
  margin-left: calc(((100vw - 100%) / 2) * -1);
  margin-right: calc(((100vw - 100%) / 2) * -1);
}

@media only screen and (max-width: 700px) {
  .blogshare, .loadshare, .bg100 {
    margin-left: 0;
    margin-right: 0;
    width: 100%;
  }
}

@media only screen and (max-width: 1000px) {
  .blogshare .inner, .loadshare .inner, .bg100 .inner {
    padding: 0 10px;
  }
}

@media screen and (min-width: 701px) and (max-width: 950px) {
  .blogshare .inner, .loadshare .inner, .bg100 .inner {
    padding: 0 40px;
  }
}

@media only screen and (max-width: 700px) {
  .blogshare .inner, .loadshare .inner, .bg100 .inner {
    padding: 0 !important;
  }
}

.blogshare .bginner iframe, .loadshare .bginner iframe, .bg100 .bginner iframe {
  vertical-align: middle;
}

.share {
  margin-bottom: 0 !important;
  margin-top: 100px !important;
}

@media screen and (min-width: 701px) and (max-width: 950px) {
  .share {
    margin-top: 100px !important;
  }
}

@media only screen and (max-width: 700px) {
  .share {
    margin-top: 60px !important;
  }
}

.share .midashi4 {
  margin: 100px 0 30px 0 !important;
}

@media only screen and (max-width: 700px) {
  .share .midashi4 {
    margin: 60px 0 30px 0 !important;
  }
}

.share ul {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 150px;
  margin: 0 auto;
}

.share li {
  list-style-type: none !important;
  overflow: inherit !important;
}

.share li a {
  display: block;
  transition: all .3s;
}

.share li a:hover {
  opacity: 1 !important;
  transform: translateY(-5px);
}

.share li a img {
  opacity: 1 !important;
  width: auto;
}

.originalgoods01 .inner {
  border: 2px solid #00afec;
  max-width: 600px;
  position: relative;
  padding: 50px 50px 50px 68px;
}

@media only screen and (max-width: 950px) {
  .originalgoods01 .inner {
    max-width: 100%;
  }
}

@media only screen and (max-width: 700px) {
  .originalgoods01 .inner {
    padding: 30px 30px 30px 48px;
  }
}

.originalgoods01 .inner .midashi5 {
  position: absolute;
  top: -13px;
  left: 50px;
  background: #fff;
  padding: 0 20px;
}

@media screen and (min-width: 701px) and (max-width: 950px) {
  .originalgoods01 .inner .midashi5 {
    top: -20px;
  }
}

@media only screen and (max-width: 700px) {
  .originalgoods01 .inner .midashi5 {
    top: -16px;
    left: 20px;
  }
}

.originalgoods01 .inner .boxwrap {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

@media only screen and (max-width: 700px) {
  .originalgoods01 .inner .boxwrap {
    display: block;
  }
}

.originalgoods01 .inner .boxwrap ul li {
  padding-left: 0 !important;
  list-style-position: outside !important;
  overflow: inherit !important;
  padding-left: 0 !important;
}

.originalgoods01 .inner .boxwrap .flxL {
  width: 48%;
}

@media only screen and (max-width: 700px) {
  .originalgoods01 .inner .boxwrap .flxL {
    width: 100%;
  }
}

.originalgoods01 .inner .boxwrap .flxR {
  width: 48%;
}

@media only screen and (max-width: 700px) {
  .originalgoods01 .inner .boxwrap .flxR {
    width: 100%;
  }
}

.rakuten01 {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row-reverse;
}

@media only screen and (max-width: 700px) {
  .rakuten01 {
    justify-content: flex-end;
  }
}

.rakuten01:before {
  font-family: "Font Awesome 5 Pro" !important;
  content: "\f2d2" !important;
  margin-left: 4px !important;
  font-size: 13px !important;
  visibility: initial !important;
  clear: inherit !important;
  height: auto !important;
}

.sitemap01wrap {
  max-width: 1000px;
  margin: 0 auto 100px;
}

@media only screen and (max-width: 1000px) {
  .sitemap01wrap {
    padding: 0 10px;
  }
}

@media screen and (min-width: 701px) and (max-width: 950px) {
  .sitemap01wrap {
    margin: 0 auto 70px;
  }
}

@media only screen and (max-width: 700px) {
  .sitemap01wrap {
    padding: 0;
    margin: 0 auto 60px;
  }
}

.sitemap01wrap .sitemap01 {
  margin-left: -40px;
  justify-content: flex-start;
}

@media only screen and (max-width: 1000px) {
  .sitemap01wrap .sitemap01 {
    margin-left: -3.8461%;
  }
}

@media only screen and (max-width: 950px) {
  .sitemap01wrap .sitemap01 {
    margin-left: 0;
    justify-content: space-between;
  }
}

.sitemap01wrap .sitemap01 .box {
  margin-left: 40px;
  width: 220px;
  height: 200px;
  border: 1px solid #dddddd;
}

@media only screen and (max-width: 1000px) {
  .sitemap01wrap .sitemap01 .box {
    width: 21.1538%;
    margin-left: 3.8461%;
    height: 100%;
  }
}

@media only screen and (max-width: 700px) {
  .sitemap01wrap .sitemap01 .box:last-child {
    margin-bottom: 0 !important;
  }
}

@media only screen and (max-width: 700px) {
  .sitemap01wrap .sitemap01 .box:nth-last-child(2) {
    margin-bottom: 0 !important;
  }
}

@media screen and (min-width: 701px) and (max-width: 950px) {
  .sitemap01wrap .sitemap01 .box {
    width: 48%;
    margin-left: 0;
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 700px) {
  .sitemap01wrap .sitemap01 .box {
    width: 48%;
    margin-left: 0;
    margin-bottom: 15px;
  }
}

.sitemap01wrap .sitemap01 .box a {
  display: block;
  color: #181818;
  font-size: 16px;
  text-align: center;
}

@media only screen and (max-width: 700px) {
  .sitemap01wrap .sitemap01 .box a {
    font-size: 14px;
  }
}

.sitemap01wrap .sitemap01 .box a:hover {
  opacity: 1 !important;
}

.sitemap01wrap .sitemap01 .box a div {
  padding: 15px;
  line-height: 1;
}

@media only screen and (max-width: 700px) {
  .sitemap01wrap .sitemap01 .box a div {
    padding: 8px;
  }
}

.sitemap01wrap .sitemap01 .box a img {
  opacity: 1 !important;
}

.sitemap02wrap .inner {
  max-width: 740px;
  margin: 0 auto 100px;
}

@media only screen and (max-width: 1000px) {
  .sitemap02wrap .inner {
    padding: 0 10px;
  }
}

@media screen and (min-width: 701px) and (max-width: 950px) {
  .sitemap02wrap .inner {
    max-width: 100%;
    margin: 0 auto 70px;
    padding: 0 40px;
  }
}

@media only screen and (max-width: 700px) {
  .sitemap02wrap .inner {
    max-width: 100%;
    padding: 0 30px;
    margin: 0 auto 60px;
  }
}

.sitemap02wrap .sitemap02 {
  margin-left: -40px;
  justify-content: flex-start;
}

@media only screen and (max-width: 1000px) {
  .sitemap02wrap .sitemap02 {
    margin-left: -5.1282%;
  }
}

@media only screen and (max-width: 950px) {
  .sitemap02wrap .sitemap02 {
    margin-left: 0;
    justify-content: space-between;
  }
}

.sitemap02wrap .sitemap02 .box {
  margin-left: 40px;
  width: 220px;
  height: 200px;
  border: 1px solid #dddddd;
}

@media only screen and (max-width: 1000px) {
  .sitemap02wrap .sitemap02 .box {
    width: 28.2051%;
    margin-left: 5.1282%;
    height: 100%;
  }
}

@media only screen and (max-width: 700px) {
  .sitemap02wrap .sitemap02 .box:last-child {
    margin-bottom: 0 !important;
  }
}

@media only screen and (max-width: 700px) {
  .sitemap02wrap .sitemap02 .box:nth-last-child(2) {
    margin-bottom: 0 !important;
  }
}

@media screen and (min-width: 701px) and (max-width: 950px) {
  .sitemap02wrap .sitemap02 .box {
    width: 48%;
    margin-left: 0;
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 700px) {
  .sitemap02wrap .sitemap02 .box {
    width: 48%;
    margin-left: 0;
    margin-bottom: 15px;
  }
}

.sitemap02wrap .sitemap02 .box a {
  display: block;
  color: #181818;
  font-size: 16px;
  text-align: center;
}

@media only screen and (max-width: 700px) {
  .sitemap02wrap .sitemap02 .box a {
    font-size: 14px;
  }
}

.sitemap02wrap .sitemap02 .box a:hover {
  opacity: 1 !important;
}

.sitemap02wrap .sitemap02 .box a div {
  padding: 15px;
  line-height: 1;
}

@media only screen and (max-width: 700px) {
  .sitemap02wrap .sitemap02 .box a div {
    padding: 8px;
  }
}

.sitemap02wrap .sitemap02 .box a img {
  opacity: 1 !important;
}

.sitemap03 {
  width: 100vw;
  height: 100%;
  margin-left: calc(((100vw - 100%) / 2) * -1);
  margin-right: calc(((100vw - 100%) / 2) * -1);
  padding: 100px 0;
  background: #efefef;
}

@media only screen and (max-width: 700px) {
  .sitemap03 {
    padding: 60px 0;
  }
}

.sitemap03 .inner {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

@media only screen and (max-width: 1000px) {
  .sitemap03 .inner {
    padding: 0 10px;
  }
}

@media screen and (min-width: 701px) and (max-width: 950px) {
  .sitemap03 .inner {
    padding: 0 40px;
  }
}

@media only screen and (max-width: 700px) {
  .sitemap03 .inner {
    display: block;
    padding: 0 30px;
  }
}

.sitemap03 .inner a {
  color: #181818;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

@media only screen and (max-width: 700px) {
  .sitemap03 .inner a {
    line-height: 1;
  }
}

.sitemap03 .inner a:hover {
  opacity: 1 !important;
}

.sitemap03 .inner a:hover:after {
  font-family: "Font Awesome 5 Pro";
  content: "\f061";
  font-size: 11px;
  vertical-align: middle;
  text-align: right;
  display: block;
  padding: 0 10px;
  font-weight: 600;
}

.sitemap03 .inner h2 a:hover:after {
  font-size: 20px;
}

.sitemap03 .inner h3 a:hover:after {
  font-size: 16px;
}

.sitemap03 .inner .flxL {
  width: 70%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
}

@media only screen and (max-width: 700px) {
  .sitemap03 .inner .flxL {
    display: block;
    width: 100%;
  }
}

.sitemap03 .inner .flxR {
  width: 25%;
}

@media only screen and (max-width: 700px) {
  .sitemap03 .inner .flxR {
    width: 100%;
  }
}

.sitemap03 .inner .midashi6 {
  width: 100%;
}

.sitemap03 .inner .sitemenuwrap {
  width: 48%;
}

@media only screen and (max-width: 700px) {
  .sitemap03 .inner .sitemenuwrap {
    width: 100%;
    margin-bottom: 40px;
  }
}

@media only screen and (max-width: 700px) {
  .sitemap03 .inner .midashi6:last-child {
    margin-bottom: 0 !important;
  }
}

.sitemap03 .inner .sitemenu td {
  padding: 0 !important;
  border: 0 !important;
}

@media screen and (min-width: 701px) and (max-width: 950px) {
  .sitemap03 .inner .sitemenu td {
    padding: 5px 0 !important;
  }
}

.sitemap03 .inner .sitemenu h3 a {
  border-bottom: 1px dotted #181818;
  color: #181818;
  line-height: 1.6em;
  margin-bottom: 20px;
  font-weight: 700;
  font-size: 18px;
}

@media only screen and (max-width: 1000px) {
  .sitemap03 .inner .sitemenu h3 a {
    font-size: 18px;
  }
}

@media screen and (min-width: 701px) and (max-width: 950px) {
  .sitemap03 .inner .sitemenu h3 a {
    font-size: 16px;
  }
}

@media only screen and (max-width: 700px) {
  .sitemap03 .inner .sitemenu h3 a {
    font-size: 16px;
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 700px) {
  .sitemap03 .inner .sitemenu .txt {
    padding-top: 20px !important;
  }
}

.works01 {
  width: 100vw;
  height: 100%;
  margin-left: calc(((100vw - 100%) / 2) * -1);
  margin-right: calc(((100vw - 100%) / 2) * -1);
  padding: 100px 0 70px;
  background: #e9546b;
}

@media only screen and (max-width: 700px) {
  .works01 {
    padding: 60px 0 45px;
  }
}

@media only screen and (max-width: 1000px) {
  .works01 .inner {
    padding: 0 10px;
  }
}

@media screen and (min-width: 701px) and (max-width: 950px) {
  .works01 .inner {
    padding: 0 40px;
  }
}

@media only screen and (max-width: 700px) {
  .works01 .inner {
    padding: 0 30px;
  }
}

.works01 .inner .fancybox2 {
  margin-left: -3.4749%;
  justify-content: flex-start;
}

@media only screen and (max-width: 700px) {
  .works01 .inner .fancybox2 {
    margin-left: -3.3816%;
    justify-content: flex-start;
  }
}

.works01 .inner .fancybox2 img {
  width: 100% !important;
}

.works01 .inner .fancybox2 .box {
  width: 21.5251%;
  margin-left: 3.4749%;
  margin-bottom: 30px;
}

@media only screen and (max-width: 700px) {
  .works01 .inner .fancybox2 .box {
    margin-left: 3.3816%;
    width: 29.9516%;
    margin-bottom: 15px;
  }
}

.works01 .inner .fancybox2 .box h3, .works01 .inner .fancybox2 .box div {
  display: none;
}

.company01 {
  width: 100vw;
  height: 100%;
  margin-left: calc(((100vw - 100%) / 2) * -1);
  margin-right: calc(((100vw - 100%) / 2) * -1);
  background: #efefef;
  margin-bottom: 100px;
  padding: 80px 0 100px;
}

@media only screen and (max-width: 700px) {
  .company01 {
    margin-bottom: 60px;
    padding: 50px 0 60px;
  }
}

@media only screen and (max-width: 1000px) {
  .company01 .inner {
    padding: 0 10px;
  }
}

@media screen and (min-width: 701px) and (max-width: 950px) {
  .company01 .inner {
    padding: 0 40px;
  }
}

@media only screen and (max-width: 700px) {
  .company01 .inner {
    padding: 0 30px;
  }
}

.company02 .inner {
  border: 2px solid #181818;
  padding: 50px;
  max-width: 600px;
  position: relative;
}

@media only screen and (max-width: 950px) {
  .company02 .inner {
    max-width: 100%;
    padding: 40px;
  }
}

@media only screen and (max-width: 700px) {
  .company02 .inner {
    padding: 30px;
  }
}

.company02 .inner .midashi5 {
  position: absolute;
  top: -13px;
  left: 50px;
  background: #efefef;
  padding: 0 20px;
}

@media screen and (min-width: 701px) and (max-width: 950px) {
  .company02 .inner .midashi5 {
    top: -20px;
  }
}

@media only screen and (max-width: 700px) {
  .company02 .inner .midashi5 {
    top: -16px;
    left: 20px;
  }
}

.company02 .inner .midashi5 h3 {
  color: #181818;
}

.company03 {
  max-width: 600px;
}

@media only screen and (max-width: 950px) {
  .company03 {
    max-width: 100%;
  }
}

.company03 dl {
  color: #181818;
}

@media only screen and (max-width: 700px) {
  .company03 dl {
    border-bottom: 2px solid #dddddd !important;
    display: block !important;
    padding: 20px 0;
  }
}

.company03 dl:first-child dt {
  padding: 0 0 40px 10px !important;
}

@media only screen and (max-width: 700px) {
  .company03 dl:first-child dt {
    padding: 0 0 5px !important;
  }
}

.company03 dl:first-child dd {
  padding: 0 0 40px 40px !important;
}

@media only screen and (max-width: 700px) {
  .company03 dl:first-child dd {
    padding: 0 !important;
  }
}

.company03 dl dt {
  font-weight: 700;
  border: 0 !important;
  background-color: inherit !important;
  justify-content: flex-start !important;
  border-bottom: 2px solid #181818 !important;
  padding: 40px 0 40px 10px !important;
}

@media only screen and (max-width: 700px) {
  .company03 dl dt {
    width: 100% !important;
    border-bottom: 0 !important;
    padding: 0 0 5px !important;
  }
}

.company03 dl dd {
  font-weight: 500;
  border: 0 !important;
  background-color: inherit !important;
  border-bottom: 2px solid #ddd !important;
  padding: 40px 0 40px 40px !important;
}

@media only screen and (max-width: 700px) {
  .company03 dl dd {
    width: 100% !important;
    padding: 0 !important;
    border-bottom: 0 !important;
  }
}

.company04 ul li {
  letter-spacing: 0;
}

.policy01 {
  width: 100vw;
  height: 100%;
  margin-left: calc(((100vw - 100%) / 2) * -1);
  margin-right: calc(((100vw - 100%) / 2) * -1);
  padding: 100px 0;
  background: #efefef;
  margin-bottom: 100px;
}

@media only screen and (max-width: 700px) {
  .policy01 {
    margin-bottom: 60px;
    padding: 50px 0 60px;
  }
}

@media only screen and (max-width: 950px) {
  .policy01 .inner3 {
    max-width: 100%;
    padding: 0 40px;
  }
}

@media only screen and (max-width: 700px) {
  .policy01 .inner3 {
    padding: 0 30px;
  }
}

.policy01 a {
  color: #66ad01;
  margin-right: 5px;
}

.annot {
  display: none;
}

.contact01 {
  border: 2px solid #8fcb0e;
}

.contact01 .message {
  background: #fff;
  border: 0 !important;
  padding: 60px 0 !important;
  margin: 0 auto !important;
  border-radius: 0 !important;
  max-width: 700px !important;
}

@media only screen and (max-width: 950px) {
  .contact01 .message {
    padding: 40px 20px !important;
  }
}

.contact01 .contact02 {
  background: #f8fdf3;
  padding: 50px;
  display: block !important;
}

@media only screen and (max-width: 700px) {
  .contact01 .contact02 {
    padding: 40px 30px;
  }
}

.contact01 .contact02 .contact02inner {
  max-width: 600px;
  margin: 0 auto;
  display: flex;
  justify-content: space-around !important;
  align-items: center !important;
}

.contact01 .contact02 .active {
  color: #8fcb0e !important;
}

.contact01 .contact02 .active:before {
  border: 5px solid #8fcb0e !important;
}

.contact01 .contact02 .active02 {
  border-bottom: 6px solid #8fcb0e !important;
}

.contact01 .contact02 .check {
  color: #8fcb0e !important;
}

.contact01 .contact02 .check:before {
  font-family: "Font Awesome 5 Pro" !important;
  content: "\f00c" !important;
  background: #8fcb0e !important;
  border: 5px solid #8fcb0e !important;
  color: #fff !important;
}

.contact01 .contact02 .box {
  color: #aaa;
}

.contact01 .contact02 .box:before {
  border-radius: 50%;
  padding-top: 3px;
  height: 53px;
  width: 56px;
  margin-top: 2px;
  background: #fff;
  border: 5px solid #ccc;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-family: 'Viga', sans-serif;
  letter-spacing: 0.05em;
  line-height: 1;
  font-weight: 500;
  font-size: 32px;
}

@media only screen and (max-width: 1000px) {
  .contact01 .contact02 .box:before {
    font-size: 32px;
  }
}

@media screen and (min-width: 701px) and (max-width: 950px) {
  .contact01 .contact02 .box:before {
    font-size: 1.5em !important;
  }
}

@media only screen and (max-width: 700px) {
  .contact01 .contact02 .box:before {
    height: 43px;
    width: 46px;
    font-size: 25px;
  }
}

.contact01 .contact02 .box div {
  line-height: 1;
  margin-top: 10px;
  text-align: center;
  font-size: 14px;
  font-weight: 700;
}

.contact01 .contact02 .box:nth-child(1):before {
  content: "1";
}

.contact01 .contact02 .box:nth-child(2) {
  width: 20%;
  height: 6px;
  display: block;
  border-bottom: 6px solid #ccc;
  margin-top: -20px;
}

@media screen and (min-width: 701px) and (max-width: 950px) {
  .contact01 .contact02 .box:nth-child(2) {
    margin-top: -20px;
  }
}

@media only screen and (max-width: 700px) {
  .contact01 .contact02 .box:nth-child(2) {
    width: 10%;
    margin-top: -20px;
  }
}

.contact01 .contact02 .box:nth-child(3):before {
  content: "2";
}

.contact01 .contact02 .box:nth-child(4) {
  width: 20%;
  height: 6px;
  display: block;
  border-bottom: 6px solid #ccc;
  margin-top: -20px;
}

@media screen and (min-width: 701px) and (max-width: 950px) {
  .contact01 .contact02 .box:nth-child(4) {
    margin-top: -20px;
  }
}

@media only screen and (max-width: 700px) {
  .contact01 .contact02 .box:nth-child(4) {
    width: 10%;
    margin-top: -20px;
  }
}

.contact01 .contact02 .box:nth-child(5):before {
  content: "3";
}

.contact01 .contact03 {
  max-width: 700px;
  margin: 0 auto;
  padding: 60px 0 0 0;
}

@media only screen and (max-width: 950px) {
  .contact01 .contact03 {
    padding: 40px 20px 0;
  }
}

.contact01 .contact03 div {
  letter-spacing: 0;
}

.contact01 .contact03 .dltxt dd {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.contact01 .contact03 .dltxt dd div {
  margin-top: 0 !important;
  margin-right: 6px;
}

.contact01 .contact03 .dltxt2 dd {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.contact01 .contact03 .dltxt2 dd div {
  margin-top: 0 !important;
  margin-left: 6px;
}

.contact01 .contact03 dl {
  margin-bottom: 40px;
  font-size: 15px;
}

.contact01 .contact03 dl:last-child {
  margin-bottom: 0;
}

.contact01 .contact03 dl dt {
  line-height: 1;
  font-size: 16px;
  max-width: 200px;
  min-width: 200px;
  width: 30% !important;
  font-weight: 700;
  border: 0 !important;
  background-color: inherit !important;
  padding: 0 !important;
  justify-content: flex-start !important;
}

@media only screen and (max-width: 700px) {
  .contact01 .contact03 dl dt {
    width: 100% !important;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 15px;
  }
}

.contact01 .contact03 dl dt label {
  margin-top: 10px;
  background: #e9546b;
  border-radius: 5px;
  color: #fff;
  font-size: 13px;
  font-weight: 700;
  padding: 6px;
  line-height: 1;
}

@media only screen and (max-width: 700px) {
  .contact01 .contact03 dl dt label {
    margin-top: -2px;
    margin-left: 10px;
    line-height: 1;
    padding: 3px;
    font-size: 11px;
  }
}

.contact01 .contact03 dl .checkbox {
  line-height: 1.8em;
}

.contact01 .contact03 dl dd {
  text-align: left;
  width: 80% !important;
  border: 0 !important;
  background-color: inherit !important;
  padding: 0 !important;
}

@media only screen and (max-width: 700px) {
  .contact01 .contact03 dl dd {
    width: 100% !important;
  }
}

.contact01 .contact03 dl dd span {
  line-height: 1;
  margin-right: 0;
}

@media only screen and (max-width: 700px) {
  .contact01 .contact03 dl dd textarea {
    line-height: 1.8em;
  }
}

.contact01 .contact03 dl dd.checkbox {
  margin-top: -5px;
}

.contact01 .contact03 dl dd.checkbox label {
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  margin-right: 0;
  margin-bottom: 15px;
}

.contact01 .contact03 dl dd.checkbox label .outer {
  margin-top: -2px;
  margin-right: 10px;
  font-size: 16px;
}

.contact01 .contact03 dl dd.checkbox label .outer:before {
  font-size: 18px;
  font-family: "Font Awesome 5 Pro";
  content: "\f0c8";
  vertical-align: middle;
  text-align: right;
  color: #aaaaaa;
}

.contact01 .contact03 dl dd.checkbox label input {
  display: none;
}

.contact01 .contact03 dl dd.checkbox label input:checked + .outer:before {
  content: "\f14a";
  color: #8fcb0e;
}

.contact01 .contact03 dl .email div {
  margin-top: 0 !important;
}

.contact01 .contact03 dl .email div:last-child {
  margin-top: 10px !important;
}

.contact01 .contact03 dl .name div, .contact01 .contact03 dl .kana div, .contact01 .contact03 dl .email div, .contact01 .contact03 dl .tel div, .contact01 .contact03 dl .textarea div, .contact01 .contact03 dl .text div {
  margin-top: 10px;
}

.contact01 .contact03 dl .name input, .contact01 .contact03 dl .name textarea, .contact01 .contact03 dl .name select, .contact01 .contact03 dl .kana input, .contact01 .contact03 dl .kana textarea, .contact01 .contact03 dl .kana select, .contact01 .contact03 dl .email input, .contact01 .contact03 dl .email textarea, .contact01 .contact03 dl .email select, .contact01 .contact03 dl .tel input, .contact01 .contact03 dl .tel textarea, .contact01 .contact03 dl .tel select, .contact01 .contact03 dl .textarea input, .contact01 .contact03 dl .textarea textarea, .contact01 .contact03 dl .textarea select, .contact01 .contact03 dl .text input, .contact01 .contact03 dl .text textarea, .contact01 .contact03 dl .text select, .contact01 .contact03 dl .addr input, .contact01 .contact03 dl .addr textarea, .contact01 .contact03 dl .addr select, .contact01 .contact03 dl .select input, .contact01 .contact03 dl .select textarea, .contact01 .contact03 dl .select select {
  background: #efefef;
  border: 0 !important;
  height: 50px;
}

.contact01 .contact03 dl .name input:focus, .contact01 .contact03 dl .name textarea:focus, .contact01 .contact03 dl .name select:focus, .contact01 .contact03 dl .kana input:focus, .contact01 .contact03 dl .kana textarea:focus, .contact01 .contact03 dl .kana select:focus, .contact01 .contact03 dl .email input:focus, .contact01 .contact03 dl .email textarea:focus, .contact01 .contact03 dl .email select:focus, .contact01 .contact03 dl .tel input:focus, .contact01 .contact03 dl .tel textarea:focus, .contact01 .contact03 dl .tel select:focus, .contact01 .contact03 dl .textarea input:focus, .contact01 .contact03 dl .textarea textarea:focus, .contact01 .contact03 dl .textarea select:focus, .contact01 .contact03 dl .text input:focus, .contact01 .contact03 dl .text textarea:focus, .contact01 .contact03 dl .text select:focus, .contact01 .contact03 dl .addr input:focus, .contact01 .contact03 dl .addr textarea:focus, .contact01 .contact03 dl .addr select:focus, .contact01 .contact03 dl .select input:focus, .contact01 .contact03 dl .select textarea:focus, .contact01 .contact03 dl .select select:focus {
  border-radius: 4px;
  outline: inherit;
  border: 1px solid #8fcb0e !important;
}

.contact01 .contact03 dl .name div, .contact01 .contact03 dl .kana div, .contact01 .contact03 dl .email div, .contact01 .contact03 dl .tel div, .contact01 .contact03 dl .textarea div, .contact01 .contact03 dl .text div, .contact01 .contact03 dl .addr div, .contact01 .contact03 dl .select div {
  line-height: 1.4em;
  color: #666;
  font-size: 14px;
  letter-spacing: -0.05em !important;
}

.contact01 .contact03 dl input, .contact01 .contact03 dl select {
  background: #efefef;
}

.contact01 .contact03 dl .select span, .contact01 .contact03 dl .addr span {
  height: 50px;
  display: flex;
  align-items: center;
  margin-right: 8px;
}

.contact01 .contact03 dl .select select, .contact01 .contact03 dl .addr select {
  height: 50px;
  padding: 15px 15px 15px 19px !important;
  border: 0 !important;
}

.contact01 .contact03 dl .select select:focus, .contact01 .contact03 dl .addr select:focus {
  outline: 0 !important;
}

.contact01 .contact03 .file {
  border-top: 0 !important;
  flex-direction: row !important;
  border-top: 0 !important;
}

@media only screen and (max-width: 700px) {
  .contact01 .contact03 .file {
    flex-direction: column !important;
  }
}

.contact01 .contact03 .file dt {
  font-size: 16px;
  display: flex !important;
  align-items: flex-start !important;
  justify-content: flex-start !important;
  flex-direction: column !important;
}

@media only screen and (max-width: 700px) {
  .contact01 .contact03 .file dt {
    align-items: center !important;
    flex-direction: row !important;
  }
}

.contact01 .contact03 .file dd label {
  position: static !important;
  margin-left: auto !important;
  margin-bottom: 10px;
}

.contact01 .contact03 .file dd div {
  line-height: 1.4em;
  color: #666;
  font-size: 14px;
  letter-spacing: -0.05em;
}

section div.form_wrap dl dd div input[type="text"], section div.form_wrap dl dd div input[type="password"], section form div.form_wrap dl dd div select {
  margin-bottom: 10px !important;
}

.contact04 {
  max-width: 600px;
  margin: 0 auto;
  padding: 40px 0 60px;
}

@media only screen and (max-width: 700px) {
  .contact04 {
    padding: 40px 20px;
  }
}

.contact04 div {
  letter-spacing: 0;
}

.contact04.confirme {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 0 50px;
}

@media only screen and (max-width: 700px) {
  .contact04.confirme {
    flex-direction: column-reverse;
    padding: 37px 20px 40px;
  }
}

@keyframes explode2 {
  0% {
    width: 0px;
    height: 0px;
    margin-left: 0px;
    margin-top: 0px;
    background-color: rgba(149, 149, 149, 0.2);
  }
  100% {
    width: 900px;
    height: 900px;
    margin-left: -450px;
    margin-top: -450px;
    background-color: rgba(126, 137, 151, 0.8);
  }
}

@keyframes desplode2 {
  0% {
    width: 900px;
    height: 900px;
    margin-left: -450px;
    margin-top: -450px;
    background-color: rgba(126, 137, 151, 0.8);
  }
  100% {
    width: 0px;
    height: 0px;
    margin-left: 0px;
    margin-top: 0px;
    background-color: rgba(187, 187, 187, 0.6);
  }
}

.contact04.confirme .btn {
  max-width: 400px !important;
  width: 68% !important;
}

@media only screen and (max-width: 700px) {
  .contact04.confirme .btn {
    width: 100% !important;
    max-width: 315px !important;
  }
}

.contact04.confirme .btn:nth-child(1) {
  width: 30% !important;
  max-width: 180px !important;
}

@media only screen and (max-width: 700px) {
  .contact04.confirme .btn:nth-child(1) {
    margin-top: 15px !important;
    width: 100% !important;
    max-width: 315px !important;
  }
}

.contact04.confirme .btn:nth-child(1) .explode-circle {
  animation: explode 0.5s forwards !important;
}

.contact04.confirme .btn:nth-child(1) .desplode-circle {
  animation: desplode2 0.5s forwards !important;
}

.contact04.confirme .btn:nth-child(1) button {
  background: #ddd !important;
}

.contact04.confirme .btn:nth-child(1) button:after {
  content: "\f060";
  left: 20px;
  justify-content: flex-start;
}

.contact04 a {
  color: #66ad01;
}

.contact04 .spleft {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  line-height: 1;
}

@media screen and (min-width: 701px) and (max-width: 950px) {
  .contact04 .spleft {
    align-items: center;
    justify-content: center;
  }
}

@media only screen and (max-width: 700px) {
  .contact04 .spleft {
    margin-bottom: 20px;
    display: block;
    line-height: 1.8em;
  }
}

.contact04 .spleft .tagwrap {
  line-height: 1;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  color: #66ad01;
}

@media only screen and (max-width: 700px) {
  .contact04 .spleft .tagwrap {
    display: inline-block;
  }
}

.contact04 .spleft .tagwrap:after {
  font-family: "Font Awesome 5 Pro";
  content: "\f2d2";
  font-size: 16px;
  vertical-align: middle;
  text-align: right;
  display: block;
  padding: 0 5px;
  font-weight: 400;
}

@media only screen and (max-width: 700px) {
  .contact04 .spleft .tagwrap:after {
    font-size: 14px;
    float: right;
  }
}

.contact04 .spleft .tagwrap a {
  color: #66ad01;
  line-height: 1;
  position: relative;
}

.contact04 .spleft .tagwrap a:after {
  position: absolute;
  bottom: -4px;
  left: 0;
  content: '';
  width: 100%;
  height: 1px;
  background: #66ad01;
  transform: scale(0, 1);
  transform-origin: center top;
  transition: transform .3s;
}

.contact04 .spleft .tagwrap a:hover {
  color: #66ad01;
  opacity: 1 !important;
}

.contact04 .spleft .tagwrap a:hover:after {
  transform: scale(1, 1);
}

.contact04 .submit {
  max-width: 400px !important;
  width: 100% !important;
  margin: 40px auto 0 !important;
}

@media only screen and (max-width: 700px) {
  .contact04 .submit {
    max-width: 315px !important;
    margin: 0 auto !important;
  }
}

.contact04 .submit button:hover {
  opacity: 1 !important;
}

.contact04 .submit .bt_confirm, .contact04 .submit .bt_back, .contact04 .submit .bt_send {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  width: 100% !important;
  height: 70px !important;
  background: #8fcb0e !important;
  color: #181818;
  text-indent: 0 !important;
  border: 0 !important;
  min-width: 100% !important;
  z-index: 1;
}

.contact04 .submit .bt_confirm span, .contact04 .submit .bt_back span, .contact04 .submit .bt_send span {
  z-index: 1;
  position: relative;
}

@-webkit-keyframes cardEnter {
  0%,
  20%,
  40%,
  60%,
  80%,
  100% {
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
  }
  20% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
  }
  40% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
  }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(1.03, 1.03, 1.03);
  }
  80% {
    -webkit-transform: scale3d(0.97, 0.97, 0.97);
  }
  100% {
    opacity: 1;
    -webkit-transform: scale3d(1, 1, 1);
  }
}

@keyframes cardEnter {
  0%,
  20%,
  40%,
  60%,
  80%,
  100% {
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
  20% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1);
  }
  40% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
    transform: scale3d(0.9, 0.9, 0.9);
  }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(1.03, 1.03, 1.03);
    transform: scale3d(1.03, 1.03, 1.03);
  }
  80% {
    -webkit-transform: scale3d(0.97, 0.97, 0.97);
    transform: scale3d(0.97, 0.97, 0.97);
  }
  100% {
    opacity: 1;
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

dd.radio {
  margin-top: -4px;
}

dd.radio label {
  display: flex !important;
  align-items: center;
  justify-content: flex-start;
  margin-right: 0 !important;
  line-height: 32px;
  cursor: pointer;
}

@media only screen and (max-width: 950px) {
  dd.radio label {
    line-height: 1.4em;
    align-items: flex-start;
    margin-bottom: 15px;
  }
}

dd.radio label:hover .btninner {
  -webkit-transform: scale(0.5);
  transform: scale(0.5);
  opacity: 0.5;
}

dd.radio label input {
  margin: 0 !important;
  height: 1px;
  width: 1px;
  opacity: 0;
}

dd.radio label input:checked + .outer {
  border: 2px solid #8fcb0e;
}

dd.radio label input:checked + .outer .btninner {
  -webkit-transform: scale(1);
  transform: scale(1);
  opacity: 1;
  background-color: #8fcb0e;
}

dd.radio label input:focus + .outer .btninner {
  -webkit-transform: scale(1);
  transform: scale(1);
  opacity: 1;
  background-color: #8fcb0e;
}

dd.radio label .outer {
  height: 20px;
  width: 20px;
  min-width: 20px;
  min-height: 20px;
  display: block;
  margin: 0px 0 5px 0;
  border: 2px solid #aaaaaa;
  border-radius: 50%;
  background-color: #fff;
  margin-right: 10px !important;
}

@media screen and (min-width: 701px) and (max-width: 950px) {
  dd.radio label .outer {
    margin: 0;
  }
}

@media only screen and (max-width: 700px) {
  dd.radio label .outer {
    margin: 1px 0 0 0;
  }
}

dd.radio label .btninner {
  transition: all 0.25s ease-in-out;
  height: 10px;
  width: 10px;
  -webkit-transform: scale(0);
  transform: scale(0);
  display: block;
  margin: 3px;
  border-radius: 50%;
  background-color: #8fcb0e;
  opacity: 0;
}

.error {
  display: none;
}

.access01 .inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media only screen and (max-width: 1200px) {
  .access01 .inner {
    padding: 0 10px;
  }
}

@media only screen and (max-width: 950px) {
  .access01 .inner {
    display: block;
  }
}

.access01 .inner .flxL {
  width: 40%;
}

@media only screen and (max-width: 950px) {
  .access01 .inner .flxL {
    width: 100%;
    margin: 0 0 50px;
  }
}

.access01 .inner .flxL .add {
  color: #181818;
}

.access01 .inner .flxL .add dl {
  margin-bottom: 30px;
}

.access01 .inner .flxL .add dl dt {
  justify-content: flex-start;
  border: 0;
  background-color: inherit;
  padding: 0;
  margin-right: 10px;
  font-weight: 700;
}

.access01 .inner .flxL .add dl dd {
  border: 0;
  background-color: inherit;
  padding: 0;
  font-weight: 400;
}

.access01 .inner .flxL .add dl h2 {
  font-family: "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
  font-weight: 700;
  color: #181818;
  line-height: 1;
  font-size: 20px;
}

@media only screen and (max-width: 1000px) {
  .access01 .inner .flxL .add dl h2 {
    font-size: 18px;
  }
}

.access01 .inner .flxL .tel2 {
  color: #181818;
  font-weight: 800;
  font-size: 30px;
}

@media only screen and (max-width: 1000px) {
  .access01 .inner .flxL .tel2 {
    font-size: 25px;
  }
}

.access01 .inner .flxL .tel2 span {
  font-size: 0.7em;
}

.access01 .inner .flxL .tel2 a {
  color: #181818;
}

.access01 .inner .flxL .btn {
  max-width: 100%;
}

.access01 .inner .flxL .btn a {
  background: #606060;
}

.access01 .inner .flxL .btn a:hover {
  background: #8e8e8e;
}

.access01 .inner .flxR {
  width: 57%;
}

@media only screen and (max-width: 950px) {
  .access01 .inner .flxR {
    width: 100%;
  }
}

.access01 .inner .flxR img {
  width: 100% !important;
}

.ext_01 {
  justify-content: space-between !important;
  flex-wrap: wrap;
}

.ext_01 .box {
  margin-bottom: 20px;
  width: 48%;
  text-align: center;
}

.ext_01 .box img {
  width: 100% !important;
}

.ext_01 .box > div {
  margin-top: 15px;
}

.ext_02 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media only screen and (max-width: 950px) {
  .ext_02 {
    flex-wrap: wrap;
  }
}

.ext_02:nth-child(even) {
  flex-direction: row-reverse;
}

.ext_02 .flxL {
  width: 55%;
}

@media only screen and (max-width: 1000px) {
  .ext_02 .flxL {
    width: 50%;
  }
}

@media only screen and (max-width: 950px) {
  .ext_02 .flxL {
    width: 100%;
    margin-bottom: 30px;
  }
}

.ext_02 .flxR {
  width: 40%;
}

@media only screen and (max-width: 1000px) {
  .ext_02 .flxR {
    width: 45%;
  }
}

@media only screen and (max-width: 950px) {
  .ext_02 .flxR {
    width: 100%;
  }
}

.ext_02 .flxR img {
  width: 100% !important;
}

.ext_03 {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

@media only screen and (max-width: 950px) {
  .ext_03 {
    flex-wrap: wrap;
  }
}

.ext_03:nth-child(even) {
  flex-direction: row-reverse;
}

.ext_03 .flxL {
  width: 55%;
}

@media only screen and (max-width: 1000px) {
  .ext_03 .flxL {
    width: 50%;
  }
}

@media only screen and (max-width: 950px) {
  .ext_03 .flxL {
    width: 100%;
    margin-bottom: 30px;
  }
}

.ext_03 .flxR {
  width: 40%;
}

@media only screen and (max-width: 1000px) {
  .ext_03 .flxR {
    width: 45%;
  }
}

@media only screen and (max-width: 950px) {
  .ext_03 .flxR {
    width: 100%;
  }
}

.ext_03 .flxR img {
  width: 100% !important;
}

@media only screen and (max-width: 700px) {
  .comp2 dl {
    text-align: center;
    display: block !important;
  }
}

.comp2 dl dt {
  padding: 15px !important;
}

@media only screen and (max-width: 700px) {
  .comp2 dl dt {
    display: block !important;
    text-align: center;
    width: 100% !important;
  }
}

.comp2 dl dd {
  padding: 15px !important;
}

@media only screen and (max-width: 700px) {
  .comp2 dl dd {
    width: 100% !important;
  }
}

.comp2 dl a {
  color: #000;
}

.waku {
  padding: 40px;
  background: #fff;
  border: double 7px #181818;
}

@media only screen and (max-width: 700px) {
  .waku {
    padding: 20px;
  }
}

.waku h3 {
  padding-bottom: 10px;
  border-bottom: 1px solid #4e362a;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  color: #222;
  font-weight: 700;
  font-size: 18px;
}

@media only screen and (max-width: 1000px) {
  .waku h3 {
    font-size: 18px;
  }
}

.media3 .sns_list {
  border: 0;
  flex-wrap: wrap;
  flex-direction: row;
  margin-left: -2.43902%;
}

@media screen and (min-width: 701px) and (max-width: 950px) {
  .media3 .sns_list {
    margin-left: -2.43902%;
  }
}

@media only screen and (max-width: 700px) {
  .media3 .sns_list {
    justify-content: space-between;
    margin-left: 0;
  }
}

.media3 .sns_list > div {
  border-bottom: 0;
  box-shadow: 0 0px 8px rgba(51, 51, 51, 0.2);
  width: 30.89431%;
  height: 30.89431%;
  margin-left: 2.43902%;
  margin-bottom: 2.43902%;
  display: block;
}

@media screen and (min-width: 701px) and (max-width: 950px) {
  .media3 .sns_list > div {
    width: 30.89431%;
    margin-left: 2.43902%;
    display: block;
  }
}

@media only screen and (max-width: 700px) {
  .media3 .sns_list > div {
    width: 48%;
    margin-left: 0;
  }
}

.media3 .sns_list > div:nth-child(n+7) {
  margin-bottom: 0;
}

.media3 .sns_list > div .sns_text {
  width: 100%;
  font-size: 16px;
}

.media3 .sns_list > div .sns_text .sns_date {
  color: #181818;
  font-weight: 700;
  font-size: 18px;
  border-bottom: 1px solid #181818;
  padding-bottom: 5px;
  margin-bottom: 10px;
}

.media3 .sns_list > div .sns_photo {
  width: 100%;
  padding: 0;
}

.media3 .sns_list > div .sns_photo a {
  display: block;
  position: relative;
}

.media3 .sns_list > div .sns_photo a:before {
  content: "";
  width: 100%;
  padding-top: 100%;
  display: block;
}

.media3 .sns_list > div .sns_photo img {
  height: 100% !important;
  width: 100% !important;
  object-fit: cover;
  font-family: 'object-fit: cover;';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.timelist {
  margin-left: calc(((100vw - 100%) / 2) * -1);
  margin-right: calc(((100vw - 100%) / 2) * -1);
  margin-bottom: 100px;
}

@media screen and (min-width: 701px) and (max-width: 950px) {
  .timelist {
    margin: 0 auto 100px;
  }
}

@media only screen and (max-width: 700px) {
  .timelist {
    margin: 0 auto 60px;
  }
}

.cbp_tmtimeline {
  max-width: 1000px;
  margin: 30px auto 0;
  padding: 0;
  list-style: none;
  position: relative;
}

@media only screen and (max-width: 1000px) {
  .cbp_tmtimeline {
    padding: 0 10px;
  }
}

@media only screen and (max-width: 950px) {
  .cbp_tmtimeline {
    padding: 0;
  }
}

.cbp_tmtimeline:before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  width: 10px;
  background: #42b536;
  left: 18%;
  margin-left: -10px;
}

@media only screen and (max-width: 950px) {
  .cbp_tmtimeline:before {
    display: none;
  }
}

.cbp_tmtimeline > li {
  position: relative;
}

.cbp_tmtimeline > li .cbp_tmtime {
  display: block;
  width: 23%;
  padding-right: 100px;
  position: absolute;
}

@media only screen and (max-width: 950px) {
  .cbp_tmtimeline > li .cbp_tmtime {
    width: 100%;
    position: relative;
    padding: 0 0 20px 0;
  }
}

.cbp_tmtimeline > li .cbp_tmtime span {
  display: block;
  text-align: right;
}

@media only screen and (max-width: 950px) {
  .cbp_tmtimeline > li .cbp_tmtime span {
    text-align: left !important;
  }
}

.cbp_tmtimeline > li .cbp_tmtime span:first-child {
  display: block;
  line-height: 1;
  margin-bottom: 15px;
  font-size: 14px;
  color: #181818;
}

@media only screen and (max-width: 950px) {
  .cbp_tmtimeline > li .cbp_tmtime span:first-child {
    margin-bottom: 5px;
  }
}

@media only screen and (max-width: 700px) {
  .cbp_tmtimeline > li .cbp_tmtime span:first-child {
    font-size: 13px;
  }
}

.cbp_tmtimeline > li .cbp_tmtime span:last-child {
  text-align: right;
  font-family: 'Viga', sans-serif;
  letter-spacing: 0.05em;
  color: #42b536;
  line-height: 1;
  font-weight: 500;
  position: relative;
  display: block;
  font-size: 40px;
}

@media only screen and (max-width: 1000px) {
  .cbp_tmtimeline > li .cbp_tmtime span:last-child {
    font-size: 35px;
  }
}

@media screen and (min-width: 701px) and (max-width: 950px) {
  .cbp_tmtimeline > li .cbp_tmtime span:last-child {
    font-size: 1.5em;
  }
}

@media only screen and (max-width: 700px) {
  .cbp_tmtimeline > li .cbp_tmtime span:last-child {
    font-size: 24px;
  }
}

.cbp_tmtimeline > li:nth-child(odd) .cbp_tmtime span:last-child {
  color: #42b536;
}

.cbp_tmtimeline > li .cbp_tmlabel {
  margin: 0 0 40px 23%;
  background: #ddf9da;
  color: #181818;
  padding: 40px;
  font-size: 16px;
  line-height: 1.8em;
  position: relative;
  border-radius: 5px;
  letter-spacing: 0;
}

.cbp_tmtimeline > li .cbp_tmlabel.gleen {
  background: #ddf9da !important;
}

.cbp_tmtimeline > li .cbp_tmlabel.gleen:after {
  border-right-color: #ddf9da !important;
}

.cbp_tmtimeline > li .cbp_tmlabel.glay {
  background: #efefef !important;
}

.cbp_tmtimeline > li .cbp_tmlabel.glay:after {
  border-right-color: #efefef !important;
}

@media only screen and (max-width: 950px) {
  .cbp_tmtimeline > li .cbp_tmlabel.glay:after {
    border-right-color: transparent !important;
    border-bottom-color: #efefef !important;
  }
}

@media only screen and (max-width: 950px) {
  .cbp_tmtimeline > li:nth-child(2) .cbp_tmlabel:after, .cbp_tmtimeline > li:nth-child(5) .cbp_tmlabel:after, .cbp_tmtimeline > li:nth-child(6) .cbp_tmlabel:after {
    border-right-color: transparent !important;
  }
}

.cbp_tmtimeline > li:nth-child(2) .cbp_tmlabel, .cbp_tmtimeline > li:nth-child(5) .cbp_tmlabel, .cbp_tmtimeline > li:nth-child(6) .cbp_tmlabel {
  background: #efefef;
}

.cbp_tmtimeline > li:nth-child(2) .cbp_tmlabel:after, .cbp_tmtimeline > li:nth-child(5) .cbp_tmlabel:after, .cbp_tmtimeline > li:nth-child(6) .cbp_tmlabel:after {
  border-right-color: #efefef;
}

.cbp_tmtimeline > li:nth-child(2) .cbp_tmicon:before, .cbp_tmtimeline > li:nth-child(5) .cbp_tmicon:before, .cbp_tmtimeline > li:nth-child(6) .cbp_tmicon:before {
  content: "\f1ad";
}

@media screen and (min-width: 701px) and (max-width: 950px) {
  .cbp_tmtimeline > li .cbp_tmlabel {
    margin: 0 0 50px 0;
    padding: 30px;
  }
}

@media only screen and (max-width: 700px) {
  .cbp_tmtimeline > li .cbp_tmlabel {
    padding: 30px 20px;
    margin: 0 0 40px 0;
  }
}

.cbp_tmtimeline > li .cbp_tmlabel h3 {
  line-height: 1;
  margin-bottom: 30px;
  font-weight: 700;
  margin-top: 0px;
  padding: 0 0 10px 0;
  border-bottom: 2px solid #181818;
  color: #181818;
  font-size: 26px;
}

@media only screen and (max-width: 1000px) {
  .cbp_tmtimeline > li .cbp_tmlabel h3 {
    font-size: 24px;
  }
}

@media screen and (min-width: 701px) and (max-width: 950px) {
  .cbp_tmtimeline > li .cbp_tmlabel h3 {
    font-size: 20px;
  }
}

@media only screen and (max-width: 700px) {
  .cbp_tmtimeline > li .cbp_tmlabel h3 {
    font-size: 18px;
  }
}

@media only screen and (max-width: 950px) {
  .cbp_tmtimeline > li .cbp_tmlabel h3 {
    margin-bottom: 20px;
    padding: 0 0 5px 0;
  }
}

.cbp_tmtimeline > li .cbp_tmlabel h4 {
  margin-bottom: 5px;
  font-weight: 700;
  margin-top: 15px;
  color: #181818;
  font-size: 18px;
}

@media only screen and (max-width: 1000px) {
  .cbp_tmtimeline > li .cbp_tmlabel h4 {
    font-size: 18px;
  }
}

@media screen and (min-width: 701px) and (max-width: 950px) {
  .cbp_tmtimeline > li .cbp_tmlabel h4 {
    font-size: 16px;
  }
}

@media only screen and (max-width: 700px) {
  .cbp_tmtimeline > li .cbp_tmlabel h4 {
    font-size: 16px;
  }
}

.cbp_tmtimeline > li .cbp_tmlabel:after {
  right: 100%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-right-color: #ddf9da;
  border-width: 10px;
  top: 10px;
}

@media only screen and (max-width: 950px) {
  .cbp_tmtimeline > li .cbp_tmlabel:after {
    right: auto;
    left: 20px;
    border-right-color: transparent;
    border-bottom-color: #ddf9da;
    top: -20px;
  }
}

.cbp_tmtimeline > li .cbp_tmicon {
  width: 40px;
  height: 40px;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  font-size: 1.4em;
  line-height: 40px;
  -webkit-font-smoothing: antialiased;
  position: absolute;
  color: #181818;
  background: #fff;
  border-radius: 50%;
  box-shadow: 0 0 0 8px #7dd573;
  text-align: center;
  left: 18%;
  top: 0;
  margin: 0 0 0 -25px;
}

@media only screen and (max-width: 950px) {
  .cbp_tmtimeline > li .cbp_tmicon {
    position: relative;
    float: right;
    left: auto;
    margin: -62px 5px 0 0px;
  }
}

.cbp_tmtimeline > li .cbp_tmicon:before {
  font-weight: 700;
  font-family: "Font Awesome 5 Pro";
  content: "\f406";
}

.cbp_tmtimeline > li .cbp_tmicon.gleen:before {
  content: "\f406" !important;
}

.cbp_tmtimeline > li .cbp_tmicon.glay:before {
  content: "\f1ad" !important;
}

.cbp_tmtimeline > li .glay2 {
  box-shadow: 0 0 0 8px #ccc;
}

.cbp_tmtimeline > li .glay2:before {
  content: "\f1ad" !important;
}

.guide01 {
  width: 100vw;
  height: 100%;
  margin-left: calc(((100vw - 100%) / 2) * -1);
  margin-right: calc(((100vw - 100%) / 2) * -1);
  background: #42b536;
  margin-bottom: 100px;
  padding: 80px 0 100px;
}

@media only screen and (max-width: 700px) {
  .guide01 {
    margin-bottom: 60px;
    padding: 40px 0 60px;
  }
}

@media only screen and (max-width: 1000px) {
  .guide01 .inner {
    padding: 0 10px;
  }
}

@media only screen and (max-width: 950px) {
  .guide01 .inner {
    max-width: 100%;
    padding: 0 40px;
  }
}

@media only screen and (max-width: 700px) {
  .guide01 .inner {
    padding: 0 30px;
  }
}

@media only screen and (max-width: 700px) {
  .guide01 .inner .midashi3 h2 {
    text-align: left;
  }
}

.guide02 {
  letter-spacing: 0;
  max-width: 700px;
  margin: 0 auto;
}

.guide02 td {
  font-size: 16px;
  padding: 20px !important;
}

@media only screen and (max-width: 700px) {
  .guide02 td {
    padding: 20px !important;
  }
}

.guide02 .ttl {
  font-size: 18px;
}

@media only screen and (max-width: 700px) {
  .guide02 .ttl {
    font-size: 16px;
  }
}

.guide02 .ttl div {
  font-weight: 700;
}

.tabs {
  width: 100vw;
  height: 100%;
  margin-left: calc(((100vw - 100%) / 2) * -1);
  margin-right: calc(((100vw - 100%) / 2) * -1);
  padding: 100px 0;
  background: #42b536;
  margin-bottom: 100px;
}

@media only screen and (max-width: 1000px) {
  .tabs {
    padding: 100px 10px;
  }
}

@media screen and (min-width: 701px) and (max-width: 950px) {
  .tabs {
    padding: 100px 40px;
  }
}

@media only screen and (max-width: 700px) {
  .tabs {
    padding: 60px 30px;
    margin-bottom: 60px;
  }
}

.tabs .inner {
  position: relative;
}

.tabs .tab-buttons {
  border-bottom: 4px solid #ccc;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
}

.tabs .tab-buttons > span {
  color: #181818;
  font-weight: 700;
  font-size: 18px;
  background: #fff;
  cursor: pointer;
  width: 25%;
  text-align: center;
  height: 70px;
  line-height: 1.4em;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media only screen and (max-width: 700px) {
  .tabs .tab-buttons > span {
    font-size: 13px;
  }
}

.tabs .tab-buttons #lamp {
  width: 25%;
  height: 4px;
  background: #181818;
  display: block;
  position: absolute;
  top: 70px;
  transition: all .3s ease-in;
  -o-transition: all .3s ease-in;
  -webkit-transition: all .3s ease-in;
  -moz-transition: all .3s ease-in;
}

.tabs .tab-buttons #lamp.content1 {
  left: 0;
  transition: all .3s ease-in;
  -o-transition: all .3s ease-in;
  -webkit-transition: all .3s ease-in;
  -moz-transition: all .3s ease-in;
}

.tabs .tab-buttons #lamp.content2 {
  left: 25%;
  transition: all .3s ease-in;
  -o-transition: all .3s ease-in;
  -webkit-transition: all .3s ease-in;
  -moz-transition: all .3s ease-in;
}

.tabs .tab-buttons #lamp.content3 {
  left: 50%;
  transition: all .3s ease-in;
  -o-transition: all .3s ease-in;
  -webkit-transition: all .3s ease-in;
  -moz-transition: all .3s ease-in;
}

.tabs .tab-buttons #lamp.content4 {
  left: 75%;
  transition: all .3s ease-in;
  -o-transition: all .3s ease-in;
  -webkit-transition: all .3s ease-in;
  -moz-transition: all .3s ease-in;
}

.tabs .tab-content {
  width: 100%;
  padding: 50px 150px;
  background: #fff;
  display: inline-block;
}

@media screen and (min-width: 701px) and (max-width: 950px) {
  .tabs .tab-content {
    padding: 50px;
  }
}

@media only screen and (max-width: 700px) {
  .tabs .tab-content {
    padding: 20px;
  }
}

.faq01 dl {
  margin-bottom: 30px;
  display: block !important;
}

@media only screen and (max-width: 700px) {
  .faq01 dl {
    margin-bottom: 20px;
  }
}

.faq01 dl:last-child {
  margin-bottom: 0 !important;
}

.faq01 dl dt {
  cursor: pointer;
  width: 100% !important;
  font-size: 18px;
  font-weight: 700;
  color: #181818;
  border: 0 !important;
  background-color: inherit !important;
  padding: 0 !important;
  margin-bottom: 10px;
}

@media only screen and (max-width: 700px) {
  .faq01 dl dt {
    font-size: 16px;
  }
}

.faq01 dl dt span {
  display: flex !important;
  align-items: center !important;
  justify-content: flex-start !important;
  flex-direction: row !important;
}

@media only screen and (max-width: 700px) {
  .faq01 dl dt span {
    align-items: flex-start !important;
  }
}

.faq01 dl dt span:before {
  margin-right: 10px;
  font-family: "Font Awesome 5 Pro";
  content: "\f059";
  font-size: 30px;
  font-weight: 700;
  color: #42b536;
  vertical-align: middle;
  text-align: right;
  display: block;
}

@media only screen and (max-width: 700px) {
  .faq01 dl dt span:before {
    font-size: 20px;
  }
}

.faq01 dl dd {
  width: 100% !important;
  border: 0 !important;
  background-color: inherit !important;
  padding: 0 0 0 10px !important;
}

@media only screen and (max-width: 700px) {
  .faq01 dl dd {
    font-size: 15px;
    padding: 0 0 0 5px !important;
  }
}

.faq01 dl dd .txt {
  display: flex !important;
  align-items: flex-start !important;
  justify-content: flex-start !important;
  flex-direction: row !important;
}

.faq01 dl dd .txt:before {
  margin-right: 15px;
  content: "";
  background: url(../images/faq/icon_1.png);
  background-size: cover;
  width: 30px;
  height: 26px;
  vertical-align: middle;
  display: block;
}

@media only screen and (max-width: 700px) {
  .faq01 dl dd .txt:before {
    width: 20px;
    height: 17px;
    margin-right: 10px;
  }
}

.faq01 dl dd span {
  width: 90%;
  margin-right: 0 !important;
  line-height: 1.8em !important;
}

.faq01 dl a {
  line-height: 1;
  color: #66ad01;
  position: relative;
}

.faq01 dl a:after {
  position: absolute;
  bottom: 0;
  left: 0;
  content: '';
  width: 100%;
  height: 1px;
  background: #66ad01;
  transform: scale(0, 1);
  transform-origin: center top;
  transition: transform .3s;
}

@media only screen and (max-width: 700px) {
  .faq01 dl a:after {
    bottom: 0;
  }
}

.faq01 dl a:hover {
  opacity: 1 !important;
}

.faq01 dl a:hover:after {
  transform: scale(1, 1);
}

.nengabnr {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-left: -1.1857%;
}

@media only screen and (max-width: 1000px) {
  .nengabnr {
    margin-left: -1.9607%;
  }
}

@media only screen and (max-width: 700px) {
  .nengabnr {
    margin-left: 0;
    justify-content: space-between;
  }
}

.nengabnr .box {
  margin-left: 1.1857%;
  margin-bottom: 1.1857%;
  width: 23.8142%;
}

@media only screen and (max-width: 1000px) {
  .nengabnr .box {
    margin-left: 1.9607%;
    margin-bottom: 1.9607%;
    width: 31.3725%;
  }
}

@media screen and (min-width: 701px) and (max-width: 950px) {
  .nengabnr .box:last-child {
    margin-bottom: 0;
  }
}

@media only screen and (max-width: 700px) {
  .nengabnr .box {
    margin-left: 0;
    margin-bottom: 10px;
    width: 48%;
  }
}

.nengabnr .box a {
  opacity: 1 !important;
  padding: 15px 10px;
  color: #181818;
  font-size: 13px;
  background: #efefef;
  border: 2px solid #efefef;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  line-height: 1.7em;
}

@media screen and (min-width: 701px) and (max-width: 950px) {
  .nengabnr .box a {
    height: 78px;
  }
}

@media only screen and (max-width: 700px) {
  .nengabnr .box a {
    height: 70px;
    font-size: 11px;
  }
}

.nengabnr .box a:hover {
  opacity: 1 !important;
  background: #fff;
}

.nengabnr .box a:before {
  font-weight: 700;
  margin-right: 6px;
  font-family: "Font Awesome 5 Pro" !important;
  content: "" !important;
  color: #8fcb0e !important;
  font-size: 37px;
}

@media only screen and (max-width: 700px) {
  .nengabnr .box a:before {
    font-size: 27px !important;
  }
}

.nengabnr .box a.activ {
  background: #fff;
}

.nengabnr .box:nth-child(1) a:before {
  font-weight: 400;
  content: "\f03e" !important;
}

.nengabnr .box:nth-child(2) a:before {
  content: "\f53f" !important;
  font-size: 30px;
}

.nengabnr .box:nth-child(3) a:before {
  content: "\f53f" !important;
  font-size: 30px;
}

.nengabnr .box:nth-child(4) a:before {
  content: "\f53f" !important;
  font-size: 30px;
}

.nengabnr .box:nth-child(5) a {
  padding: 15px 15px;
}

.nengabnr .box:nth-child(5) a:before {
  content: "\f53f" !important;
  font-size: 30px;
}

.nengabnr .box:nth-child(6) a:before {
  content: "\f157" !important;
}

.nengabnr .box:nth-child(7) a:before {
  content: "\f044" !important;
}

.nenga01 {
  max-width: 700px;
  margin: 0 auto;
}

@media only screen and (max-width: 700px) {
  .nenga01 {
    font-size: 15px;
  }
}

.nenga01 .ttl div {
  font-weight: 700;
}

.tabs02 {
  width: 100vw;
  height: 100%;
  margin-left: calc(((100vw - 100%) / 2) * -1);
  margin-right: calc(((100vw - 100%) / 2) * -1);
  margin-bottom: 100px;
}

@media only screen and (max-width: 1000px) {
  .tabs02 {
    padding: 100px 10px;
  }
}

@media screen and (min-width: 701px) and (max-width: 950px) {
  .tabs02 {
    padding: 0 40px;
    margin-bottom: 60px;
  }
}

@media only screen and (max-width: 700px) {
  .tabs02 {
    padding: 0 30px;
    margin-bottom: 60px;
  }
}

.tabs02 .inner {
  position: relative;
}

.tabs02 .tab-buttons2 {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-left: -0.3984%;
}

.tabs02 .tab-buttons2 > span {
  margin-left: 0.3984%;
  width: 24.6015%;
  background: #efefef;
  cursor: pointer;
  text-align: center;
  line-height: 1.4em;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.tabs02 .tab-buttons2 > span a {
  width: 100%;
  padding: 20px 10px 15px;
  display: block;
  font-weight: 700;
  font-size: 16px;
  color: #181818;
  opacity: 1 !important;
}

@media screen and (min-width: 701px) and (max-width: 950px) {
  .tabs02 .tab-buttons2 > span a {
    font-size: 16px;
  }
}

@media only screen and (max-width: 700px) {
  .tabs02 .tab-buttons2 > span a {
    font-size: 14px;
    padding: 20px 10px;
  }
}

.tabs02 .tab-buttons2 > span a:after {
  font-family: "Font Awesome 5 Pro";
  content: "\f063";
  font-size: 20px;
  font-weight: 600;
  color: #bcbcbc;
  vertical-align: middle;
  text-align: center;
  display: block;
  margin-top: 5px;
  transition: 1s;
}

.tabs02 .tab-buttons2 > span a:hover:after {
  transition: 1s;
  color: #181818;
}

.tabs02 .tab-buttons2 .active a:after {
  color: #181818;
}

.tabs02 .tab-content {
  width: 100%;
  padding: 50px 150px;
  background: #fff;
  display: inline-block;
}

@media screen and (min-width: 701px) and (max-width: 950px) {
  .tabs02 .tab-content {
    padding: 50px;
  }
}

@media only screen and (max-width: 700px) {
  .tabs02 .tab-content {
    padding: 30px;
  }
}

.nenga02 {
  width: 100vw;
  height: 100%;
  margin-left: calc(((100vw - 100%) / 2) * -1);
  margin-right: calc(((100vw - 100%) / 2) * -1);
  background: #00afec;
  padding: 80px 0 100px;
}

@media only screen and (max-width: 700px) {
  .nenga02 {
    padding: 60px 0 60px;
  }
}

.nenga02 .inner3 {
  max-width: 700px;
  margin: 0 auto;
}

@media only screen and (max-width: 950px) {
  .nenga02 .inner3 {
    max-width: 100%;
    padding: 0 40px;
  }
}

@media only screen and (max-width: 700px) {
  .nenga02 .inner3 {
    padding: 0 30px;
  }
}

.nenga02 .inner3 .midashi3 h2 {
  line-height: 1;
}

@media only screen and (max-width: 700px) {
  .nenga02 .inner3 .midashi3 h2 {
    text-align: left;
  }
}

@media only screen and (max-width: 1000px) {
  .nenga02 .inner {
    padding: 0 10px;
  }
}

@media only screen and (max-width: 950px) {
  .nenga02 .inner {
    max-width: 100%;
    padding: 0 40px;
  }
}

@media only screen and (max-width: 700px) {
  .nenga02 .inner {
    padding: 0 30px;
  }
}

.nenga02 .inner .midashi3 h2 {
  line-height: 1;
}

@media only screen and (max-width: 700px) {
  .nenga02 .inner .midashi3 h2 {
    text-align: left;
  }
}

.nenga02 .lineup03 .boxwrap {
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 700px) {
  .nenga02 .lineup03 .boxwrap {
    display: block;
  }
}

.nenga02 .lineup03 .boxwrap .box {
  height: 100%;
  width: 48%;
  margin-bottom: 4%;
  background: #fff;
}

.nenga02 .lineup03 .boxwrap .box:nth-last-child(1) {
  margin-bottom: 0;
}

.nenga02 .lineup03 .boxwrap .box:nth-last-child(2) {
  margin-bottom: 0;
}

@media only screen and (max-width: 700px) {
  .nenga02 .lineup03 .boxwrap .box:nth-last-child(2) {
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 700px) {
  .nenga02 .lineup03 .boxwrap .box {
    margin-bottom: 30px;
    width: 100%;
  }
}

.nenga02 .lineup03 .boxwrap .box a {
  opacity: 1 !important;
  display: block;
  color: #181818;
}

.nenga02 .lineup03 .boxwrap .box a:hover {
  opacity: 1 !important;
}

.nenga02 .lineup03 .boxwrap .box a article:after {
  font-family: "Font Awesome 5 Pro";
  content: "\f061";
  font-size: 35px;
  vertical-align: middle;
  text-align: right;
  display: block;
  padding: 0 30px 30px 0;
}

@media only screen and (max-width: 950px) {
  .nenga02 .lineup03 .boxwrap .box a article:after {
    padding: 0 20px 20px 20px;
  }
}

.nenga02 .lineup03 .boxwrap .box .img img {
  opacity: 1 !important;
  width: 100% !important;
}

.nenga02 .lineup03 .boxwrap .box .img img:hover {
  opacity: 1 !important;
}

.nenga02 .lineup03 .boxwrap .box .txtwrap {
  letter-spacing: 0;
  padding: 30px;
}

@media only screen and (max-width: 950px) {
  .nenga02 .lineup03 .boxwrap .box .txtwrap {
    padding: 20px 20px 10px 20px;
  }
}

.nenga02 .lineup03 .boxwrap .box .txtwrap h3 {
  line-height: 1.4em;
  display: block;
  margin-bottom: 15px;
  font-weight: 700;
  font-size: 26px;
}

@media only screen and (max-width: 1000px) {
  .nenga02 .lineup03 .boxwrap .box .txtwrap h3 {
    font-size: 24px;
  }
}

@media screen and (min-width: 701px) and (max-width: 950px) {
  .nenga02 .lineup03 .boxwrap .box .txtwrap h3 {
    line-height: 1.4em;
    font-size: 20px;
  }
}

@media only screen and (max-width: 700px) {
  .nenga02 .lineup03 .boxwrap .box .txtwrap h3 {
    line-height: 1.4em;
    font-size: 18px;
    margin-bottom: 10px;
  }
}

.nenga02 .lineup03 .boxwrap .box .txtwrap h3 span {
  display: block;
}

@media screen and (min-width: 701px) and (max-width: 950px) {
  .nenga02 .lineup03 .boxwrap .box .txtwrap h3 span {
    display: inline-block;
  }
}

.nenga02 .lineup03 .boxwrap .box .txtwrap .txt {
  line-height: 1.8em;
}

@media only screen and (max-width: 700px) {
  .nenga02 .lineup03 .boxwrap .box .txtwrap .txt {
    font-size: 15px;
  }
}

.nenga02 .lineup03 .boxwrap2 {
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 700px) {
  .nenga02 .lineup03 .boxwrap2 {
    display: block;
  }
}

.nenga02 .lineup03 .boxwrap2 .box {
  height: 100%;
  width: 48%;
  margin-bottom: 4%;
  background: #fff;
}

.nenga02 .lineup03 .boxwrap2 .box:nth-last-child(1) {
  margin-bottom: 0;
}

.nenga02 .lineup03 .boxwrap2 .box:nth-last-child(2) {
  margin-bottom: 0;
}

@media only screen and (max-width: 700px) {
  .nenga02 .lineup03 .boxwrap2 .box:nth-last-child(2) {
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 700px) {
  .nenga02 .lineup03 .boxwrap2 .box {
    margin-bottom: 30px;
    width: 100%;
  }
}

.nenga03wrap {
  margin-left: calc(((100vw - 100%) / 2) * -1);
  margin-right: calc(((100vw - 100%) / 2) * -1);
}

@media only screen and (max-width: 1000px) {
  .nenga03wrap {
    margin-left: calc(((100vw - 100%) / 2) * -1);
    margin-right: calc(((100vw - 100%) / 2) * -1);
    padding: 0 10px;
  }
}

@media only screen and (max-width: 950px) {
  .nenga03wrap {
    padding: 0;
    margin-left: 0;
    margin-right: 0;
  }
}

.nenga03wrap .inner {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.nenga03wrap .nenga03 {
  border: 2px solid #00afec;
  width: 100%;
  max-width: 48%;
  position: relative;
  padding: 50px 50px 50px 68px;
}

@media only screen and (max-width: 950px) {
  .nenga03wrap .nenga03 {
    padding: 30px 30px 30px 40px;
    max-width: 100%;
  }
  .nenga03wrap .nenga03:last-child {
    margin-top: 40px;
  }
}

.nenga03wrap .nenga03 a {
  line-height: 1;
  position: relative;
  color: #66ad01;
}

.nenga03wrap .nenga03 a:after {
  position: absolute;
  bottom: 0;
  left: 0;
  content: '';
  width: 100%;
  height: 1px;
  background: #66ad01;
  transform: scale(0, 1);
  transform-origin: center top;
  transition: transform .3s;
}

@media only screen and (max-width: 700px) {
  .nenga03wrap .nenga03 a:after {
    bottom: 0;
  }
}

.nenga03wrap .nenga03 a:hover {
  opacity: 1 !important;
}

.nenga03wrap .nenga03 a:hover:after {
  transform: scale(1, 1);
}

.nenga03wrap .nenga03 .midashi5 {
  position: absolute;
  top: -13px;
  left: 50px;
  background: #fff;
  padding: 0 20px;
}

@media only screen and (max-width: 950px) {
  .nenga03wrap .nenga03 .midashi5 {
    top: -16px;
    left: 20px;
  }
}

.nenga03wrap .nenga03 .boxwrap {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

@media only screen and (max-width: 950px) {
  .nenga03wrap .nenga03 .boxwrap {
    display: block;
  }
}

.nenga03wrap .nenga03 .boxwrap ul li {
  padding-left: 0px !important;
  list-style-position: outside !important;
  overflow: inherit !important;
  padding-left: 0 !important;
}

.nenga04 {
  width: 100vw;
  height: 100%;
  margin-left: calc(((100vw - 100%) / 2) * -1);
  margin-right: calc(((100vw - 100%) / 2) * -1);
  background: #00afec;
  margin-bottom: 100px;
  padding: 80px 0 100px;
}

@media only screen and (max-width: 700px) {
  .nenga04 {
    padding: 60px 0 60px;
    margin-bottom: 60px;
  }
}

.nenga04 .inner3 {
  max-width: 700px;
  margin: 0 auto;
}

@media only screen and (max-width: 950px) {
  .nenga04 .inner3 {
    max-width: 100%;
    padding: 0 40px;
  }
}

@media only screen and (max-width: 700px) {
  .nenga04 .inner3 {
    padding: 0 30px;
  }
}

.nenga04 .inner3 .midashi3 h2 {
  line-height: 1;
}

@media only screen and (max-width: 700px) {
  .nenga04 .inner3 .midashi3 h2 {
    text-align: left;
  }
}

@media only screen and (max-width: 1000px) {
  .nenga04 .inner {
    padding: 0 10px;
  }
}

@media only screen and (max-width: 700px) {
  .nenga04 .inner {
    padding: 0 30px;
  }
}

.nenga04 .inner .midashi3 h2 {
  line-height: 1;
}

@media only screen and (max-width: 700px) {
  .nenga04 .inner .midashi3 h2 {
    text-align: left;
  }
}

.nenga04 .lineup03 .boxwrap {
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 700px) {
  .nenga04 .lineup03 .boxwrap {
    display: block;
  }
}

.nenga04 .lineup03 .boxwrap .box {
  height: 100%;
  width: 48%;
  margin-bottom: 4%;
  background: #fff;
}

.nenga04 .lineup03 .boxwrap .box:nth-last-child(1) {
  margin-bottom: 0;
}

.nenga04 .lineup03 .boxwrap .box:nth-last-child(2) {
  margin-bottom: 0;
}

@media only screen and (max-width: 700px) {
  .nenga04 .lineup03 .boxwrap .box:nth-last-child(2) {
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 700px) {
  .nenga04 .lineup03 .boxwrap .box {
    margin-bottom: 30px;
    width: 100%;
  }
}

.nenga04 .lineup03 .boxwrap .box a {
  opacity: 1 !important;
  display: block;
  color: #181818;
}

.nenga04 .lineup03 .boxwrap .box a:hover {
  opacity: 1 !important;
}

.nenga04 .lineup03 .boxwrap .box a article:after {
  font-family: "Font Awesome 5 Pro";
  content: "\f061";
  font-size: 35px;
  vertical-align: middle;
  text-align: right;
  display: block;
  padding: 0 30px 30px 0;
}

.nenga04 .lineup03 .boxwrap .box .img img {
  opacity: 1 !important;
  width: 100% !important;
}

.nenga04 .lineup03 .boxwrap .box .img img:hover {
  opacity: 1 !important;
}

.nenga04 .lineup03 .boxwrap .box .txtwrap {
  letter-spacing: 0;
  padding: 30px;
}

.nenga04 .lineup03 .boxwrap .box .txtwrap h3 {
  line-height: 1.4em;
  display: block;
  margin-bottom: 15px;
  font-weight: 700;
  font-size: 26px;
}

@media only screen and (max-width: 1000px) {
  .nenga04 .lineup03 .boxwrap .box .txtwrap h3 {
    font-size: 24px;
  }
}

@media screen and (min-width: 701px) and (max-width: 950px) {
  .nenga04 .lineup03 .boxwrap .box .txtwrap h3 {
    line-height: 1.4em;
    font-size: 20px;
  }
}

@media only screen and (max-width: 700px) {
  .nenga04 .lineup03 .boxwrap .box .txtwrap h3 {
    line-height: 1.4em;
    font-size: 18px;
  }
}

.nenga04 .lineup03 .boxwrap .box .txtwrap h3 span {
  display: block;
}

@media screen and (min-width: 701px) and (max-width: 950px) {
  .nenga04 .lineup03 .boxwrap .box .txtwrap h3 span {
    display: inline-block;
  }
}

.nenga04 .lineup03 .boxwrap .box .txtwrap .txt {
  line-height: 1.8em;
}

@media only screen and (max-width: 700px) {
  .nenga04 .lineup03 .boxwrap .box .txtwrap .txt {
    font-size: 15px;
  }
}

.nenga04 .lineup03 .boxwrap2 {
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 700px) {
  .nenga04 .lineup03 .boxwrap2 {
    display: block;
  }
}

.nenga04 .lineup03 .boxwrap2 .box {
  height: 100%;
  width: 48%;
  margin-bottom: 4%;
  background: #fff;
}

.nenga04 .lineup03 .boxwrap2 .box:nth-last-child(1) {
  margin-bottom: 0;
}

.nenga04 .lineup03 .boxwrap2 .box:nth-last-child(2) {
  margin-bottom: 0;
}

@media only screen and (max-width: 700px) {
  .nenga04 .lineup03 .boxwrap2 .box:nth-last-child(2) {
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 700px) {
  .nenga04 .lineup03 .boxwrap2 .box {
    margin-bottom: 30px;
    width: 100%;
  }
}

.anc {
  margin-top: -69px;
  height: 69px;
}

.whitebg {
  padding: 100px 0;
}

@media only screen and (max-width: 700px) {
  .whitebg {
    padding: 60px 0;
  }
}

.whitebg02 {
  padding: 100px 0 0 0;
}

@media only screen and (max-width: 700px) {
  .whitebg02 {
    padding: 60px 0 0 0;
  }
}

.nenga-color01 {
  width: 100vw;
  height: 100%;
  margin-left: calc(((100vw - 100%) / 2) * -1);
  margin-right: calc(((100vw - 100%) / 2) * -1);
  margin-bottom: 100px;
}

@media only screen and (max-width: 700px) {
  .nenga-color01 {
    margin-bottom: 60px;
  }
}

@media only screen and (max-width: 1000px) {
  .nenga-color01 .inner {
    padding: 0 10px;
  }
}

@media only screen and (max-width: 950px) {
  .nenga-color01 .inner {
    max-width: 100%;
    padding: 0 40px;
  }
}

@media only screen and (max-width: 700px) {
  .nenga-color01 .inner {
    padding: 0 30px;
  }
}

.nenga-color01 .inner .midashi3 h2 {
  line-height: 1;
}

@media only screen and (max-width: 700px) {
  .nenga-color01 .inner .midashi3 h2 {
    text-align: left;
  }
}

.nenga-color01 .boxwrap02 {
  display: flex;
  justify-content: flex-start;
  margin-left: -1.96078%;
}

@media screen and (min-width: 701px) and (max-width: 950px) {
  .nenga-color01 .boxwrap02 {
    margin-left: -3.3816%;
  }
}

@media only screen and (max-width: 700px) {
  .nenga-color01 .boxwrap02 {
    margin-left: 0;
    justify-content: space-between;
  }
}

.nenga-color01 .boxwrap02 .box {
  height: 100%;
  width: 18.0392%;
  margin-left: 1.96078%;
  margin-bottom: 4%;
}

@media screen and (min-width: 701px) and (max-width: 950px) {
  .nenga-color01 .boxwrap02 .box {
    width: 29.9516%;
    margin-left: 3.3816%;
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 700px) {
  .nenga-color01 .boxwrap02 .box {
    margin-left: 0;
    margin-bottom: 20px;
    width: 48%;
  }
}

.nenga-color01 .boxwrap02 .box h3 {
  margin-top: 10px;
  text-align: center;
}

.nenga-color01 .boxwrap02 .box div {
  display: none;
}

.nenga-color01 .btn a {
  color: #181818;
}

.nenga-color02 {
  width: 100vw;
  height: 100%;
  margin-left: calc(((100vw - 100%) / 2) * -1);
  margin-right: calc(((100vw - 100%) / 2) * -1);
}

@media only screen and (max-width: 1000px) {
  .nenga-color02 .inner {
    padding: 0 10px;
  }
}

@media only screen and (max-width: 950px) {
  .nenga-color02 .inner {
    max-width: 100%;
    padding: 0 40px;
  }
}

@media only screen and (max-width: 700px) {
  .nenga-color02 .inner {
    padding: 0 30px;
  }
}

.nenga-color02 .inner .midashi3 h2 {
  line-height: 1;
}

@media only screen and (max-width: 700px) {
  .nenga-color02 .inner .midashi3 h2 {
    text-align: left;
  }
}

.nenga-color02 .boxwrap02 {
  display: flex;
  justify-content: flex-start;
  margin-left: -1.96078%;
}

@media screen and (min-width: 701px) and (max-width: 950px) {
  .nenga-color02 .boxwrap02 {
    margin-left: -3.3816%;
  }
}

@media only screen and (max-width: 700px) {
  .nenga-color02 .boxwrap02 {
    margin-left: 0;
    justify-content: space-between;
  }
}

.nenga-color02 .boxwrap02 .box {
  height: 100%;
  width: 18.0392%;
  margin-left: 1.96078%;
  margin-bottom: 4%;
}

@media screen and (min-width: 701px) and (max-width: 950px) {
  .nenga-color02 .boxwrap02 .box {
    width: 29.9516%;
    margin-left: 3.3816%;
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 700px) {
  .nenga-color02 .boxwrap02 .box {
    margin-left: 0;
    margin-bottom: 20px;
    width: 48%;
  }
}

.nenga-color02 .boxwrap02 .box h3 {
  margin-top: 10px;
  text-align: center;
}

.nenga-color02 .boxwrap02 .box div {
  display: none;
}

.nenga-color02 .btn a {
  color: #181818;
}

.nenga05 .inner {
  border: 2px solid #e9546b;
  padding: 50px 50px 50px 68px;
  max-width: 700px;
  position: relative;
}

@media only screen and (max-width: 700px) {
  .nenga05 .inner {
    padding: 30px 30px 30px 48px;
  }
}

.nenga05 .inner .midashi5 {
  position: absolute;
  top: -13px;
  left: 50px;
  background: #fff;
  padding: 0 20px;
}

@media screen and (min-width: 701px) and (max-width: 950px) {
  .nenga05 .inner .midashi5 {
    top: -20px;
  }
}

@media only screen and (max-width: 700px) {
  .nenga05 .inner .midashi5 {
    top: -16px;
    left: 20px;
  }
}

.nenga05 .inner .midashi5 h3 {
  color: #e9546b !important;
}

.nenga05 .inner .boxwrap {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

@media only screen and (max-width: 700px) {
  .nenga05 .inner .boxwrap {
    display: block;
  }
}

.nenga05 .inner .boxwrap ul li {
  list-style-position: outside !important;
  overflow: inherit !important;
  padding-left: 0 !important;
}

.nenga05 .inner .boxwrap span {
  position: relative;
  background: linear-gradient(transparent 60%, #fef052 60%);
}

.nenga6 {
  font-size: 15px;
}

@media only screen and (max-width: 700px) {
  .nenga6 {
    line-height: 1.4em;
    font-size: 14px;
  }
}

@media only screen and (max-width: 700px) {
  .nenga6.spfs12 td {
    word-wrap: break-word !important;
    font-size: 12px !important;
    line-height: 1.4em !important;
  }
}

.nenga6 .ttl div {
  font-weight: 700;
}

.nenga6 td {
  padding: 20px !important;
}

@media only screen and (max-width: 700px) {
  .nenga6 td {
    padding: 15px 10px !important;
  }
}

.nenga6 .blank div {
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #66ad01;
}

@media only screen and (max-width: 700px) {
  .nenga6 .blank div {
    line-height: 1.4em;
  }
}

.nenga6 .blank div:after {
  font-family: "Font Awesome 5 Pro";
  content: "\f2d2";
  font-size: 13px;
  vertical-align: middle;
  text-align: right;
  display: block;
  margin-left: 4px;
  font-weight: 400;
}

.nenga6 .blank div:hover {
  opacity: 1 !important;
}

.nenga6 .blank div:hover:after {
  transform: scale(1, 1);
}

.nenga6 .blank a {
  color: #66ad01;
  line-height: 1;
  position: relative;
}

.nenga6 .blank a:after {
  position: absolute;
  bottom: 0;
  left: 0;
  content: '';
  width: 100%;
  height: 1px;
  background: #66ad01;
  transform: scale(0, 1);
  transform-origin: center top;
  transition: transform .3s;
}

.nenga6 .blank a:hover {
  color: #66ad01;
  opacity: 1 !important;
}

.nenga6 .blank a:hover:after {
  transform: scale(1, 1);
}

.nenga6 .linktxt02 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  line-height: 1;
}

.nenga6 .linktxt02 .tagwrap {
  line-height: 1;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  color: #66ad01;
}

.nenga6 .linktxt02 .tagwrap:after {
  font-family: "Font Awesome 5 Pro";
  content: "\f2d2";
  font-size: 16px;
  vertical-align: middle;
  text-align: right;
  display: block;
  padding: 0 5px 0 0;
  font-weight: 400;
}

@media only screen and (max-width: 700px) {
  .nenga6 .linktxt02 .tagwrap:after {
    font-size: 14px;
  }
}

.nenga6 .linktxt02 .tagwrap a {
  color: #66ad01;
  line-height: 1;
  position: relative;
}

.nenga6 .linktxt02 .tagwrap a:after {
  position: absolute;
  bottom: 0;
  left: 0;
  content: '';
  width: 100%;
  height: 1px;
  background: #66ad01;
  transform: scale(0, 1);
  transform-origin: center top;
  transition: transform .3s;
}

.nenga6 .linktxt02 .tagwrap a:hover {
  color: #66ad01;
  opacity: 1 !important;
}

.nenga6 .linktxt02 .tagwrap a:hover:after {
  transform: scale(1, 1);
}

@media only screen and (max-width: 700px) {
  .nengatd01 td {
    font-size: 14px;
    width: auto !important;
  }
}

@media only screen and (max-width: 700px) {
  .nengatd01 .nengatd01ttl {
    width: 120px !important;
  }
}

@media only screen and (max-width: 700px) {
  .nengatd01 .nengatd01txt {
    width: 87px !important;
  }
}

.nenga7 {
  width: 100vw;
  height: 100%;
  margin-left: calc(((100vw - 100%) / 2) * -1);
  margin-right: calc(((100vw - 100%) / 2) * -1);
  padding: 80px 0 100px;
  background: #00afec;
}

@media only screen and (max-width: 1000px) {
  .nenga7 {
    margin-left: calc(((100vw - 100%) / 2) * -1);
    margin-right: calc(((100vw - 100%) / 2) * -1);
    padding: 80px 10px 100px;
  }
}

@media screen and (min-width: 701px) and (max-width: 950px) {
  .nenga7 {
    padding: 80px 0 100px;
  }
}

@media only screen and (max-width: 700px) {
  .nenga7 {
    padding: 60px 0 60px;
  }
}

@media only screen and (max-width: 950px) {
  .nenga7 .inner {
    max-width: 100%;
    padding: 0 40px;
  }
}

@media only screen and (max-width: 700px) {
  .nenga7 .inner {
    padding: 0 30px;
  }
}

@media only screen and (max-width: 950px) {
  .nenga7 .inner3 {
    max-width: 100%;
    padding: 0 40px;
  }
}

@media only screen and (max-width: 700px) {
  .nenga7 .inner3 {
    padding: 0 30px;
  }
}

.nenga7_2 {
  width: 100vw;
  height: 100%;
  margin-left: calc(((100vw - 100%) / 2) * -1);
  margin-right: calc(((100vw - 100%) / 2) * -1);
  padding: 80px 0 100px;
  background: #00afec;
}

@media only screen and (max-width: 1000px) {
  .nenga7_2 {
    margin-left: calc(((100vw - 100%) / 2) * -1);
    margin-right: calc(((100vw - 100%) / 2) * -1);
    padding: 80px 10px 100px;
  }
}

@media screen and (min-width: 701px) and (max-width: 950px) {
  .nenga7_2 {
    padding: 80px 0 100px;
  }
}

@media only screen and (max-width: 700px) {
  .nenga7_2 {
    padding: 60px 0 60px;
  }
}

@media only screen and (max-width: 950px) {
  .nenga7_2 .inner {
    max-width: 100%;
    padding: 0 40px;
  }
}

@media only screen and (max-width: 700px) {
  .nenga7_2 .inner {
    padding: 0 30px;
  }
}

.nenga8 .inner {
  border: 2px solid #181818;
  padding: 50px 50px 50px 50px;
  max-width: 700px;
  position: relative;
}

@media only screen and (max-width: 950px) {
  .nenga8 .inner {
    padding: 30px 30px 30px 30px;
  }
}

.nenga8 .inner .midashi5 {
  position: absolute;
  top: -13px;
  left: 50px;
  background: #fff;
  padding: 0 20px;
}

@media screen and (min-width: 701px) and (max-width: 950px) {
  .nenga8 .inner .midashi5 {
    top: -20px;
  }
}

@media only screen and (max-width: 700px) {
  .nenga8 .inner .midashi5 {
    top: -16px;
    left: 20px;
  }
}

.nenga8 .inner .midashi5 h3 {
  color: #181818 !important;
}

.nenga8 .inner .ttl h4 {
  font-weight: 700;
  font-size: 18px;
  color: #181818 !important;
}

@media only screen and (max-width: 700px) {
  .nenga8 .inner .ttl h4 {
    font-size: 16px;
    line-height: 1.6em;
  }
}

@media only screen and (max-width: 950px) {
  .nenga8 .inner .listyle ul li {
    padding: 6px 0;
  }
}

.nenga8 .inner .boxwrap {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

@media only screen and (max-width: 700px) {
  .nenga8 .inner .boxwrap {
    display: block;
  }
}

.nenga8 .inner .boxwrap .flxL {
  width: 48%;
}

@media only screen and (max-width: 700px) {
  .nenga8 .inner .boxwrap .flxL {
    width: 100%;
  }
}

.nenga8 .inner .boxwrap .flxR {
  width: 48%;
}

@media only screen and (max-width: 700px) {
  .nenga8 .inner .boxwrap .flxR {
    width: 100%;
  }
}

.nenga8 .inner .boxwrap a {
  line-height: 1;
  position: relative;
  color: #66ad01;
}

.nenga8 .inner .boxwrap a:after {
  position: absolute;
  bottom: 0;
  left: 0;
  content: '';
  width: 100%;
  height: 1px;
  background: #66ad01;
  transform: scale(0, 1);
  transform-origin: center top;
  transition: transform .3s;
}

@media only screen and (max-width: 700px) {
  .nenga8 .inner .boxwrap a:after {
    bottom: 0;
  }
}

.nenga8 .inner .boxwrap a:hover {
  opacity: 1 !important;
}

.nenga8 .inner .boxwrap a:hover:after {
  transform: scale(1, 1);
}

.nenga8 .inner .boxwrap2 {
  display: block;
}

.nenga8 .inner .boxwrap2 a {
  line-height: 1;
  position: relative;
  color: #66ad01;
}

.nenga8 .inner .boxwrap2 a:after {
  position: absolute;
  bottom: 0;
  left: 0;
  content: '';
  width: 100%;
  height: 1px;
  background: #66ad01;
  transform: scale(0, 1);
  transform-origin: center top;
  transition: transform .3s;
}

@media only screen and (max-width: 700px) {
  .nenga8 .inner .boxwrap2 a:after {
    bottom: 0;
  }
}

.nenga8 .inner .boxwrap2 a:hover {
  opacity: 1 !important;
}

.nenga8 .inner .boxwrap2 a:hover:after {
  transform: scale(1, 1);
}

@media only screen and (max-width: 700px) {
  .modaal-inner-wrapper {
    padding: 25px 25px !important;
  }
}

.priceform {
  font-size: 15px;
}

@media only screen and (max-width: 700px) {
  .priceform {
    font-size: 12px;
  }
}

.priceform input, .priceform select {
  border-radius: 0 !important;
}

.priceform input {
  height: 50px;
  padding: 15px;
}

@media only screen and (max-width: 700px) {
  .priceform input {
    padding: 10px;
  }
}

.priceform h4 {
  margin-top: 5px;
}

.priceform table {
  max-width: 420px;
  border-collapse: collapse;
  text-align: left;
  border: 1px #fff solid;
}

.priceform th, .priceform td {
  border: 1px #fff solid;
  text-align: center;
  padding: 15px 15px 15px !important;
}

@media only screen and (max-width: 700px) {
  .priceform th, .priceform td {
    padding: 10px !important;
  }
}

.priceform th {
  text-align: center;
  color: #181818;
  background-color: #00afec;
  font-size: 18px;
  font-weight: 700;
}

@media only screen and (max-width: 700px) {
  .priceform th {
    font-size: 15px;
  }
}

.priceform td strong {
  color: #e9546b;
}

.priceform .nengabg1, .priceform .nengabg2 {
  background-color: #efefef;
}

.priceform .nengabg1 td:first-child, .priceform .nengabg2 td:first-child {
  width: 38%;
  line-height: 1;
}

.priceform select {
  width: 100%;
}

.priceform .nengaform {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.priceform .nengaform span {
  line-height: 1;
  width: 60px;
}

.priceform .rei {
  color: #757575;
  font-size: 13px;
}

.selectdiv {
  position: relative;
}

.selectdiv:after {
  content: '<>';
  font: 17px "Consolas", monospace;
  color: #333;
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
  right: 44px;
  top: 27px;
  padding: 0 0 15px 0;
  border-bottom: 1px solid #999;
  position: absolute;
  pointer-events: none;
}

@media screen and (min-width: 701px) and (max-width: 950px) {
  .selectdiv:after {
    top: 26px;
    padding: 0px 0 5px 0;
    right: 20px;
  }
}

@media only screen and (max-width: 700px) {
  .selectdiv:after {
    top: 21px;
    padding: 0px 0 5px 0;
    right: 15px;
  }
}

.selectdiv select {
  height: 50px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  /* Add some styling */
  display: block;
  width: 100%;
  padding: 10px;
  background-color: #ffffff;
  background-image: none;
  border: 1px solid #cccccc;
  -ms-word-break: normal;
  word-break: normal;
}

.selectdiv select::-ms-expand {
  display: none;
}

dd.select {
  max-width: 350px;
  position: relative;
}

dd.select:after {
  content: '<>';
  font: 17px "Consolas", monospace;
  color: #333;
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
  right: 24px;
  top: 6px;
  padding: 0 0 15px 0;
  border-bottom: 1px solid #999;
  /*left line */
  position: absolute;
  pointer-events: none;
}

dd.select select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  /* Add some styling */
  display: block;
  width: 100%;
  padding: 8px 15px 10px 15px;
  background-color: #ffffff;
  background-image: none;
  border: 1px solid #cccccc;
  -ms-word-break: normal;
  word-break: normal;
}

dd.select select::-ms-expand {
  display: none;
}

section div.form_wrap dl dd.addr select {
  width: 100% !important;
}

.addr input {
  padding: 15px 15px 15px 19px !important;
}

.addr div:nth-child(2) {
  max-width: 180px;
  position: relative;
}

.addr div:nth-child(2):after {
  content: '<>';
  font: 17px "Consolas", monospace;
  color: #333;
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
  right: 23px;
  top: 7px;
  padding: 0 0px 15px 0px;
  border-bottom: 1px solid #999;
  position: absolute;
  pointer-events: none;
}

.addr div:nth-child(2) select {
  color: #666;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  /* Add some styling */
  display: block;
  width: 100% !important;
  padding: 15px 15px 15px 19px;
  background-color: #ffffff;
  background-image: none;
  border: 1px solid #cccccc;
  -ms-word-break: normal;
  word-break: normal;
}

.addr div:nth-child(2) select::-ms-expand {
  display: none;
}

.nenga-form01 {
  max-width: 700px;
  margin: 0 auto 100px;
}

@media only screen and (max-width: 700px) {
  .nenga-form01 {
    margin: 0 auto 50px;
  }
}

@media only screen and (max-width: 700px) {
  .nenga-form01 td {
    padding: 10px !important;
    font-size: 14px;
    width: auto !important;
  }
}

.formcostam a {
  line-height: 1;
  position: relative;
  color: #66ad01;
}

.formcostam a:after {
  position: absolute;
  bottom: 0;
  left: 0;
  content: '';
  width: 100%;
  height: 1px;
  background: #66ad01;
  transform: scale(0, 1);
  transform-origin: center top;
  transition: transform .3s;
}

@media only screen and (max-width: 700px) {
  .formcostam a:after {
    bottom: 0;
  }
}

.formcostam a:hover {
  opacity: 1 !important;
}

.formcostam a:hover:after {
  transform: scale(1, 1);
}

.formcostam2 {
  margin-top: 10px;
}

.formcostam2 a {
  color: #66ad01;
  position: relative;
}

.formcostam2 a:after {
  position: absolute;
  bottom: 0;
  left: 0;
  content: '';
  width: 100%;
  height: 1px;
  background: #66ad01;
  transform: scale(0, 1);
  transform-origin: center top;
  transition: transform .3s;
}

@media only screen and (max-width: 700px) {
  .formcostam2 a:after {
    bottom: 0;
  }
}

.formcostam2 a:hover {
  color: #66ad01;
  opacity: 1 !important;
}

.formcostam2 a:hover:after {
  transform: scale(1, 1);
}

.business-card01 {
  max-width: 700px;
}

.business-card02 {
  width: 100vw;
  height: 100%;
  margin-left: calc(((100vw - 100%) / 2) * -1);
  margin-right: calc(((100vw - 100%) / 2) * -1);
  background: #fff;
  padding: 100px 0 100px;
}

@media only screen and (max-width: 700px) {
  .business-card02 {
    padding: 60px 0 60px;
  }
}

.business-card02 .inner3 {
  max-width: 700px;
  margin: 0 auto;
}

@media only screen and (max-width: 950px) {
  .business-card02 .inner3 {
    max-width: 100%;
    padding: 0 40px;
  }
}

@media only screen and (max-width: 700px) {
  .business-card02 .inner3 {
    padding: 0 30px;
  }
}

@media only screen and (max-width: 700px) {
  .business-card02 .inner3 .fs15 td {
    padding: 15px 10px !important;
  }
  .business-card02 .inner3 .fs15 .b {
    width: 70px !important;
  }
}

@media only screen and (max-width: 700px) {
  .business-card02 .inner3 .businesstd02 td {
    padding: 15px 10px !important;
  }
  .business-card02 .inner3 .businesstd02 .b {
    width: auto !important;
  }
}

.business-card02 .inner3 .midashi3 h2 {
  line-height: 1;
}

@media only screen and (max-width: 700px) {
  .business-card02 .inner3 .midashi3 h2 {
    text-align: left;
    font-size: 22px !important;
  }
}

@media only screen and (max-width: 1000px) {
  .business-card02 .inner {
    padding: 0 10px;
  }
}

@media only screen and (max-width: 950px) {
  .business-card02 .inner {
    max-width: 100%;
    padding: 0 40px;
  }
}

@media only screen and (max-width: 700px) {
  .business-card02 .inner {
    padding: 0 30px;
  }
}

.business-card02 .inner .midashi3 h2 {
  line-height: 1;
}

@media only screen and (max-width: 700px) {
  .business-card02 .inner .midashi3 h2 {
    text-align: left;
  }
}

.business-card02 .lineup03 .boxwrap2 {
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 700px) {
  .business-card02 .lineup03 .boxwrap2 {
    display: block;
  }
}

.business-card02 .lineup03 .boxwrap2 .box {
  height: 100%;
  width: 48%;
  margin-bottom: 4%;
  background: #fff;
}

.business-card02 .lineup03 .boxwrap2 .box:nth-last-child(1) {
  margin-bottom: 0;
}

.business-card02 .lineup03 .boxwrap2 .box:nth-last-child(2) {
  margin-bottom: 0;
}

@media only screen and (max-width: 700px) {
  .business-card02 .lineup03 .boxwrap2 .box:nth-last-child(2) {
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 700px) {
  .business-card02 .lineup03 .boxwrap2 .box {
    margin-bottom: 30px;
    width: 100%;
  }
}

.business-card02 .lineup03 .boxwrap {
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 700px) {
  .business-card02 .lineup03 .boxwrap {
    display: block;
  }
}

.business-card02 .lineup03 .boxwrap .box {
  height: 100%;
  width: 48%;
  margin-bottom: 4%;
  background: #fff;
}

.business-card02 .lineup03 .boxwrap .box:nth-last-child(1) {
  margin-bottom: 0;
}

.business-card02 .lineup03 .boxwrap .box:nth-last-child(2) {
  margin-bottom: 0;
}

@media only screen and (max-width: 700px) {
  .business-card02 .lineup03 .boxwrap .box:nth-last-child(2) {
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 700px) {
  .business-card02 .lineup03 .boxwrap .box {
    margin-bottom: 30px;
    width: 100%;
  }
}

.business-card02 .lineup03 .boxwrap .box a {
  opacity: 1 !important;
  display: block;
  color: #181818;
}

.business-card02 .lineup03 .boxwrap .box a:hover {
  opacity: 1 !important;
}

.business-card02 .lineup03 .boxwrap .box a article:after {
  font-family: "Font Awesome 5 Pro";
  content: "\f061";
  font-size: 35px;
  vertical-align: middle;
  text-align: right;
  display: block;
  padding: 0 30px 30px 0;
}

.business-card02 .lineup03 .boxwrap .box .img img {
  opacity: 1 !important;
  width: 100% !important;
}

.business-card02 .lineup03 .boxwrap .box .img img:hover {
  opacity: 1 !important;
}

.business-card02 .lineup03 .boxwrap .box .txtwrap {
  letter-spacing: 0;
  padding: 30px;
}

.business-card02 .lineup03 .boxwrap .box .txtwrap h3 {
  line-height: 1.4em;
  display: block;
  margin-bottom: 10px;
  font-weight: 700;
  font-size: 26px;
}

@media only screen and (max-width: 1000px) {
  .business-card02 .lineup03 .boxwrap .box .txtwrap h3 {
    font-size: 24px;
  }
}

@media screen and (min-width: 701px) and (max-width: 950px) {
  .business-card02 .lineup03 .boxwrap .box .txtwrap h3 {
    font-size: 20px;
  }
}

@media only screen and (max-width: 700px) {
  .business-card02 .lineup03 .boxwrap .box .txtwrap h3 {
    font-size: 18px;
  }
}

.business-card02 .lineup03 .boxwrap .box .txtwrap h3 span {
  display: block;
}

@media screen and (min-width: 701px) and (max-width: 950px) {
  .business-card02 .lineup03 .boxwrap .box .txtwrap h3 span {
    display: inline-block;
  }
}

.business-card03 {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-left: -1.1857%;
}

@media only screen and (max-width: 1000px) {
  .business-card03 {
    margin-left: -1.9607%;
  }
}

@media only screen and (max-width: 700px) {
  .business-card03 {
    margin-left: 0;
    justify-content: space-between;
  }
}

.business-card03 .box {
  margin-left: 1.1857%;
  margin-bottom: 1.1857%;
  width: 23.8142%;
}

@media only screen and (max-width: 1000px) {
  .business-card03 .box {
    margin-left: 1.9607%;
    margin-bottom: 1.9607%;
    width: 31.3725%;
  }
}

@media only screen and (max-width: 700px) {
  .business-card03 .box {
    margin-left: 0;
    margin-bottom: 10px;
    width: 48%;
  }
}

.business-card03 .box a {
  opacity: 1 !important;
  padding: 15px 10px;
  color: #181818;
  font-size: 13px;
  background: #efefef;
  border: 2px solid #efefef;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  line-height: 1.7em;
}

@media screen and (min-width: 701px) and (max-width: 950px) {
  .business-card03 .box a {
    height: 100%;
  }
}

@media screen and (min-width: 701px) and (max-width: 950px) {
  .business-card03 .box a {
    height: 78px;
  }
}

@media only screen and (max-width: 700px) {
  .business-card03 .box a {
    font-size: 11px;
    height: 70px;
  }
}

.business-card03 .box a:hover {
  opacity: 1 !important;
  background: #fff;
}

.business-card03 .box a:before {
  font-weight: 700;
  margin-right: 6px;
  font-family: "Font Awesome 5 Pro" !important;
  content: "" !important;
  color: #8fcb0e !important;
  font-size: 37px;
}

@media only screen and (max-width: 700px) {
  .business-card03 .box a:before {
    font-size: 27px;
  }
}

.business-card03 .box a.activ {
  background: #fff;
}

.business-card03 .box:nth-child(1) a:before {
  content: "\f2bb" !important;
}

.business-card03 .box:nth-child(2) a:before {
  content: "\f0c5" !important;
}

.business-card03 .box:nth-child(3) a:before {
  content: "\f157" !important;
}

.business-card03 .box:nth-child(4) a:before {
  content: "\f247" !important;
  font-weight: 500;
}

.business-card03 .box:nth-child(5) a:before {
  content: "\f044" !important;
}

.business-card03 .box:nth-child(6) a:before {
  content: "\f019" !important;
}

.business-card03 .box:nth-child(7) a:before {
  content: "\f019" !important;
}

.businesscard02 {
  width: 100vw;
  height: 100%;
  margin-left: calc(((100vw - 100%) / 2) * -1);
  margin-right: calc(((100vw - 100%) / 2) * -1);
  background: #00afec;
  margin-bottom: 100px;
  padding: 80px 0 100px;
}

@media only screen and (max-width: 700px) {
  .businesscard02 {
    margin-bottom: 60px;
    padding: 60px 0 60px;
  }
}

@media only screen and (max-width: 1000px) {
  .businesscard02 .inner {
    padding: 0 10px;
  }
}

@media screen and (min-width: 701px) and (max-width: 950px) {
  .businesscard02 .inner {
    padding: 0 40px;
  }
}

@media only screen and (max-width: 700px) {
  .businesscard02 .inner {
    padding: 0 30px;
  }
}

.businesscard02 .inner .boxwrap2 {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

@media only screen and (max-width: 700px) {
  .businesscard02 .inner .boxwrap2 {
    display: block;
  }
}

.businesscard02 .inner .boxwrap2 .box {
  height: 100%;
  width: 48%;
  margin-bottom: 4%;
  background: #fff;
}

.businesscard02 .inner .boxwrap2 .box:nth-last-child(1) {
  margin-bottom: 0;
}

.businesscard02 .inner .boxwrap2 .box:nth-last-child(2) {
  margin-bottom: 0;
}

@media only screen and (max-width: 700px) {
  .businesscard02 .inner .boxwrap2 .box:nth-last-child(2) {
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 700px) {
  .businesscard02 .inner .boxwrap2 .box {
    margin-bottom: 30px;
    width: 100%;
  }
}

.businesscard02 .inner .boxwrap2 .box td {
  padding: 0 !important;
  border: 0 !important;
}

.businesscard02 .inner .boxwrap2 .box a {
  opacity: 1 !important;
  display: block;
  color: #181818;
}

.businesscard02 .inner .boxwrap2 .box a:hover {
  opacity: 0.7 !important;
}

.businesscard02 .inner .boxwrap2 .box a article:after {
  font-family: "Font Awesome 5 Pro";
  content: "\f061";
  font-size: 35px;
  vertical-align: middle;
  text-align: right;
  display: block;
  padding: 0 30px 30px 0;
}

.businesscard02 .inner .boxwrap2 .box .img img {
  opacity: 1 !important;
  width: 100% !important;
}

.businesscard02 .inner .boxwrap2 .box .img img:hover {
  opacity: 1 !important;
}

.businesscard02 .inner .boxwrap2 .box .txtwrap {
  letter-spacing: 0;
  padding: 30px;
}

.businesscard02 .inner .boxwrap2 .box .txtwrap h3 {
  padding: 30px 30px 20px 30px;
  line-height: 1;
  display: block;
  font-weight: 700;
  font-size: 26px;
}

@media only screen and (max-width: 1000px) {
  .businesscard02 .inner .boxwrap2 .box .txtwrap h3 {
    font-size: 24px;
  }
}

@media screen and (min-width: 701px) and (max-width: 950px) {
  .businesscard02 .inner .boxwrap2 .box .txtwrap h3 {
    font-size: 20px;
  }
}

@media only screen and (max-width: 700px) {
  .businesscard02 .inner .boxwrap2 .box .txtwrap h3 {
    font-size: 18px;
  }
}

@media only screen and (max-width: 950px) {
  .businesscard02 .inner .boxwrap2 .box .txtwrap h3 {
    line-height: 1.4em;
    padding: 20px 20px 10px;
  }
}

.businesscard02 .inner .boxwrap2 .box .txtwrap h3 span {
  margin-top: 15px;
  font-size: 0.7em;
  display: block;
}

@media only screen and (max-width: 950px) {
  .businesscard02 .inner .boxwrap2 .box .txtwrap h3 span {
    font-size: 16px;
    margin-top: 0;
  }
}

.businesscard02 .inner .boxwrap2 .box .txt div {
  padding: 0px 30px 30px 30px;
}

@media only screen and (max-width: 950px) {
  .businesscard02 .inner .boxwrap2 .box .txt div {
    font-size: 16px;
    padding: 0px 20px 20px 20px;
  }
}

@media only screen and (max-width: 700px) {
  .businesscard02 .inner .boxwrap2 .box .txt div {
    font-size: 15px;
  }
}

@media only screen and (max-width: 700px) {
  .sptxtleft div {
    text-align: left !important;
  }
}

.b {
  font-weight: 700;
}

.b div {
  font-weight: 700;
}

.businesscard03 .inner {
  border: 2px solid #181818;
  padding: 50px 50px 50px 50px;
  max-width: 700px;
  position: relative;
}

@media only screen and (max-width: 700px) {
  .businesscard03 .inner {
    padding: 30px 30px 30px 30px;
  }
}

.businesscard03 .inner .midashi5 {
  position: absolute;
  top: -13px;
  left: 50px;
  background: #fff;
  padding: 0 20px;
}

.businesscard03 .inner .midashi5 h3 {
  color: #181818 !important;
}

.businesscard03 .inner .ttl h4 {
  font-weight: 700;
  font-size: 18px;
  color: #181818 !important;
}

.businesscard03 .inner ul {
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.businesscard03 .inner ul li:nth-child(2) {
  padding: 0 10px;
  color: #181818;
}

.businesscard03 .inner ul li:nth-child(2):after {
  display: none;
}

.businesscard03 .inner ul li {
  overflow: inherit !important;
  line-height: 1;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  color: #66ad01;
}

.businesscard03 .inner ul li:after {
  font-family: "Font Awesome 5 Pro";
  content: "\f019";
  font-weight: 700;
  font-size: 13px;
  vertical-align: middle;
  text-align: right;
  display: block;
  margin-left: 5px;
}

.businesscard03 .inner ul a {
  color: #66ad01;
  line-height: 1;
  position: relative;
}

.businesscard03 .inner ul a:after {
  position: absolute;
  bottom: 0;
  left: 0;
  content: '';
  width: 100%;
  height: 1px;
  background: #66ad01;
  transform: scale(0, 1);
  transform-origin: center top;
  transition: transform .3s;
}

.businesscard03 .inner ul a:hover {
  color: #66ad01;
  opacity: 1 !important;
}

.businesscard03 .inner ul a:hover:after {
  transform: scale(1, 1);
}

.newstabs {
  width: 100vw;
  height: 100%;
  margin-left: calc(((100vw - 100%) / 2) * -1);
  margin-right: calc(((100vw - 100%) / 2) * -1);
  padding: 100px 0;
  background: #f3e100;
  margin-bottom: 100px;
}

@media only screen and (max-width: 1000px) {
  .newstabs {
    padding: 100px 10px;
  }
}

@media screen and (min-width: 701px) and (max-width: 950px) {
  .newstabs {
    padding: 60px 40px;
    margin-bottom: 60px;
  }
}

@media only screen and (max-width: 700px) {
  .newstabs {
    padding: 60px 30px;
    margin-bottom: 60px;
  }
}

.newstabs .inner {
  position: relative;
}

.newstabs .tab-buttons {
  border-bottom: 4px solid #ccc;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
}

.newstabs .tab-buttons > span {
  color: #181818;
  font-weight: 700;
  font-size: 18px;
  background: #fff;
  cursor: pointer;
  width: 25%;
  text-align: center;
  height: 70px;
  line-height: 1.4em;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media only screen and (max-width: 700px) {
  .newstabs .tab-buttons > span {
    height: 50px;
    font-size: 13px;
  }
}

.newstabs .tab-buttons #lamp {
  width: 25%;
  height: 4px;
  background: #181818;
  display: block;
  position: absolute;
  top: 70px;
  transition: all .3s ease-in;
  -o-transition: all .3s ease-in;
  -webkit-transition: all .3s ease-in;
  -moz-transition: all .3s ease-in;
}

@media only screen and (max-width: 700px) {
  .newstabs .tab-buttons #lamp {
    top: 50px;
  }
}

.newstabs .tab-buttons #lamp.content1 {
  left: 0;
  transition: all .3s ease-in;
  -o-transition: all .3s ease-in;
  -webkit-transition: all .3s ease-in;
  -moz-transition: all .3s ease-in;
}

.newstabs .tab-buttons #lamp.content2 {
  left: 25%;
  transition: all .3s ease-in;
  -o-transition: all .3s ease-in;
  -webkit-transition: all .3s ease-in;
  -moz-transition: all .3s ease-in;
}

.newstabs .tab-buttons #lamp.content3 {
  left: 50%;
  transition: all .3s ease-in;
  -o-transition: all .3s ease-in;
  -webkit-transition: all .3s ease-in;
  -moz-transition: all .3s ease-in;
}

.newstabs .tab-buttons #lamp.content4 {
  left: 75%;
  transition: all .3s ease-in;
  -o-transition: all .3s ease-in;
  -webkit-transition: all .3s ease-in;
  -moz-transition: all .3s ease-in;
}

.newstabs .tab-content {
  width: 100%;
  padding: 30px 150px 60px;
  background: #fff;
  display: inline-block;
}

@media screen and (min-width: 701px) and (max-width: 950px) {
  .newstabs .tab-content {
    padding: 50px;
  }
}

@media only screen and (max-width: 700px) {
  .newstabs .tab-content {
    padding: 10px 20px 30px 20px;
  }
}

.news01 .blog_list {
  border: 0;
}

.news01 .blog_list .blog_photo {
  display: none;
}

.news01 .blog_list #app01, .news01 .blog_list #app02, .news01 .blog_list #app03, .news01 .blog_list #app04 {
  display: block;
}

.news01 .blog_list #app01 dl, .news01 .blog_list #app02 dl, .news01 .blog_list #app03 dl, .news01 .blog_list #app04 dl {
  padding: 0;
  border-bottom: 1px solid #181818;
}

.news01 .blog_list #app01 dl > a, .news01 .blog_list #app02 dl > a, .news01 .blog_list #app03 dl > a, .news01 .blog_list #app04 dl > a {
  display: block;
  transition: .3s;
  pointer-events: auto;
  padding: 40px 20px 40px;
}

@media only screen and (max-width: 700px) {
  .news01 .blog_list #app01 dl > a, .news01 .blog_list #app02 dl > a, .news01 .blog_list #app03 dl > a, .news01 .blog_list #app04 dl > a {
    padding: 30px 10px;
  }
}

.news01 .blog_list #app01 dl > a:hover, .news01 .blog_list #app02 dl > a:hover, .news01 .blog_list #app03 dl > a:hover, .news01 .blog_list #app04 dl > a:hover {
  opacity: 1 !important;
  background: #f8f8f8;
}

.news01 .blog_list > div {
  display: none;
}

.news01 .blog_list .blog_text {
  width: 100%;
  padding: 0;
  font-size: 16px;
}

.news01 .blog_list .blog_text .detawrap {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 20px;
}

@media only screen and (max-width: 700px) {
  .news01 .blog_list .blog_text .detawrap {
    margin-bottom: 10px;
  }
}

.news01 .blog_list .blog_text .detawrap .wrapdeta .blog_date {
  line-height: 1;
  font-size: 16px;
  font-weight: 700;
  color: #181818;
}

.news01 .blog_list .blog_text .detawrap .wrapdeta ul {
  display: block;
}

.news01 .blog_list .blog_text .detawrap .wrapdeta ul li {
  display: none;
  line-height: 1;
  margin-left: 10px;
  font-size: 12px;
  border: 1px solid #181818;
  color: #181818;
  border-radius: 20px;
  padding: 3px 10px;
}

.news01 .blog_list .blog_text .detawrap .wrapdeta ul li:first-child {
  display: block;
}

.news01 .blog_list .blog_text h3 {
  margin-bottom: 0;
}

.news01 .blog_list .blog_text h3 a {
  font-size: 16px;
  color: #181818;
}

.news01 .blog_list .blog_text h3 a:hover {
  opacity: 1 !important;
}

.news01 .blog_list .blog_text .detail {
  display: none;
}

.news01 dl {
  padding: 15px 0;
  border-bottom: 1px dashed #333;
  display: block !important;
}

.news01 dl dt, .news01 dl dd {
  padding: 0 !important;
  width: 100% !important;
  border: 0 !important;
  background-color: inherit !important;
}

.pagination {
  margin-top: 50px;
  display: flex !important;
  align-items: center;
  justify-content: center;
}

.pagination li {
  display: inline;
}

.pagination li a {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  cursor: pointer;
  background-color: #fff;
  color: #181818;
  text-decoration: none;
  background-color: #fff;
  display: flex !important;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 14px;
  opacity: 1;
}

.pagination li a:hover {
  background: #efefef;
}

.pagination .page-item {
  display: none;
}

.pagination .active {
  display: block;
}

.pagination .active a {
  background-color: #f3e100 !important;
}

.blog #contents {
  max-width: 100%;
}

.blog #contents .blogmain {
  margin-left: calc(((100vw - 100%) / 2) * -1);
  margin-right: calc(((100vw - 100%) / 2) * -1);
  margin-bottom: 80px;
  background: #f3e100;
}

@media screen and (min-width: 701px) and (max-width: 950px) {
  .blog #contents .blogmain {
    padding: 0 40px;
    margin-bottom: 60px;
  }
}

@media only screen and (max-width: 700px) {
  .blog #contents .blogmain {
    padding: 0 30px;
    margin-bottom: 60px;
  }
}

.blog #contents .blogmain .maininner {
  max-width: 700px;
  margin: 0 auto;
  padding: 60px 0;
}

@media only screen and (max-width: 950px) {
  .blog #contents .blogmain .maininner {
    max-width: 100%;
    padding: 40px 0;
  }
}

.blog #contents .blogmain .maininner .rub {
  font-weight: 700;
  margin-bottom: 30px;
  line-height: 1;
}

.blog #contents .blogmain .maininner ul {
  display: block;
  margin-bottom: 30px;
}

@media only screen and (max-width: 950px) {
  .blog #contents .blogmain .maininner ul {
    margin-bottom: 20px;
  }
}

.blog #contents .blogmain .maininner ul li {
  display: none;
}

.blog #contents .blogmain .maininner ul li:first-child {
  display: block;
}

.blog #contents .blogmain .maininner ul li a {
  line-height: 1;
  font-size: 16px;
  font-weight: 700;
  border: 1px solid #181818;
  color: #181818;
  border-radius: 20px;
  padding: 7px 18px;
}

@media only screen and (max-width: 700px) {
  .blog #contents .blogmain .maininner ul li a {
    font-size: 13px;
  }
}

.blog #contents .blogmain .maininner h1 {
  font-weight: 700;
  display: block;
  margin-bottom: 30px;
  letter-spacing: 0;
  font-size: 34px;
}

@media only screen and (max-width: 1000px) {
  .blog #contents .blogmain .maininner h1 {
    font-size: 32px;
  }
}

@media screen and (min-width: 701px) and (max-width: 950px) {
  .blog #contents .blogmain .maininner h1 {
    font-size: 25px;
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 700px) {
  .blog #contents .blogmain .maininner h1 {
    font-size: 22px;
    margin-bottom: 20px;
  }
}

.blog #contents .blogmain .maininner h1 span {
  padding-right: 0;
  line-height: 1.6em;
}

.blog #contents .blogmain .maininner .date {
  font-weight: 700;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
}

@media only screen and (max-width: 700px) {
  .blog #contents .blogmain .maininner .date {
    font-size: 14px;
  }
}

.blog #contents .blogmain .maininner .date:before {
  font-family: "Font Awesome 5 Pro";
  content: "\f303";
  font-size: 16px;
  vertical-align: middle;
  text-align: right;
  display: block;
  padding: 0 5px 0 0;
}

@media only screen and (max-width: 700px) {
  .blog #contents .blogmain .maininner .date:before {
    font-size: 14px;
  }
}

.blog #contents article {
  max-width: 700px;
  margin: 0 auto;
}

@media only screen and (max-width: 700px) {
  .blog #contents article .tag {
    margin: 0 0 60px 0 !important;
  }
}

.blog #contents article .tag > div {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.blog #contents article .tag .tagwrap {
  line-height: 1;
  margin-right: 20px;
  margin-bottom: 20px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  color: #66ad01;
}

.blog #contents article .tag .tagwrap:before {
  font-family: "Font Awesome 5 Pro";
  content: "\f02b";
  font-size: 16px;
  vertical-align: middle;
  text-align: right;
  display: block;
  padding: 0 5px 0 0;
  font-weight: 700;
}

@media only screen and (max-width: 700px) {
  .blog #contents article .tag .tagwrap:before {
    font-size: 14px;
  }
}

.blog #contents article .tag .tagwrap a {
  color: #66ad01;
  line-height: 1;
  position: relative;
}

.blog #contents article .tag .tagwrap a:after {
  position: absolute;
  bottom: -4px;
  left: 0;
  content: '';
  width: 100%;
  height: 1px;
  background: #66ad01;
  transform: scale(0, 1);
  transform-origin: center top;
  transition: transform .3s;
}

.blog #contents article .tag .tagwrap a:hover {
  color: #66ad01;
  opacity: 1 !important;
}

.blog #contents article .tag .tagwrap a:hover:after {
  transform: scale(1, 1);
}

.blogmain2 {
  margin-bottom: 80px;
  background: #f3e100;
}

@media screen and (min-width: 701px) and (max-width: 950px) {
  .blogmain2 {
    padding: 0 40px;
  }
}

@media only screen and (max-width: 700px) {
  .blogmain2 {
    padding: 0 30px;
    margin-bottom: 30px;
  }
}

.blogmain2 .maininner {
  max-width: 700px;
  margin: 0 auto;
  padding: 60px 0;
  text-align: left;
}

@media only screen and (max-width: 950px) {
  .blogmain2 .maininner {
    max-width: 100%;
    padding: 40px 0;
  }
}

.blogmain2 .maininner .rub {
  font-weight: 700;
  margin-bottom: 30px;
  line-height: 1;
}

@media screen and (min-width: 701px) and (max-width: 950px) {
  .blogmain2 .maininner .rub {
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 700px) {
  .blogmain2 .maininner .rub {
    margin-bottom: 10px;
    font-size: 14px;
  }
}

.blogmain2 .maininner h1 {
  font-size: 34px;
  font-weight: 700;
  line-height: 1.4em;
  display: block;
  letter-spacing: 0;
}

@media only screen and (max-width: 1000px) {
  .blogmain2 .maininner h1 {
    font-size: 32px;
  }
}

@media screen and (min-width: 701px) and (max-width: 950px) {
  .blogmain2 .maininner h1 {
    font-size: 25px;
  }
}

@media only screen and (max-width: 700px) {
  .blogmain2 .maininner h1 {
    font-size: 22px;
  }
}

.blogmain2 .maininner h1 span {
  padding-right: 0;
}

.news02 {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

@media only screen and (max-width: 700px) {
  .news02 {
    display: block;
  }
}

.news02 .flxL {
  width: 25%;
  padding: 30px;
  background: #efefef;
}

@media only screen and (max-width: 950px) {
  .news02 .flxL {
    padding: 25px;
  }
}

@media only screen and (max-width: 700px) {
  .news02 .flxL {
    width: 100%;
    margin-bottom: 30px;
  }
}

.news02 .flxL .txt td {
  border: 0 !important;
  padding: 0 !important;
}

.news02 .flxL .txt a {
  line-height: 1;
  position: relative;
  color: #181818;
  font-size: 14px;
}

.news02 .flxL .txt a:before {
  position: absolute;
  bottom: 0;
  left: 0;
  content: '';
  width: 100%;
  height: 1px;
  background: #333;
  transform: scale(0, 1);
  transform-origin: center top;
  transition: transform .3s;
}

@media only screen and (max-width: 700px) {
  .news02 .flxL .txt a:before {
    bottom: 0;
  }
}

.news02 .flxL .txt a:hover {
  opacity: 1 !important;
}

.news02 .flxL .txt a:hover:before {
  transform: scale(1, 1);
}

.news02 .flxR {
  width: 70%;
  padding: 30px;
  background: #efefef;
}

@media only screen and (max-width: 700px) {
  .news02 .flxR {
    width: 100%;
  }
}

.news02 .flxR .txt tbody {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.news02 .flxR .txt tr {
  display: flex !important;
  align-items: center;
  justify-content: flex-start;
}

.news02 .flxR .txt tr:after {
  content: "/";
  padding: 0 10px;
}

.news02 .flxR .txt td {
  border: 0 !important;
  padding: 0 !important;
}

.news02 .flxR .txt a {
  line-height: 1;
  color: #181818;
  font-size: 14px;
  position: relative;
}

.news02 .flxR .txt a:before {
  position: absolute;
  bottom: 0;
  left: 0;
  content: '';
  width: 100%;
  height: 1px;
  background: #333;
  transform: scale(0, 1);
  transform-origin: center top;
  transition: transform .3s;
}

@media only screen and (max-width: 700px) {
  .news02 .flxR .txt a:before {
    bottom: 0;
  }
}

.news02 .flxR .txt a:hover {
  opacity: 1 !important;
}

.news02 .flxR .txt a:hover:before {
  transform: scale(1, 1);
}

.news02 .ttl {
  margin-bottom: 13px;
}

.news02 .ttl h2 {
  font-family: 'Viga', sans-serif;
  letter-spacing: 0.05em;
  color: #181818;
  line-height: 1;
  font-weight: 500;
  position: relative;
  display: block;
  font-size: 20px;
}

@media only screen and (max-width: 1000px) {
  .news02 .ttl h2 {
    font-size: 18px;
  }
}

@media screen and (min-width: 701px) and (max-width: 950px) {
  .news02 .ttl h2 {
    font-size: 18px;
  }
}

@media only screen and (max-width: 700px) {
  .news02 .ttl h2 {
    font-size: 18px;
  }
}

.blogsearch {
  width: 100%;
  max-width: 500px !important;
  margin: 40px auto 0 !important;
}

.blogsearch #search form {
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #181818;
}

.blogsearch #search form input {
  padding: 15px !important;
  border: 0 !important;
}

.blogsearch #search form button {
  background: none;
  border: 0;
  font-size: 30px;
  padding: 0 10px;
}

.blogsearch #search form button:focus {
  outline: inherit;
}

.blog02 a {
  line-height: 1;
  position: relative;
  color: #66ad01;
}

@media only screen and (max-width: 700px) {
  .blog02 a {
    line-height: 1.8em;
  }
}

.blog02 a:after {
  position: absolute;
  bottom: 0;
  left: 0;
  content: '';
  width: 100%;
  height: 1px;
  background: #66ad01;
  transform: scale(0, 1);
  transform-origin: center top;
  transition: transform .3s;
}

@media only screen and (max-width: 700px) {
  .blog02 a:after {
    bottom: 0;
  }
}

.blog02 a:hover {
  opacity: 1 !important;
}

.blog02 a:hover:after {
  transform: scale(1, 1);
}

.img100 img {
  width: 100% !important;
}

.loadnewstab .newstabs {
  border: 4px solid #f3e100;
  width: 100% !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
  padding: 0 !important;
  background: none !important;
  margin-bottom: 50px;
}

@media only screen and (max-width: 700px) {
  .loadnewstab .newstabs {
    padding: 0;
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 1000px) {
  .loadnewstab .newstabs .inner {
    margin: 0;
  }
}

@media only screen and (max-width: 700px) {
  .loadnewstab .newstabs .inner {
    margin: 0;
  }
}

.loadnewstab .newstabs .tab-buttons span {
  background: #f3e100;
}

.loadnewstab .newstabs .tab-content {
  width: 100%;
  padding: 40px !important;
  background: #fff;
  display: inline-block;
}

@media screen and (min-width: 701px) and (max-width: 950px) {
  .loadnewstab .newstabs .tab-content {
    padding: 30px !important;
  }
}

@media only screen and (max-width: 700px) {
  .loadnewstab .newstabs .tab-content {
    padding: 10px 20px 30px 20px !important;
  }
}

.newssearch2 .blog_list {
  margin-bottom: 100px;
  border: 0;
  display: block;
}

@media only screen and (max-width: 700px) {
  .newssearch2 .blog_list {
    margin-bottom: 60px;
  }
}

.newssearch2 .blog_list .blog_photo {
  display: none;
}

.newssearch2 .blog_list dl {
  padding: 0;
  border-bottom: 1px solid #181818;
}

.newssearch2 .blog_list dl > a {
  display: block;
  transition: .3s;
  padding: 40px 20px 40px;
}

@media only screen and (max-width: 700px) {
  .newssearch2 .blog_list dl > a {
    padding: 30px 10px 30px;
  }
}

.newssearch2 .blog_list dl > a:hover {
  background: #f8f8f8;
  opacity: 1 !important;
}

.newssearch2 .blog_list > div {
  display: none;
}

.newssearch2 .blog_list .blog_text {
  width: 100%;
  padding: 0;
  font-size: 16px;
}

.newssearch2 .blog_list .blog_text .detawrap {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 10px;
}

.newssearch2 .blog_list .blog_text .detawrap .wrapdeta .blog_date {
  line-height: 1;
  font-size: 16px;
  font-weight: 700;
  color: #181818;
}

.newssearch2 .blog_list .blog_text .detawrap .wrapdeta ul {
  display: block;
}

.newssearch2 .blog_list .blog_text .detawrap .wrapdeta ul li {
  display: none;
  pointer-events: none;
  line-height: 1;
  margin-left: 10px;
  font-size: 12px;
  border: 1px solid #181818;
  color: #181818;
  border-radius: 20px;
  padding: 3px 10px;
}

.newssearch2 .blog_list .blog_text .detawrap .wrapdeta ul li:first-child {
  display: block;
}

.newssearch2 .blog_list .blog_text h3 {
  margin-bottom: 0;
}

.newssearch2 .blog_list .blog_text h3 a {
  font-size: 16px;
  color: #181818;
}

.newssearch2 .blog_list .blog_text h3 a:hover {
  opacity: 1 !important;
}

.newssearch2 .blog_list .blog_text .detail {
  display: none;
}

.newssearch2 dl {
  padding: 15px 0;
  border-bottom: 1px dashed #333;
  display: block !important;
}

.newssearch2 dl dt, .newssearch2 dl dd {
  padding: 0 !important;
  width: 100% !important;
  border: 0 !important;
  background-color: inherit !important;
}

.certificate01 {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-left: -1.1857%;
}

@media only screen and (max-width: 1000px) {
  .certificate01 {
    margin-left: -1.9607%;
  }
}

@media only screen and (max-width: 700px) {
  .certificate01 {
    margin-left: 0;
    justify-content: space-between;
  }
}

.certificate01 .box {
  margin-left: 1.1857%;
  margin-bottom: 1.1857%;
  width: 23.8142%;
}

@media only screen and (max-width: 1000px) {
  .certificate01 .box {
    margin-left: 1.9607%;
    margin-bottom: 1.9607%;
    width: 31.3725%;
  }
}

@media only screen and (max-width: 700px) {
  .certificate01 .box {
    margin-left: 0;
    margin-bottom: 10px;
    width: 48%;
  }
}

.certificate01 .box a {
  letter-spacing: 0;
  opacity: 1 !important;
  padding: 15px 10px;
  color: #181818;
  font-size: 13px;
  background: #efefef;
  border: 2px solid #efefef;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  line-height: 1.7em;
}

@media screen and (min-width: 701px) and (max-width: 950px) {
  .certificate01 .box a {
    height: 78px;
  }
}

@media only screen and (max-width: 700px) {
  .certificate01 .box a {
    font-size: 11px;
    height: 70px;
  }
}

.certificate01 .box a:hover {
  opacity: 1 !important;
  background: #fff;
}

.certificate01 .box a:before {
  font-weight: 700;
  margin-right: 10px;
  font-family: "Font Awesome 5 Pro" !important;
  content: "" !important;
  color: #8fcb0e !important;
  font-size: 33px;
}

@media only screen and (max-width: 700px) {
  .certificate01 .box a:before {
    font-size: 27px;
  }
}

.certificate01 .box a.activ {
  background: #fff;
}

.certificate01 .box:nth-child(1) a:before {
  content: "\f15c" !important;
}

.certificate01 .box:nth-child(2) a:before {
  content: "\f0c5" !important;
}

.certificate01 .box:nth-child(3) a:before {
  content: "\f036" !important;
}

.certificate01 .box:nth-child(4) a:before {
  content: "\f1fc" !important;
}

.certificate01 .box:nth-child(5) a:before {
  content: "\f157" !important;
}

.certificate01 .box:nth-child(6) a:before {
  content: "\f247" !important;
  font-weight: 400;
}

.certificate01 .box:nth-child(7) a:before {
  content: "\f044" !important;
}

.certificate01 .box:nth-child(8) a:before {
  content: "\f044" !important;
}

.certificate02 ul li {
  line-height: 1.8em;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.certificate02 ul li:before {
  font-family: "Font Awesome 5 Pro";
  content: "\f00c";
  font-weight: 700;
  font-size: 16px;
  vertical-align: middle;
  text-align: right;
  display: block;
  padding: 0 10px 0 0;
}

.certificate03 > div {
  padding: 30px;
  background: #fff;
}

.certificate03 > div div {
  margin-bottom: 20px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.certificate03 > div div:after {
  font-family: "Font Awesome 5 Pro";
  content: "\f591";
  font-weight: 700;
  font-size: 18px;
  vertical-align: middle;
  text-align: right;
  display: block;
  padding: 0 0 0 2px;
  color: #00afec;
}

.certificate03 > div div h4 {
  padding: 0 3px;
  font-size: 18px;
  font-weight: 700;
  display: inline-block;
  background: linear-gradient(transparent 60%, #a0e6fe 60%);
}

@media only screen and (max-width: 700px) {
  .certificate03 > div div h4 {
    font-size: 16px;
  }
}

.certificatesentence01 .inner {
  border: 2px solid #181818;
  padding: 50px;
  max-width: 700px;
  position: relative;
}

@media only screen and (max-width: 950px) {
  .certificatesentence01 .inner {
    padding: 30px;
  }
}

@media only screen and (max-width: 950px) {
  .certificatesentence01 .inner ul li {
    padding: 6px 0;
  }
}

.certificatesentence01 .inner a {
  list-style: 1;
  position: relative;
  color: #66ad01;
}

.certificatesentence01 .inner a:after {
  position: absolute;
  bottom: 0;
  left: 0;
  content: '';
  width: 100%;
  height: 1px;
  background: #66ad01;
  transform: scale(0, 1);
  transform-origin: center top;
  transition: transform .3s;
}

@media only screen and (max-width: 700px) {
  .certificatesentence01 .inner a:after {
    bottom: 0;
  }
}

.certificatesentence01 .inner a:hover {
  opacity: 1 !important;
}

.certificatesentence01 .inner a:hover:after {
  transform: scale(1, 1);
}

.certificatesentence01 .inner .midashi5 {
  position: absolute;
  top: -13px;
  left: 50px;
  background: #fff;
  padding: 0 20px;
}

@media screen and (min-width: 701px) and (max-width: 950px) {
  .certificatesentence01 .inner .midashi5 {
    top: -20px;
  }
}

@media only screen and (max-width: 700px) {
  .certificatesentence01 .inner .midashi5 {
    top: -16px;
    left: 20px;
  }
}

.certificatesentence01 .inner .midashi5 h3 {
  color: #181818;
}

.certificatesentence01 .inner .boxwrap {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

@media only screen and (max-width: 700px) {
  .certificatesentence01 .inner .boxwrap {
    display: block;
  }
}

.certificatesentence01 .inner .boxwrap .flxL {
  width: 48%;
}

@media only screen and (max-width: 700px) {
  .certificatesentence01 .inner .boxwrap .flxL {
    width: 100%;
  }
}

.certificatesentence01 .inner .boxwrap .flxR {
  width: 48%;
}

@media only screen and (max-width: 700px) {
  .certificatesentence01 .inner .boxwrap .flxR {
    width: 100%;
  }
}

.certificatesentence02 > div {
  padding: 30px;
  background: #fff;
  border-left: 10px solid #a0e6fe;
}

@media only screen and (max-width: 700px) {
  .certificatesentence02 > div {
    padding: 20px;
  }
}

.certificatesentence02 > div div {
  margin-bottom: 15px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  line-height: 1;
}

@media only screen and (max-width: 700px) {
  .certificatesentence02 > div div {
    line-height: 1.4em;
  }
}

.certificatesentence02 > div div:before {
  font-family: "Font Awesome 5 Pro";
  content: "\f1fc";
  font-weight: 700;
  font-size: 16px;
  vertical-align: middle;
  text-align: right;
  display: block;
  padding: 0 5px 0 0;
}

.certificatesentence02 > div div h3 {
  font-size: 18px;
  font-weight: 700;
  display: inline-block;
}

@media only screen and (max-width: 700px) {
  .certificatesentence02 > div div h3 {
    font-size: 16px;
  }
}

.certificatesentence02 > div p {
  padding: 0 0 0 21px;
}

@media only screen and (max-width: 700px) {
  .certificatesentence02 > div p {
    font-size: 15px;
  }
}

.certificatesentence03 > div {
  padding: 30px;
  background: #f9f9f9;
  border-left: 10px solid #a0e6fe;
}

@media only screen and (max-width: 700px) {
  .certificatesentence03 > div {
    padding: 20px;
  }
}

.certificatesentence03 > div div {
  margin-bottom: 15px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  line-height: 1;
}

@media only screen and (max-width: 700px) {
  .certificatesentence03 > div div {
    line-height: 1.4em;
  }
}

.certificatesentence03 > div div:before {
  font-family: "Font Awesome 5 Pro";
  content: "\f1fc";
  font-weight: 700;
  font-size: 16px;
  vertical-align: middle;
  text-align: right;
  display: block;
  padding: 0 5px 0 0;
}

.certificatesentence03 > div div h3 {
  font-size: 18px;
  font-weight: 700;
  display: inline-block;
}

@media only screen and (max-width: 700px) {
  .certificatesentence03 > div div h3 {
    font-size: 16px;
  }
}

.certificatesentence03 > div p {
  padding: 0 0 0 21px;
}

@media only screen and (max-width: 700px) {
  .certificatesentence03 > div p {
    font-size: 15px;
  }
}

@media only screen and (max-width: 700px) {
  .fs15 {
    font-size: 14px !important;
  }
}

.nengaform dl:nth-child(9) dd {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.nengaform dl:nth-child(9) dd input {
  width: auto;
  margin-left: 10px;
  max-width: 100px !important;
}

.nengaform dl:nth-child(10) dd {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.nengaform dl:nth-child(10) dd input {
  width: auto;
  margin-right: 10px;
  max-width: 100px !important;
}

.certificateform dl:nth-child(13) dd {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.certificateform dl:nth-child(13) dd input {
  width: auto;
  margin-right: 10px;
  max-width: 100px !important;
}

.certificateform2 dl:nth-child(11) dd {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.certificateform2 dl:nth-child(11) dd input {
  width: auto;
  margin-right: 10px;
  max-width: 100px !important;
}

.afnone:after {
  display: none;
}

.contactwrap {
  background: #f7f7f7;
  padding: 50px;
}

.policy a {
  color: #333;
}

.img100 img {
  max-width: 100% !important;
  width: 100% !important;
}

.tdpd10 td {
  padding: 10px !important;
}

.slider2 img {
  margin: 0 auto;
}

.tdscl {
  overflow-x: scroll;
}

.tdscl table {
  table-layout: fixed;
  width: 1850px;
}

.formtel td {
  font-size: 16px;
  border: 0 !important;
}

.formtel .tel2 div {
  color: #000;
  font-size: 30px;
  font-weight: 700;
}

.formtel .tel2 a {
  font-size: 30px;
  font-weight: 700;
  color: #000;
}

.modaal-close {
  position: absolute !important;
  right: -20px !important;
  top: -20px !important;
  background: rgba(0, 0, 0, 0.7) !important;
}

.modaal-content-container h3 {
  border-bottom: 2px solid #BBBBBB;
  font-size: 18px;
  font-weight: bold;
  margin: 0 0 15px;
  padding: 0 0 5px;
}

.modaal-content-container p {
  margin: 0 0 20px;
}

.modaal-close:focus:before, .modaal-close:focus:after, .modaal-close:hover:before, .modaal-close:hover:after {
  background: #fff !important;
}

@media only screen and (max-width: 950px) {
  .modaal-content-container {
    height: auto;
    overflow-y: scroll;
  }
}

.center {
  text-align: center;
}

@media only screen and (max-width: 700px) {
  .businesstd01 td {
    font-size: 12px !important;
    line-height: 1.4em !important;
    padding: 15px 10px !important;
  }
}

@media only screen and (max-width: 700px) {
  .businesstd02 td {
    font-size: 14px !important;
    line-height: 1.4em !important;
    padding: 15px 10px !important;
  }
}

@media only screen and (max-width: 700px) {
  .businesstd02.spfs12 td {
    word-wrap: break-word !important;
    font-size: 12px !important;
  }
}

@media only screen and (max-width: 700px) {
  .businesstd02 .sp60 {
    max-width: 60px !important;
    width: 60px !important;
  }
}

@media only screen and (max-width: 700px) {
  .businesstd02 .sp80 {
    max-width: 80px !important;
    width: 80px !important;
  }
}

@media only screen and (max-width: 700px) {
  .businesstd02 .sp120 {
    max-width: 120px !important;
    width: 120px !important;
  }
}

@media only screen and (max-width: 700px) {
  .businesstd02 .sp200 {
    max-width: 200px !important;
    width: 200px !important;
  }
}

@media only screen and (max-width: 700px) {
  .certificatetd01 td {
    font-size: 14px !important;
    line-height: 1.4em !important;
    padding: 15px 10px !important;
  }
}

@media only screen and (max-width: 700px) {
  .certificatetd01 .b {
    width: 90px !important;
  }
}

@media only screen and (max-width: 700px) {
  .certificatetd02 td {
    font-size: 12px !important;
    line-height: 1.4em !important;
    padding: 15px 10px !important;
  }
}

.linktxt01 a {
  line-height: 1;
  position: relative;
  color: #66ad01;
}

.linktxt01 a:after {
  position: absolute;
  bottom: 0;
  left: 0;
  content: '';
  width: 100%;
  height: 1px;
  background: #66ad01;
  transform: scale(0, 1);
  transform-origin: center top;
  transition: transform .3s;
}

@media only screen and (max-width: 700px) {
  .linktxt01 a:after {
    bottom: 0;
  }
}

.linktxt01 a:hover {
  opacity: 1 !important;
}

.linktxt01 a:hover:after {
  transform: scale(1, 1);
}

.linktxt02 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  line-height: 1;
}

.linktxt02 .tagwrap {
  line-height: 1;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  color: #66ad01;
}

.linktxt02 .tagwrap:after {
  font-family: "Font Awesome 5 Pro";
  content: "\f2d2";
  font-size: 16px;
  vertical-align: middle;
  text-align: right;
  display: block;
  padding: 0 5px 0 0;
  font-weight: 400;
}

@media only screen and (max-width: 700px) {
  .linktxt02 .tagwrap:after {
    font-size: 14px;
  }
}

.linktxt02 .tagwrap a {
  color: #66ad01;
  line-height: 1;
  position: relative;
}

.linktxt02 .tagwrap a:after {
  position: absolute;
  bottom: 0;
  left: 0;
  content: '';
  width: 100%;
  height: 1px;
  background: #66ad01;
  transform: scale(0, 1);
  transform-origin: center top;
  transition: transform .3s;
}

.linktxt02 .tagwrap a:hover {
  color: #66ad01;
  opacity: 1 !important;
}

.linktxt02 .tagwrap a:hover:after {
  transform: scale(1, 1);
}

.disabled {
  display: none !important;
}

.fome01 {
  margin: 100px 0 30px;
}

@media only screen and (max-width: 700px) {
  .fome01 {
    margin: 60px 0 20px;
  }
}

.fome01 h2 {
  text-align: center;
}

@media only screen and (max-width: 700px) {
  .fome01 h2 {
    text-align: left;
  }
}

.fome02 {
  margin: 0 0 100px 0;
  text-align: center;
}

@media only screen and (max-width: 700px) {
  .fome02 {
    margin: 0 0 50px 0;
  }
}
